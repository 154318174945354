<template>
    <view-section-card title="대상정보">
        <v-card-text>
            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <v-select v-model="form.target.type" label="대상 유형" :items="targetTypes" v-bind="attrs_input" @input="emit" />
                </v-col>
                <v-expand-transition>
                    <v-col cols="12" class="pa-0" v-show="form.target.type == SHIPPING_TARGET_TYPES.BY_PRODUCTS.value">
                        <div class="pa-2">
                            <autocomplete-product v-model="form.target._products" label="대상 제품" multiple v-bind="attrs_input" @input="emit" />
                        </div>
                    </v-col>
                </v-expand-transition>
                <v-expand-transition>
                    <v-col cols="12" class="pa-0" v-show="form.target.type == SHIPPING_TARGET_TYPES.BY_CATEGORY.value">
                        <div class="pa-2">
                            <autocomplete-category v-model="form.target._categories" label="대상 카테고리" multiple v-bind="attrs_input" @input="emit" />
                        </div>
                    </v-col>
                </v-expand-transition>
            </v-row>
        </v-card-text>
    </view-section-card>
</template>

<script>
import { attrs_input, initShopShipping, SHIPPING_TARGET_TYPES } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import AutocompleteCategory from "@/components/dumb/autocomplete-category.vue";

const targetTypes = Object.values(SHIPPING_TARGET_TYPES);

export default {
    components: {
        ViewSectionCard,
        AutocompleteProduct,
        AutocompleteCategory,
    },
    props: {
        value: { type: Object, default: initShopShipping },
    },
    data: () => ({
        form: initShopShipping(),

        targetTypes,
        SHIPPING_TARGET_TYPES,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initShopShipping(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style></style>
