<template>
    <v-btn v-bind="{ ...attrs, loading }" @click="setOrderStateDispatchd">{{ title }}</v-btn>
</template>

<script>
import api from "@/api";

export default {
    props: {
        title: { type: String, default: "상태갱신" },
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    computed: {
        attrs() {
            if (0 < Object.keys(this.$attrs).length) return this.$attrs;
            else return { small: true, outlined: true, color: "primary", class: "mr-2" };
        },
    },
    methods: {
        async setOrderStateDispatchd() {
            try {
                if (!this.selected.length) throw new Error(`${this.title} 처리할 구매건을 선택해주세요`);
            } catch (error) {
                this.$handleError(error);
                return;
            }

            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                for (const { _id, state, _purchases } of this.selected) {
                    const props = { _id, state };
                    if (!_purchases?.length) delete props.state;
                    await api.console.shop.orders.patchState(props);
                }

                alert(`${this.title} 처리 되었습니다`);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
