<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="이전주문 목록" v-model="showsSearch" showsFilterButton />

            <order-list-search v-bind="{ loading, showsSearch }" />

            <v-row class="mx-n2 mt-n3">
                <v-col cols="auto" class="pa-2">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col cols="auto" class="pa-2">
                    <order-list-status />
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="pa-2">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <v-layout align-center> <v-icon small class="mr-1">mdi-microsoft-excel</v-icon> 엑셀다운로드 </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.orderNo`]="{ item, value, header }">
                    <order-view :_order="item._id" v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile width="100%" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                        </template>
                    </order-view>
                </template>
                <template #[`item.purchases`]="{ item, value, header }">
                    <order-view :_order="item._id" v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile width="100%" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                        </template>
                    </order-view>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn-toggle borderless tile>
                        <list-order-cancel-btn plain text class="caption" v-bind="{ loading, selected: item.purchases, disabled: item.disabled }" v-on="{ search, loading: (value) => (loading = value) }" />
                        <v-divider vertical />
                        <list-order-confirm-btn plain text class="caption" v-bind="{ loading, selected: item.confirmables, disabled: item.disabled }" v-on="{ search, loading: (value) => (loading = value) }" title="주문완료" />
                        <v-divider vertical />
                        <order-list-update plain text class="caption" v-bind="{ loading, selected: [item], disabled: !item.updatabble }" v-on="{ search, loading: (value) => (loading = value) }" />
                    </v-btn-toggle>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import OrderView from "@/components/console/legacy/orders/view/order-view.vue";
import OrderListSearch from "@/components/console/legacy/orders/list/order-list-search.vue";
import OrderListStatus from "@/components/console/legacy/orders/list/order-list-status.vue";

const formatPurchase = (purchases) => {
    let text = purchases[0]?.name;
    if (1 < purchases.length) text += ` 외 ${purchases.length - 1}개`;
    return text;
};

const formatReceiver = (receiver) => {
    const { postcode, address1, address2, phone, email } = receiver || {};

    let address = "";
    if (postcode) address += ` [${postcode?.trim?.() || postcode}]`;
    if (address1) address += ` ${address1?.trim?.() || address1}`;
    if (address2) address += ` ${address2?.trim?.() || address2}`;
    address = address.trim();

    let contact = [`${phone?.phoneNumberFormat?.() || ""}`, email].filter((item) => item).join(" / ");

    return [address, contact].filter((item) => item).join("\r\n");
};

const headers = initDataTableHeaders([
    { text: "주문번호", value: "orderNo", width: 180, cellClass: "pa-0" },
    { text: "주문제품", value: "purchases", cellClass: "pa-0 max-width-0 text-truncate", withTitle: true, formatter: formatPurchase, width: 160 },
    { text: "구매자", value: "sender", width: 160, cellClass: "max-width-0 text-truncate", withTitle: true, formatter: ({ username, name }) => `${name} (${username})` },
    { text: "배송상세", value: "receiver", cellClass: "max-width-0 text-truncate", withTitle: true, formatter: formatReceiver },
    { text: "구매금액", value: "totalPrice", width: 120, align: "end", formatter: (value) => value?.format?.() || "-" },
    { text: "결제방법", value: "paymentMethodText", width: 100, align: "center" },
    { text: "주문상태", value: "statusText", width: +90, align: "center" },
    { text: "주문일시", value: "createdAt", width: 100, cellClass: "py-0", formatter: (value) => value?.toDateTime?.() || value || "-" },
]);

export default {
    components: {
        ListHeading,

        OrderView,
        OrderListSearch,
        OrderListStatus,
    },
    data: () => ({
        orders: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        headers,
    }),
    computed: {
        ...mapState(["scope", "payload"]),
        isConsole() {
            return this.scope?.includes?.("console");
        },
        items() {
            return [...this.orders];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            if (!query.status) delete query.status;
            if (query.searchDateValue) query.searchDateKey = "createdAt";

            if (!this.isConsole) {
                if (!query._managers) query._managers = this.payload?._user;
            }

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.users = [];
            this.summary = { totalCount: 0 };
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, orders } = await api.console.legacy.orders.gets({ headers: { skip, limit }, params });

                this.orders = orders;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { order } = await api.console.legacy.orders.put(item);
                this.loading = false;
                this.updateItem(order);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.orders.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.orders.splice(index, 1, item);
            else {
                this.orders = [item, ...this.orders];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { orders } = await api.console.legacy.orders.gets({
                    params,
                });

                this.$excel(orders, headers, "주문목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
