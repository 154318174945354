<template>
    <v-sheet class="mt-2">
        <v-row class="ma-n1">
            <v-col v-for="detail in color" :key="detail" cols="auto" class="pa-1">
                <v-chip small dark label :color="detail">{{detail}}</v-chip>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    data() {
        return {
            color: [
                "#E5ABB9", "#00A1E1", "#A2A9AE", "#F9E151", "#3BAF2C", "#041A72", "#CF152D", "#FD8307",
            ]
        };
    },
}
</script>

<style>

</style>