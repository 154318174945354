<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="배송정책" v-model="showsSearch" showsFilterButton>
                <template #add-button>
                    <shop-shipping-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile color="primary" v-bind="{ ...attrs, loading }" v-on="on"> <v-icon> mdi-plus </v-icon> </v-btn>
                        </template>
                    </shop-shipping-form>
                </template>
            </list-heading>

            <shop-shipping-list-search v-bind="{ loading, showsSearch }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.basics`]="{ item }">
                    <shop-shipping-list-item-basics :value="item" flat tile color="transparent" />
                </template>

                <template #[`item.options`]="{ item }">
                    <form-options :value="item" mode="view" />
                </template>

                <template #[`item.isActive`]="{ item }">
                    <v-switch v-model="item.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, isActive })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <shop-shipping-form :value="item" @input="search">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small title="수정">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </shop-shipping-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small title="삭제">mdi-delete</v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, DISPLAY_CODES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import FormOptions from "@/components/console/shop/shippings/form/form-options.vue";
import ShopShippingForm from "@/components/console/shop/shippings/form/shop-shipping-form.vue";
import ShopShippingListSearch from "@/components/console/shop/shippings/list/shop-shipping-list-search.vue";
import ShopShippingListItemBasics from "@/components/console/shop/shippings/list/shop-shipping-list-item-basics.vue";

const headers = [
    { text: "기본정보", value: "basics", width: 240, cellClass: "pa-0" },
    { text: "옵션정보", value: "options", cellClass: "pa-0" },
    { text: "사용여부", value: "isActive", width: +90 },
    { text: "Actions", value: "actions", width: 88 },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,

        FormOptions,
        ShopShippingForm,
        ShopShippingListSearch,
        ShopShippingListItemBasics,
    },
    props: {
        targetType: { type: String, default: null },
    },
    data: () => ({
        shippings: [],

        limit: 20,
        summary: { totalCount: 0 },

        attrs_switch,
        DISPLAY_CODES,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return this.shippings;
        },
        headers() {
            return headers.filter((item) => (item.targetTypes && this.targetType ? item.targetTypes.includes(this.targetType) : true));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            query["target.type"] = this.targetType;
            if (!query["target.type"]) delete query["target.type"];

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.shippings = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, shippings } = await api.console.shop.shippings.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.shippings = shippings;
            } finally {
                this.loading = false;
            }
        },

        async update(shipping) {
            if (this.loading) return;
            else this.loading = true;
            try {
                shipping = (await api.console.shop.shippings.put(shipping))?.shipping;
                this.updateItem(shipping);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(shipping) {
            const index = this.shippings.findIndex(({ _id }) => _id == shipping._id);
            if (0 <= index) this.shippings.splice(index, 1, shipping);
            else {
                this.shippings = [shipping, ...this.shippings];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async remove(shipping) {
            try {
                if (confirm("해당 정책을 삭제하시겠습니까?")) {
                    await api.console.shop.shippings.delete(shipping);
                    await this.search();

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
    .vertical-align-middle {
        vertical-align: middle !important;
    }
}
</style>
