<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="주문 관리" showsFilterButton v-model="showsSearch" />

            <purchase-list-search v-bind="{ showsSearch }" />

            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-sheet outlined rounded>
                        <v-btn color="white" class="green--text px-2" v-bind="{ loading }" @click="excel"><v-icon class="mr-2">mdi-microsoft-excel</v-icon>엑셀다운로드</v-btn>
                    </v-sheet>
                </v-col>
            </v-row>

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item?.order?.receiver?.title }}</div>
                    <div>{{ item?.order?.receiver?.name }}</div>
                    <div>{{ item?.order?.receiver?.phone }}</div>
                    <div>{{ item?.order?.receiver?.email }}</div>
                </template>
                <template #[`item.order.receiver.address`]="{ item }">
                    <div v-if="item.order.receiver.postcode">[{{ item.order.receiver.postcode }}]</div>
                    <div>{{ item.order.receiver.address1 }} {{ item.order.receiver.address2 }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import { decode__productOptionName } from "@/plugins/vue-methods-shop";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PurchaseListSearch from "@/components/console/shop/purchases/purchase-list-search.vue";

const headers = [
    { width: 190, text: "주문번호\n제품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 110, text: "구매확정상태", value: "purchaseStatusMessage" },
    { width: 100, text: "주문상태", value: "orderStatusMessage" },
    { width: 100, text: "클레임상태", value: "claimStatusMessage" },
    { width: 100, text: "클레임 사유", value: "claimReason" },
    { width: 200, text: "클레임 상세사유", value: "claimReasonDetails" },
    { width: 200, text: "배송요청사항", value: "order.requestMessage" },
    { width: 200, text: "제품요청사항", value: "requestMessage" },
    { width: 120, text: "연락처", value: "order.sender.phone" },
    { width: 120, text: "제품코드", value: "code" },
    { width: 200, text: "구매제품", value: "product.name" },
    { width: 200, text: "옵션정보", value: "name", formatter: decode__productOptionName },
    { width: +90, text: "구매수량", value: "amount" },
    { width: 120, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
    { width: 300, text: "배송지", value: "order.receiver.address" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    components: {
        OrderView,
        ListHeading,
        PurchaseListSearch,
    },
    data: () => ({
        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { purchases } = await api.console.shop.purchases.getPurchases({ params });

                var rows = purchases.map((purchase) => ({
                    제품주문번호: purchase.purchaseNo,
                    주문번호: purchase.order.orderNo,
                    구매자명: purchase.order.sender.name,
                    구매자ID: purchase.user ? purchase.user.username : "비회원",
                    수취인명: purchase.order.receiver.name,
                    주문상태: purchase.orderStatusMessage,
                    주문세부상태: purchase.claimStatusMessage,
                    결제일: purchase.paymentAt ? purchase.paymentAt.toDateTime() : purchase.createdAt.toDateTime(),
                    배송방법: purchase.delivery.method,
                    택배사: purchase.delivery.name,
                    송장번호: purchase.delivery.number,
                    발송일: purchase.shippedAt,
                    제품번호: purchase._product,
                    제품명: purchase.product.name,
                    제품종류: "",
                    옵션정보: purchase.name,
                    수량: purchase.amount,
                    옵션가격: purchase.price,
                    제품가격: purchase.product.price,
                    "제품별 할인액": purchase.discountPrice,
                    "판매자 부담 할인액": "",
                    "제품별 총 주문금액": purchase.salePrice,
                    사은품: "",
                    발주확인일: purchase.orderConfirmDate,
                    발송기한: "",
                    발송처리일: purchase.shippingConfirmDate,
                    송장출력일: "",
                    "배송비 형태": "",
                    "배송비 묶음번호": "",
                    "배송비 유형": "",
                    "배송비 합계": purchase.deliveryPrice,
                    "지역별 배송비": purchase.islandPrice,
                    "배송비 할인액": "",
                    "판매자 제품코드": "",
                    수취인연락처1: purchase.order.receiver.phone,
                    수취인연락처2: "",
                    배송지: `${purchase.order.receiver.address1} ${purchase.order.receiver.address2}`,
                    구매자연락처: purchase.order.sender.phone,
                    우편번호: purchase.order.receiver.postcode,
                    배송메세지: purchase.delivery.message,
                    출고지: "",
                    결제수단: purchase.order.paymentMethod,
                    유입경로: "",
                    "3개월 주문건수": "",
                    "6개월 주문건수": "",
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "주문목록.xlsx");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
