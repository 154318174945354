var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": `${_vm.title} 목록`
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('catalogue-form', _vm._b({
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        }, 'catalogue-form', {
          code: _vm.code
        }, false))];
      },
      proxy: true
    }])
  }), _c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.file`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.download(value === null || value === void 0 ? void 0 : value.url);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-file")])], 1)];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (shows) {
              return _vm.save({
                _id: item._id,
                shows
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('catalogue-form', _vm._b({
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, 'catalogue-form', {
          value: item,
          code: _vm.code
        }, false)), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "py-3",
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": _vm.search
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }