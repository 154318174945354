var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "대상정보"
    }
  }, [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "대상 유형",
      "items": _vm.targetTypes
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.target.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.target, "type", $$v);
      },
      expression: "form.target.type"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.target.type == _vm.SHIPPING_TARGET_TYPES.BY_PRODUCTS.value,
      expression: "form.target.type == SHIPPING_TARGET_TYPES.BY_PRODUCTS.value"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('autocomplete-product', _vm._b({
    attrs: {
      "label": "대상 제품",
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.target._products,
      callback: function ($$v) {
        _vm.$set(_vm.form.target, "_products", $$v);
      },
      expression: "form.target._products"
    }
  }, 'autocomplete-product', _vm.attrs_input, false))], 1)])], 1), _c('v-expand-transition', [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.target.type == _vm.SHIPPING_TARGET_TYPES.BY_CATEGORY.value,
      expression: "form.target.type == SHIPPING_TARGET_TYPES.BY_CATEGORY.value"
    }],
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "pa-2"
  }, [_c('autocomplete-category', _vm._b({
    attrs: {
      "label": "대상 카테고리",
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.target._categories,
      callback: function ($$v) {
        _vm.$set(_vm.form.target, "_categories", $$v);
      },
      expression: "form.target._categories"
    }
  }, 'autocomplete-category', _vm.attrs_input, false))], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }