<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading v-model="showsSearch" v-bind="{ title }" showsFilterButton />

            <list-search v-bind="{ loading, showsSearch }" @search="(page = 1) && search()">
                <v-row>
                    <v-col cols="6" md="3" class="py-0">
                        <v-text-field v-model="filter.createdAt[0]" label="작성일자" clearable type="date" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="py-0">
                        <v-text-field v-model="filter.createdAt[1]" label="작성일자" clearable type="date" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="py-0">
                        <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="py-0">
                        <v-text-field v-model="filter.searchValue" label="검색어" :disabled="!filter.searchKey" @keydown.enter="(page = 1) && search()" v-bind="attrs_input" />
                    </v-col>
                </v-row>
            </list-search>

            <template v-if="categories.length">
                <v-row class="mt-n3">
                    <v-col cols="auto">
                        <board-categories-list v-bind="{ categories }" />
                    </v-col>
                </v-row>
            </template>

            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.thumb`]="{ item }">
                    <v-row>
                        <v-img v-if="item.thumb" :src="item.thumb" width="54" height="54" contain></v-img>
                    </v-row>
                </template>
                <template #[`item.files.0`]="{ value }">
                    <v-btn icon text :href="value?.url" target="_blank" :disabled="!value">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
                <template #[`item.category`]="{ value }">
                    {{ categories.find((item) => item.value == value)?.text || "-" }}
                </template>
                <template #[`item.meta.href`]="{ value }">
                    <v-btn v-if="!!value" icon text :href="value" target="_blank">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                    <template v-else>-</template>
                </template>
                <template #[`item.meta.youtube`]="{ value }">
                    <v-btn icon text :href="value" target="_blank" :disabled="!value">
                        <v-icon>mdi-link</v-icon>
                    </v-btn>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()" />

            <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input, BOARD_CATEGORIES__JFL_GUIDES, initDataTableHeaders } from "@/assets/variables";

import BoardCategoriesList from "@/components/console/boards/board-categories-list.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ListSearch from "@/components/console/dumb/list-search.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
        BoardCategoriesList,
        ListHeading,
        ListSearch,
    },
    prop: {
        tab: String,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            limit: 10,

            attrs_input,

            boards: [],
            summary: { totalCount: 0 },

            searchKey: null,
            searchValue: null,
        };
    },
    computed: {
        ...mapGetters(["academyCategories"]),
        title() {
            if (this.$route.query.code == "bright-academy") return "bright Academy";
            if (this.$route.query.code == "jfl-guides") return "JFL 가이드";
            return "게시글";
        },
        categories() {
            switch (this.$route.query.code) {
                case "bright-academy": {
                    return this.academyCategories || [];
                }
                case "jfl-guides": {
                    return Object.values(BOARD_CATEGORIES__JFL_GUIDES);
                }

                default: {
                    return [];
                }
            }
        },
        headers() {
            return initDataTableHeaders(
                [
                    { width: 40, text: "no", value: "index", align: "right" },
                    { width: 80, text: "썸네일", value: "thumb", test: (code) => ["bright-academy"].includes(code) },
                    { width: 80, text: "동영상", value: "files.0", test: (code) => ["bright-academy"].includes(code) },
                    { width: 80, text: "유튜브", value: "meta.youtube", test: (code) => ["bright-academy"].includes(code) },
                    { width: 120, text: "링크", value: "meta.href", test: (code) => [].includes(code) },
                    { width: 100, text: "기간", test: (code) => [].includes(code), value: "period" },
                    { width: 180, text: "카테고리", value: "category", test: (code) => ["bright-academy"].includes(code), formatter: (value) => this.categories[value]?.text || value || "-" },
                    { text: "제목", value: "subject" },
                    { width: 160, text: "작성자", value: "writer.name", test: (code) => [].includes(code) },
                    { width: 170, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
                    { width: 80, text: "답변", value: "meta.isReplied", test: (code) => [].includes(code), formatter: (value) => (value ? "완료" : "대기"), align: "center" },
                    { width: 80, value: "actions", align: "right" },
                ].filter((header) => (header.test ? header.test(this.$route.query.code) : true))
            );
        },
        items() {
            return this.boards.map((board, index) => ({ ...board, index: this.summary.totalCount - index - (this.page - 1) * this.limit }));
        },
        searchKeys() {
            return [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ].filter(({ hides }) => !hides);
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({ query: Object.assign({}, this.filter, { page: this.page }) });
                }

                var { summary, boards } = await api.console.boards.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            const { code, category } = this.$route.query;

            const query = { code, category };
            if (!code) delete query.code;
            if (!category) delete query.category;

            this.$router.push({ path: "/console/boards/create", query });
        },
        edit(board) {
            this.$router.push(`/console/boards/${board._id}`);
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.boards.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
