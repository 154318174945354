<template>
    <list-search v-bind="{ showsSearch, loading }" @search="emit">
        <v-row class="ma-n2">
            <v-col cols="12" sm="6" md="4" class="pa-2">
                <v-select label="검색대상" v-model="query['searchKey']" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="4" class="pa-2">
                <v-text-field label="검색어" v-model="query['searchValue']" clearable v-bind="attrs_input" @keydown.enter="emit" />
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
                <autocomplete-user label="영업담당자" v-model="query['_managers']" :params="{ scope: 'manager' }" :itemText="({ username }) => username" :sort="{ username: 1 }" clearable v-bind="attrs_input" @keydown.enter="emit" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pa-2">
                <v-select label="회원유형" v-model="query['type']" :items="types" placeholder="전체" clearable v-bind="attrs_input" @change="emit" />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pa-2">
                <v-select label="회원권한" v-model="query['scope']" :items="scopeItems" v-bind="attrs_input" @change="emit" />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pa-2">
                <v-select label="주의회원" v-model="query['isWarning']" :items="isWarningItems" v-bind="attrs_input" @change="emit" />
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pa-2">
                <v-select label="가입승인" v-model="query['isApproved']" :items="isApprovedItems" v-bind="attrs_input" @change="emit" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input, attrs_switch, USER_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["searchKey"]: query?.["searchKey"] ?? null,
    ["searchValue"]: query?.["searchValue"] ?? null,

    ["_managers"]: query?.["_managers"] ?? null,
    ["type"]: query?.["type"] ?? null,

    ["scope"]: query?.["scope"] ?? null,
    ["isWarning"]: JSON.parse(query?.["isWarning"] ?? "null"),
    ["isApproved"]: JSON.parse(query?.["isApproved"] ?? "null"),
});

const types = Object.values(USER_TYPES);

const searchKeys = [
    { text: "아이디", value: "username" },
    { text: "이름(상호명)", value: "name" },
    { text: "연락처", value: "phone" },
    { text: "이메일", value: "email" },
    // { text: "상호명", value: "business.name" },
    { text: "대표자", value: "business.ceoName" },
    { text: "연동코드", value: "meta.CustNo" },
];

const scopeItems = [
    { text: "전체", value: null },
    { text: "최고관리자", value: "console" },
    { text: "영업담당자", value: "manager" },
    { text: "상담사", value: "counsel" },
    { text: "외상거래", value: "credits" },
];

const isWarningItems = [
    { text: "전체", value: null },
    { text: "주의", value: true },
    { text: "일반", value: false },
];

const isApprovedItems = [
    { text: "전체", value: null },
    { text: "승인", value: true },
    { text: "미승인", value: false },
];

export default {
    components: {
        ListSearch,
        ViewFieldCard,
        AutocompleteUser,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        types,
        searchKeys,
        scopeItems,
        isWarningItems,
        isApprovedItems,

        attrs_input,
        attrs_switch,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            if (!query?.["_managers"]) delete query["_managers"];

            if (!query?.["type"]) delete query["type"];
            if (!query?.["scope"]) delete query["scope"];

            if (query?.["isWarning"] == null) delete query["isWarning"];
            if (query?.["isApproved"] == null) delete query["isApproved"];

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
