<template>
    <v-layout justify-center>
        <v-responsive max-width="720" width="100%" height="100%">
            <list-heading title="쇼핑몰 기본정보" />
            <view-section-card title="운송업체 설정" v-bind="{ loading }">
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" md="4" class="pa-2">
                            <autocomplete-delivery v-model="delivery" label="업체명" :itemText="({ name, code }) => `${name} (${code})`" returnObject v-bind="attrs_input" />
                        </v-col>
                        <v-col cols="12" md="8" class="pa-2">
                            <v-text-field v-model="url" label="URL" v-bind="attrs_input" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn plain text color="primary" @click="save">저장하기</v-btn>
                </v-card-actions>
            </view-section-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, initSetting } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteDelivery from "@/components/dumb/autocomplete-delivery.vue";

export default {
    components: {
        ListHeading,
        ViewSectionCard,
        AutocompleteDelivery,
    },
    data: () => ({
        setting: initSetting(),
        delivery: null,

        url: null,
        attrs_input,

        loading: false,
    }),
    mounted() {
        this.init();
    },
    watch: {
        delivery() {
            this.setting.shop._delivery = this.delivery?._id || null;
            this.url = this.delivery?.url || null;
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { setting } = await api.console.setting.get();
                this.setting = initSetting(setting);

                this.delivery = this.setting.shop.delivery;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.delivery?._id) {
                    const { _id } = this.delivery;
                    const { url } = this;
                    await api.console.shop.deliveries.put({ _id, url });
                }

                const { shop } = this.setting;
                await api.console.setting.put({ shop });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-row {
        height: auto !important;
        max-height: auto !important;
        min-height: auto !important;

        &:first-of-type {
            padding-top: 12px;
        }
        &:last-of-type {
            padding-bottom: 12px;
        }
    }
    .v-data-table__mobile-row__header {
        flex: 0 0 25%;
    }
}
</style>
