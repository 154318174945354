<template>
    <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined" style="flex: 1 0 auto; position: relative">
        <template #top> <div class="table-label px-1 white">배송정보</div> </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.receiver.isFactory`]>
            <v-checkbox :value="order?.receiver?.isFactory" readonly hide-details class="ma-0 pa-0">
                <template #label> {{ order?.receiver?.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다" }} </template>
            </v-checkbox>
        </template>
    </v-data-table>
</template>

<script>
const headers = [
    // { text: "배송지", value: "receiver.title", formatter: (value) => value || "기본배송지" },
    { text: "수취인", value: "receiver.name" },
    {
        text: "주소",
        value: "receiver",
        formatter: ({ postcode, address1, address2 } = {}) => {
            let address = "";
            if (postcode) address += `[${postcode}]`;
            if (address1) address += ` ${address1}`;
            if (address2) address += ` ${address2}`;
            return address || "-";
        },
    },
    { text: "일반전화", value: "receiver.tel" },
    { text: "휴대전화", value: "receiver.phone" },
    // { text: "기공소", value: "receiver.isFactory", formatter: (bool) => (bool ? "O" : "X") },
    { text: "요청사항", value: "requestMessage" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    props: {
        order: { type: Object, default: () => {} },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [this.order];
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-width: 80px;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
