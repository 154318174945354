var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "480",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "bright Academy 카테고리"
    }
  }), _c('view-section-card', _vm._b({
    attrs: {
      "hideTitle": ""
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, [_c('draggable', {
    model: {
      value: _vm.form.academy.categories,
      callback: function ($$v) {
        _vm.$set(_vm.form.academy, "categories", $$v);
      },
      expression: "form.academy.categories"
    }
  }, [_vm._l(_vm.form.academy.categories, function (item, index) {
    return [_c('v-chip', {
      key: item,
      attrs: {
        "outlined": "",
        "label": "",
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.form.academy.categories.splice(index, 1);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1), _c('v-text-field', _vm._b({
    attrs: {
      "append-icon": "mdi-plus"
    },
    on: {
      "click:append": _vm.addItem,
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addItem.apply(null, arguments);
      }
    },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "red"
    },
    on: {
      "click": _vm.init
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("초기화하기")]), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장하기")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }