<template>
    <list-search v-bind="{ showsSearch }" @search="emit">
        <v-row align="center">
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query['createdAt'][0]" type="date" label="다운로드일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <v-text-field v-model="query['createdAt'][1]" type="date" label="다운로드일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <autocomplete-user v-model="query['_user']" label="회원" clearable v-bind="attrs_input" v-on="{ emit }" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="">
                <autocomplete-catalogue v-model="query['_software']" clearable label="소프트웨어" :params="{ code: 'software' }" v-bind="attrs_input" v-on="{ emit }" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteCatalogue from "@/components/dumb/autocomplete-catalogue.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

const searchKeys = [
    { text: "회원이름", value: "name" },
    { text: "휴대폰번호", value: "phone" },
];

export default {
    components: {
        ListSearch,

        AutocompleteUser,
        AutocompleteCatalogue,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            if (!query?.["createdAt"]) delete query.createdAt;
            if (!query?.["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query.createdAt;

            if (!query?.["searchKey"]) delete query.searchKey;
            if (!query?.["searchValue"]) delete query.searchValue;

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
