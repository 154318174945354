var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('v-data-table', _vm._b({
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: `item.commit`,
      fn: function (_ref) {
        var item = _ref.item;
        return [item.key == 'brands' ? [_c('commit-brands', _vm._g(_vm._b({
          ref: "commit-brands",
          attrs: {
            "text": "",
            "block": "",
            "tile": "",
            "height": "48"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v;
            },
            expression: "result"
          }
        }, 'commit-brands', {
          parsed: _vm.parsed,
          loading: _vm.loading
        }, false), {
          setLoading: _vm.setLoading
        }))] : _vm._e(), item.key == 'categories' ? [_c('commit-categories', _vm._g(_vm._b({
          ref: "commit-categories",
          attrs: {
            "text": "",
            "block": "",
            "tile": "",
            "height": "48"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v;
            },
            expression: "result"
          }
        }, 'commit-categories', {
          parsed: _vm.parsed,
          loading: _vm.loading
        }, false), {
          setLoading: _vm.setLoading
        }))] : _vm._e(), item.key == 'options' ? [_c('commit-options', _vm._g(_vm._b({
          ref: "commit-options",
          attrs: {
            "text": "",
            "block": "",
            "tile": "",
            "height": "48"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v;
            },
            expression: "result"
          }
        }, 'commit-options', {
          parsed: _vm.parsed,
          loading: _vm.loading
        }, false), {
          setLoading: _vm.setLoading
        }))] : _vm._e(), item.key == 'products' ? [_c('commit-products', _vm._g(_vm._b({
          ref: "commit-products",
          attrs: {
            "text": "",
            "block": "",
            "tile": "",
            "height": "48"
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v;
            },
            expression: "result"
          }
        }, 'commit-products', {
          parsed: _vm.parsed,
          loading: _vm.loading
        }, false), {
          setLoading: _vm.setLoading
        }))] : _vm._e()];
      }
    }, {
      key: "foot",
      fn: function () {
        return [_c('tr', [_c('td', {
          attrs: {
            "colspan": _vm.headers.length
          }
        }, [_c('v-divider')], 1)]), _c('tr', [_c('td', {
          attrs: {
            "colspan": _vm.headers.length - 1
          }
        }), _c('td', [_c('v-btn', _vm._b({
          attrs: {
            "text": "",
            "block": "",
            "height": "48",
            "color": "primary",
            "disabled": _vm.commitDisabled
          },
          on: {
            "click": _vm.commit
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), [_vm._v(" 전체등록 ")])], 1)]), _c('tr', [_c('td', {
          attrs: {
            "colspan": _vm.headers.length
          }
        }, [_c('v-divider')], 1)])];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.file,
      expression: "!file"
    }],
    staticClass: "black--text",
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-card-title', [_vm._v(" 파일을 먼저 등록해주세요 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }