<template>
    <v-select v-model="active" v-bind="{ ...$attrs, label, items }" @change="emit" />
</template>

<script>
import { mapGetters } from "vuex";
import { DISPLAY_CODES } from "@/assets/variables";

export default {
    props: {
        value: { type: Boolean, default: null },
        code: { type: String, default: DISPLAY_CODES.POPULAR.value },
    },
    data: () => ({
        active: null,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
        label() {
            return this.displayCodes.find(({ value }) => value == this.code)?.text;
        },
        items() {
            return [
                { text: "전체", value: null },
                { text: "활성", value: true },
                { text: "비활성", value: false },
            ];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.active = this.value;
        },
        emit() {
            this.$emit("input", this.active);
        },
    },
};
</script>

<style></style>
