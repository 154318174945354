var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "640",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "브랜드 목록"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('brand-form', {
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.label`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.url,
            "attrs_avatar": {
              size: 48,
              rounded: true
            }
          }
        })];
      }
    }, {
      key: `item.thumb`,
      fn: function (_ref4) {
        var value = _ref4.value;
        return [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.url,
            "attrs_avatar": {
              size: 48,
              rounded: true
            }
          }
        })];
      }
    }, {
      key: `item.image`,
      fn: function (_ref5) {
        var value = _ref5.value;
        return [_c('image-popup', {
          attrs: {
            "src": value === null || value === void 0 ? void 0 : value.url,
            "attrs_avatar": {
              size: 48,
              rounded: true
            }
          }
        })];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (shows) {
              return _vm.update({
                _id: item._id,
                shows
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('brand-form', _vm._b({
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var attrs = _ref8.attrs,
                on = _ref8.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" mdi-pencil ")])], 1)];
            }
          }], null, true)
        }, 'brand-form', item, false)), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }