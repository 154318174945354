<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%" class="shop-product-view">
            <list-heading title="회원 상세" />

            <v-row class="ma-n2">
                <v-col cols="12" md="6" lg="3" class="pa-2">
                    <view-section-card title="기본정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.username" label="아이디" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.password" label="비밀번호" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.phone" label="연락처" v-bind="attrs_input" />
                                </v-col>
                                <!-- <v-col cols="12" class="pa-2">
                                    <v-select v-model="user.type" label="유형" :items="userTypes" v-bind="attrs_input" readonly />
                                </v-col> -->
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.bizNumber" label="사업자등록번호" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <view-field-card :href="user.businessRegistration?.href" target="_blank" label="사업자등록증">
                                        <v-icon v-if="user.businessRegistration" small class="mr-1"> mdi-file-outline </v-icon>
                                        {{ user.businessRegistration?.fileName || "등록된 파일이 없습니다" }}
                                    </view-field-card>
                                    <!-- <v-text-field v-model="user.businessRegistration" label="사업자등록증" v-bind="attrs_input" /> -->
                                </v-col>
                                <!-- <v-col cols="12" class="pa-2">
                                    <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" v-bind="attrs_input" />
                                </v-col> -->
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="6" lg="3" class="pa-2">
                    <view-section-card title="관리정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.meta.CustNo" label="연동코드" messages="※ 변경 시 저장 필수" v-bind="attrs_input">
                                        <template #append> <v-btn v-bind="{ loading }" small plain text height="32" color="primary" class="mt-n1 mr-n2" :disabled="!user.meta.CustNo" @click="sync">조회</v-btn> </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.money" label="선수금" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" md="6" class="pa-2">
                                    <view-field-card label="가입승인">
                                        <v-switch v-model="user.isApproved" dense v-bind="attrs_switch" class="mt-1 mb-n1" color="primary" />
                                    </view-field-card>
                                </v-col>
                                <v-col cols="12" md="6" class="pa-2">
                                    <view-field-card label="주의회원">
                                        <v-switch v-model="user.isWarning" dense v-bind="attrs_switch" class="mt-1 mb-n1" color="red lighten-1" />
                                    </view-field-card>
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <autocomplete-user v-model="user._managers" label="영업담당자" :params="{ scope: 'manager' }" :itemText="({ username, name }) => (name ? `${username} (${name})` : username)" multiple v-bind="attrs_input" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="6" lg="3" class="pa-2">
                    <view-section-card title="업체정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.name" label="상호명" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.no" label="사업자등록번호" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.ceoName" label="대표자" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.service" label="업종" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.type" label="업태" v-bind="attrs_input" readonly />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="6" lg="3" class="pa-2">
                    <view-section-card title="연락정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.tel" label="연락처" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.email" label="이메일" v-bind="attrs_input" :readonly="!!user.meta.CustNo" />
                                </v-col>

                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.postcode" label="우편번호" v-bind="attrs_input" readonly />
                                </v-col>

                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.address1" label="기본주소" v-bind="attrs_input" readonly />
                                </v-col>
                                <v-col cols="12" class="pa-2">
                                    <v-text-field v-model="user.business.address2" label="상세주소" v-bind="attrs_input" readonly />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <view-section-card title="주문내역" v-bind="{ loading }">
                        <v-data-table v-bind="{ items, headers }">
                            <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                            <template #[`item.numbers`]="{ item }">
                                <div>
                                    <order-view :_order="item._order">
                                        <template #activator="{ attrs, on }">
                                            <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                                        </template>
                                    </order-view>
                                </div>
                                <div>{{ item.purchaseNo }}</div>
                            </template>
                            <template #[`item.order.sender`]="{ item }">
                                <div>{{ item.order.sender.name }}</div>
                                <div>{{ item.order.sender.phone }}</div>
                                <div>{{ item.order.sender.email }}</div>
                            </template>
                            <template #[`item.order.receiver`]="{ item }">
                                <div>{{ item.order.receiver.name }}</div>
                                <div>{{ item.order.receiver.phone }}</div>
                                <div>{{ item.order.receiver.email }}</div>
                            </template>
                        </v-data-table>
                    </view-section-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { attrs_input, attrs_switch, initUser, USER_TYPES } from "@/assets/variables";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

const headers = [
    { width: 190, divider: true, text: "주문번호\n제품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, divider: true, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, divider: true, text: "주문상태", value: "orderStatusMessage" },
    { width: 120, divider: true, text: "클레임상태", value: "claimStatusMessage" },
    { width: 120, divider: true, text: "연락처", value: "order.sender.phone" },
    { width: 200, divider: true, text: "구매제품", value: "product.name" },
    { width: 200, divider: true, text: "옵션정보", value: "name" },
    { width: 100, divider: true, text: "구매수량", value: "amount" },
    { width: 120, divider: true, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

const userTypes = Object.values(USER_TYPES);

export default {
    components: {
        OrderView,
        ViewSectionCard,
        ListHeading,
        ViewFieldCard,
        VPhoneField,
        AutocompleteUser,
    },
    data: () => ({
        user: initUser(),
        levels: [],

        purchases: [],

        headers,
        userTypes,
        attrs_input,
        attrs_switch,

        loading: false,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
    },
    mounted() {
        this.init().then(this.sync);
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                this.user = initUser(user);

                var { levels } = await api.console.levels.gets();
                this.levels = levels;

                var { purchases } = await api.console.shop.purchases.getPurchases({
                    params: { _user: this.$route.params._user },
                });

                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { password, ...form } = this.user;

                if (password) form.password = CryptoAES.encrypt(password);
                else delete form.password;

                await api.console.users.put(form);

                alert("수정되었습니다");
            } finally {
                this.loading = false;
            }
        },

        async sync() {
            if (!this.user.meta.CustNo) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                const { customer, customerInfo, customerInfo2 } = await api.dentium.customers.get({ CustNo: this.user.meta.CustNo });

                this.user.tel = customer?.TellNo || customerInfo?.Tel2;
                this.user.email = customerInfo?.Email?.trim?.() || null;

                this.user.business = {
                    ...(this.user.business || {}),
                    no: customer?.BizNo?.trim?.() || null,
                    name: customer?.FullName?.trim?.() || null,
                    type: customer?.BizType?.trim?.() || null,
                    service: customer?.BizKind?.trim?.() || null,
                    ceoName: customer?.Owner?.trim?.() || null,
                    postcode: customerInfo?.ZipCode?.trim?.() || null,
                    address1: customerInfo?.KorAddr1?.trim?.() || null,
                    address2: customerInfo?.KorAddr2?.trim?.() || null,
                };

                this.user.money = -(customerInfo2?.NonReceiptAmt || 0);

                this.user = initUser(this.user);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
