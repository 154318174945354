<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading title="사이트 관리" />

            <v-row class="ma-n2">
                <v-col cols="12" md="" class="pa-2">
                    <v-card outlined v-bind="{ loading }">
                        <v-card-title>
                            <v-row>
                                <v-col cols="auto" class="py-0 subtitle-1">SEO(검색어 최적화 설정)</v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" class="pa-2"> <v-text-field v-model="setting.seo.title" label="타이틀: Title" v-bind="attrs_input" @input="(meta('title').content = setting.seo.title) || true" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-text-field v-model="meta('title').content" label="메타태그: Title" disabled v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-text-field v-model="meta('author').content" label="메타태그: Author" v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-text-field v-model="meta('subject').content" label="메타태그: Subject" v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-text-field v-model="meta('copyright').content" label="메타태그: Copyright" v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-textarea v-model="meta('description').content" label="메타태그: Description" v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" class="pa-2"> <v-textarea v-model="meta('keywords').content" label="메타태그: Keywords" v-bind="attrs_input" /> </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="" class="pa-2">
                    <view-section-card title="무통장입금 계좌연동" hideDivider v-bind="{ loading }">
                        <template #actions>
                            <v-switch v-model="setting.shop.cashReceipt.enabled" v-bind="attrs_switch"> </v-switch>
                        </template>

                        <v-expand-transition>
                            <v-sheet v-show="setting.shop.cashReceipt.enabled">
                                <v-divider />

                                <v-card-text>
                                    <v-row class="ma-n2">
                                        <v-col cols="6" class="pa-2"> <v-select v-model="setting.shop.accountBankCode" label="은행" :items="banks" item-text="name" item-value="code" v-bind="attrs_input" /> </v-col>
                                        <v-col cols="6" class="pa-2"> <v-text-field v-model="setting.shop.accountHolder" label="예금주" v-bind="attrs_input" /> </v-col>
                                        <v-col cols="12" class="pa-2"> <v-text-field v-model="setting.shop.accountNumber" label="계좌번호" v-bind="attrs_input" /> </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-sheet>
                        </v-expand-transition>
                    </view-section-card>

                    <view-section-card title="OpenAI 연동" hideDivider class="mt-4" v-bind="{ loading }">
                        <template #actions>
                            <v-switch v-model="setting.openai.enabled" v-bind="attrs_switch"> </v-switch>
                        </template>

                        <v-expand-transition>
                            <v-sheet v-show="setting.openai.enabled">
                                <v-divider />

                                <v-card-text>
                                    <v-row class="ma-n2">
                                        <v-col cols="6" class="pa-2"> <v-text-field v-model="setting.openai.organization" label="Organization" v-bind="attrs_input" /> </v-col>
                                        <v-col cols="6" class="pa-2"> <v-text-field v-model="setting.openai.apiKey" label="API Key" v-bind="attrs_input" /> </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-sheet>
                        </v-expand-transition>
                    </view-section-card>

                    <view-section-card title="SMTP 사용" hideDivider class="mt-4" v-bind="{ loading }">
                        <template #actions>
                            <v-tooltip>
                                <v-icon> mdi-file </v-icon>
                            </v-tooltip>
                            <v-switch v-model="setting.smtp.enabled" v-bind="attrs_switch"> </v-switch>
                        </template>
                        <v-expand-transition>
                            <v-sheet v-show="setting.smtp.enabled">
                                <v-divider />

                                <v-card-text>
                                    <v-row class="ma-n2">
                                        <v-col cols="6" class="pa-2"> <v-text-field v-model="setting.smtp.username" label="아이디" v-bind="attrs_input" /> </v-col>
                                        <v-col cols="6" class="pa-2"> <v-text-field v-model="setting.smtp.password" label="비밀번호" autocomplete="new-password" v-bind="attrs_input" /> </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-sheet>
                        </v-expand-transition>
                    </view-section-card>

                    <view-section-card title="관리자 비밀번호 변경" class="mt-4" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="12" md="" class="pa-2"> <v-text-field v-model="admin.password" type="password" autocomplete="new-password" label="비밀번호" v-bind="attrs_input" /> </v-col>
                                <v-col cols="12" md="" class="pa-2"> <v-text-field v-model="admin.passwordRepeat" type="password" label="비밀번호 확인" v-bind="attrs_input" /> </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="py-1">
                            <v-spacer />
                            <v-btn text color="primary" v-bind="{ loading }" @click="saveAdmin">저장</v-btn>
                        </v-card-actions>
                    </view-section-card>
                </v-col>
            </v-row>
            <v-btn fixed right bottom fab color="primary" v-bind="{ loading }" @click="save"> <v-icon>mdi-content-save</v-icon> </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import banks from "@/plugins/shop-banks.json";
import { attrs_input, attrs_switch, initSetting } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    components: {
        ListHeading,
        ViewSectionCard,
    },
    data: () => ({
        setting: initSetting(),
        banks,
        admin: {
            password: "",
            passwordRepeat: "",
        },

        attrs_input,
        attrs_switch,

        loading: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            try {
                var { setting } = await api.console.setting.get();
                if (setting?.smtp?.password) {
                    setting.smtp.password = CryptoAES.decrypt(setting.smtp.password);
                }

                this.setting = initSetting(setting);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        meta(name) {
            var meta = this.setting.seo.meta.find((meta) => meta.name == name);
            if (!meta) {
                meta = { name, content: null };
                this.setting.seo.meta.push(meta);
            }
            return meta;
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const {
                    smtp: { ...smtp },
                    ...setting
                } = this.setting;
                if (smtp?.password) {
                    smtp.password = CryptoAES.encrypt(smtp?.password);
                }
                await api.console.setting.put({ ...setting, smtp });
            } finally {
                this.loading = false;
            }
        },

        async saveAdmin(event) {
            event.preventDefault();

            const { password, passwordRepeat } = this.admin;

            try {
                if (password !== passwordRepeat) throw new Error("비밀번호가 일치하지 않습니다.");

                await api.console.users.put({ _id: this.$store.state.payload._user, password });

                alert("비밀번호가 변경되었습니다.");
            } catch (error) {
                this.handleError(error);
            }
        },

        handleError(error) {
            console.error(error);
            if (error.response) alert(error.response.data.message);
            else alert(error.message);
        },
    },
};
</script>
