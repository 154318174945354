<template></template>

<script>
import { mapState } from "vuex";

import Dashboard from "@/pages/console/Dashboard.vue";

export default {
    components: {
        Dashboard,
    },
    computed: {
        ...mapState(["scope"]),
    },
    methods: {
        scopeIncludes(criterion) {
            return this.scope?.includes?.(criterion);
        },
    },
    mounted() {
        if (this.scopeIncludes("console")) this.$router.replace("/console/dashboard");
        if (this.scopeIncludes("manager")) this.$router.replace("/console/shop/orders");
    },
};
</script>
