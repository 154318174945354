var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "outlined": "",
      "flat": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 " + _vm._s(_vm.isCreate ? "생성하기" : "수정하기"))]), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.categories ? _c('shop-category-select', _vm._b({
    attrs: {
      "dense": "",
      "hideDetails": ""
    },
    model: {
      value: _vm.form._parent,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_parent", $$v);
      },
      expression: "form._parent"
    }
  }, 'shop-category-select', Object.assign({}, _vm.attrs_input, {
    categories: _vm.categories,
    _disableds: [_vm.form._id]
  }), false)) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "카테고리 이름"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "카테고리 코드"
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "카테고리 색상"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-card', {
          attrs: {
            "outlined": "",
            "rounded": "xl"
          }
        }, [_c('v-avatar', {
          attrs: {
            "color": _vm.form.color,
            "size": "24"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.color,
      callback: function ($$v) {
        _vm.$set(_vm.form, "color", $$v);
      },
      expression: "form.color"
    }
  }, 'v-text-field', _vm.attrs_input, false)), _c('shop-category-color')], 1)], 1)], 1), _c('v-card-actions', [_c('v-fade-transition', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCreate,
      expression: "!isCreate"
    }],
    staticClass: "white--text",
    attrs: {
      "disabled": !_vm.form._id || _vm.form.root ? true : false,
      "color": "grey darken-1"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }