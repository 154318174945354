<template>
    <v-card v-bind="$attrs">
        <v-card-text class="caption">
            <v-row class="ma-n4" style="word-break: keep-all">
                <template v-for="{ term, desc } in items">
                    <v-col cols="12" class="py-3 px-4" :key="term">
                        <div class="font-weight-bold grey--text">{{ term }}</div>
                        <div class="white-space-pre-line">{{ desc }}</div>
                    </v-col>
                </template>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { initIssue, initShopShipping, SHIPPING_TARGET_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initShopShipping },
    },
    computed: {
        duration() {
            const { startsAt, endsAt } = this.value;

            let text = "";

            if (startsAt) text += startsAt.toDateTime();
            else text += "시작일시없음";

            text += "\n~ ";

            if (endsAt) text += endsAt.toDateTime();
            else text += "종료일시없음";

            return text;
        },
        items() {
            return [
                {
                    term: "정책명",
                    desc: this.value.name || "-",
                },
                {
                    term: "사용기간",
                    desc: this.duration,
                },
                {
                    term: "대상 유형",
                    desc: SHIPPING_TARGET_TYPES[this.value.target.type]?.text,
                },
                {
                    term: "대상 제품",
                    desc: (this.value.target.products || []).map(({ name }) => name).join(", "),
                    hides: this.value.target.type != SHIPPING_TARGET_TYPES.BY_PRODUCTS.value,
                },
                {
                    term: "대상 카테고리",
                    desc: (this.value.target.categories || []).map(({ name }) => name).join(", "),
                    hides: this.value.target.type != SHIPPING_TARGET_TYPES.BY_CATEGORY.value,
                },
            ].filter(({ hides }) => !hides);
        },
    },
};
</script>

<style></style>
