<template>
    <v-layout justify-center>
        <v-responsive max-width="720" width="100%">
            <list-heading :title="`${title} 목록`">
                <template #add-button>
                    <catalogue-form v-bind="{ code }" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </catalogue-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ headers, items, loading }" hide-default-footer disable-filtering disable-pagination disable-sort>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.file`]="{ value }">
                    <v-btn small text icon @click="download(value?.url)">
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch" @change="(shows) => save({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <catalogue-form v-bind="{ value: item, code }" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </catalogue-form>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import { attrs_switch, initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CatalogueForm from "@/components/console/shop/catalogues/catalogue-form.vue";

export default {
    components: {
        ListHeading,
        CatalogueForm,
    },

    data: () => ({
        catalogues: [],

        limit: 10,
        summary: { totalCount: 0 },

        attrs_switch,

        loading: false,
    }),
    computed: {
        code() {
            return this.$route.query.code || "catalogue";
        },
        title() {
            switch (this.code) {
                case "software": {
                    return "소프트웨어";
                }
                case "catalogue":
                default: {
                    return "카탈로그";
                }
            }
        },
        headers() {
            const headers = [];
            headers.push({ value: "index", align: "right", width: +40 });
            if (this.code == "catalogue") {
                headers.push({ text: "카테고리", value: "category.name", cellClass: "text-truncate max-width-0 py-3", width: 160 });
            }
            headers.push(
                ...[
                    { text: "이름", value: "name", cellClass: "text-truncate max-width-0 py-3" },
                    { text: "파일", value: "file", align: "center", width: +60 },
                    { text: "표시", value: "shows", align: "center", width: +80 },
                    { value: "actions", align: "right", width: +90 },
                ]
            );

            return initDataTableHeaders(headers);
        },
        items() {
            return this.catalogues.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        ...mapActions(["getCategories"]),
        async init() {
            this.catalogues = [];
            this.summary = { totalCount: 0 };
            this.getCategories();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;

                var { summary, catalogues } = await api.console.shop.catalogues.gets({
                    headers: { skip, limit },
                    params,
                });

                this.catalogues = catalogues;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },
        async update(item) {
            const index = this.catalogues.findIndex(({ _id }) => _id == item._id);
            console.log(item, index);
            if (-1 < index) this.catalogues.splice(index, 1, item);
            else {
                this.catalogues = [item, ...this.catalogues];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _file, _id, name }) {
            const go = confirm(`${name} - 카탈로그를 삭제하시겠습니까?`);
            if (go) {
                if (_file) await api.console.files.delete({ _id: _file });
                await api.console.shop.catalogues.delete({ _id });
                this.catalogues = this.catalogues.filter((catalogue) => catalogue?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item) {
            const { catalogue } = await api.console.shop.catalogues.put(item);
            this.update(catalogue);
        },
        async download(href) {
            console.log(href);
            if (href) await api.downloadMemberResource(href);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
