<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading title="SMTP 발송" />

            <v-card outlined v-bind="{ loading }">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="py-0 subtitle-1">SEO(검색어 최적화 설정)</v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="12" class="pa-2"> <v-text-field v-model="form.email" label="수신자 이메일" v-bind="attrs_input" /> </v-col>
                        <v-col cols="12" class="pa-2"> <v-text-field v-model="form.subject" label="제목" v-bind="attrs_input" /> </v-col>
                        <v-col cols="12" class="pa-2"> <naver-smarteditor v-model="form.html" label="내용" v-bind="attrs_input" /> </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn plain text color="primary" v-bind="{ loading }" @click="post"> 발송하기 </v-btn>
                </v-card-actions>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const initForm = (form = {}) => ({
    ...(form || {}),
    email: form?.email || null,
    subject: form?.subject || null,
    html: form?.html || null,
});

export default {
    components: {
        ListHeading,
        NaverSmarteditor,
    },
    data: () => ({
        form: initForm(),
        loading: false,
        attrs_input,
    }),
    methods: {
        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.smtp.post(this.form);
                alert("발송되었습니다.");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
