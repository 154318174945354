<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query.createdAt[0]" type="date" max="2999-12-31" label="요청일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field v-model="query.createdAt[1]" type="date" max="2999-12-31" label="요청일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input" @keydown.enter="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input, USER_TYPES } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

let initQuery = (query = {}) => ({
    ...query,
    ["createdAt"]: query?.["createdAt"] || [null, null],
    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,
});

const searchKeys = [
    { text: "작성자", value: "writer.name" },
    { text: "연락처", value: "writer.phone" },
    { text: "제목", value: "subject" },
    { text: "내용", value: "content" },
];

export default {
    components: {
        ListSearch,
        AutocompleteUser,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,

        attrs_input,
        USER_TYPES,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["createdAt"]) delete query["createdAt"];
            if (!query?.["createdAt"]?.[0] && !query?.["createdAt"]?.[1]) delete query["createdAt"];

            if (!query?.["searchKey"]) delete query["searchKey"];
            if (!query?.["searchValue"]) delete query["searchValue"];

            query.page = 1;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
