<template>
    <v-layout column fill-height>
        <list-heading title="대량등록" style="flex: 0 0 auto" />
        <v-tabs v-model="tab" style="flex: 0 0 auto">
            <v-tab>파일선택</v-tab>
            <v-tab>내용확인</v-tab>
            <v-tab>등록처리</v-tab>
        </v-tabs>
        <v-divider />
        <v-tabs-items :value="tab" style="flex: 1 0 auto">
            <v-tab-item class="fill-height" style="overflow: hidden">
                <tab-upload v-model="file" />
            </v-tab-item>
            <v-tab-item class="fill-height" style="overflow: hidden">
                <tab-verify v-model="parsed" v-bind="{ file, result }" />
            </v-tab-item>
            <v-tab-item class="fill-height" style="overflow: hidden">
                <tab-commit v-model="result" v-bind="{ file, parsed }" />
            </v-tab-item>
        </v-tabs-items>
    </v-layout>
</template>

<script>
import { initShopBulkUploadData } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import TabUpload from "@/components/console/shop/bulk-upload/tab-upload.vue";
import TabVerify from "@/components/console/shop/bulk-upload/tab-verify.vue";
import TabCommit from "@/components/console/shop/bulk-upload/tab-commit.vue";

export default {
    components: {
        ListHeading,
        TabUpload,
        TabVerify,
        TabCommit,
    },
    data: () => ({
        tab: 0,

        file: null,

        parsed: initShopBulkUploadData(),
        result: initShopBulkUploadData(),

        loading: false,
    }),
    mounted() {
        this.init();
    },
    watch: {
        file(file) {
            if (file) this.tab = 1;
        },
    },
    methods: {
        async init() {
            this.file = null;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-window__container {
        height: 100%;
    }
}
</style>