var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1440",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "상담예약 목록",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('form-list-search', _vm._b({}, 'form-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.state`,
      fn: function (_ref2) {
        var _vm$FORM_STATES$value;
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('form-state-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-2 my-n2",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(((_vm$FORM_STATES$value = _vm.FORM_STATES[value]) === null || _vm$FORM_STATES$value === void 0 ? void 0 : _vm$FORM_STATES$value.text) || "-") + " ")])], 1)];
      }
    }, {
      key: `item.memo`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('form-memo-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-2 my-n2 fill-height",
          attrs: {
            "flat": "",
            "tile": "",
            "color": "transparent"
          }
        }, [_vm._v(" " + _vm._s(value || "-") + " ")])], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref4) {
        var item = _ref4.item,
          headers = _ref4.headers;
        return [_c('td'), _c('td', {
          staticClass: "px-0",
          attrs: {
            "colspan": headers.length - 1
          }
        }, [_c('v-sheet', {
          staticClass: "fill-height"
        }, [_c('v-card-subtitle', {
          staticClass: "caption"
        }, [_vm._v("문의내용")]), _c('v-card-text', {
          staticClass: "white-space-pre-line"
        }, [_vm._v(" " + _vm._s(item.content) + " ")])], 1)], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }