var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "480",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "light": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "number",
      "label": "#",
      "disabled": _vm.isCreate
    },
    model: {
      value: _vm.form.index,
      callback: function ($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "라벨"
    },
    model: {
      value: _vm.form.label,
      callback: function ($$v) {
        _vm.$set(_vm.form, "label", $$v);
      },
      expression: "form.label"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "부제"
    },
    model: {
      value: _vm.form.subtitle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subtitle", $$v);
      },
      expression: "form.subtitle"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.title,
      callback: function ($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "요약",
      "rows": "1",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.summary,
      callback: function ($$v) {
        _vm.$set(_vm.form, "summary", $$v);
      },
      expression: "form.summary"
    }
  }, 'v-textarea', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기간(시작)",
      "type": "date",
      "clearable": ""
    },
    model: {
      value: _vm.form.duration[0],
      callback: function ($$v) {
        _vm.$set(_vm.form.duration, 0, $$v);
      },
      expression: "form.duration[0]"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기간(종료)",
      "type": "date",
      "clearable": ""
    },
    model: {
      value: _vm.form.duration[1],
      callback: function ($$v) {
        _vm.$set(_vm.form.duration, 1, $$v);
      },
      expression: "form.duration[1]"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("썸네일")]), _c('image-input', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "accept": "image/*",
      "width": "122",
      "height": "122",
      "outlined": ""
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2 pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_vm._v("이미지")]), _c('image-input', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "accept": "image/*",
      "width": "122",
      "height": "122",
      "outlined": ""
    },
    model: {
      value: _vm.form.photo,
      callback: function ($$v) {
        _vm.$set(_vm.form, "photo", $$v);
      },
      expression: "form.photo"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-product', _vm._b({
    ref: "autocomplete-product",
    attrs: {
      "label": "제품",
      "multiple": ""
    },
    model: {
      value: _vm.form._products,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_products", $$v);
      },
      expression: "form._products"
    }
  }, 'autocomplete-product', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-product', _vm._b({
    ref: "autocomplete-product",
    attrs: {
      "label": "주요제품",
      "multiple": "",
      "params": {
        _id: _vm.form._products
      }
    },
    model: {
      value: _vm.form._products__main,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_products__main", $$v);
      },
      expression: "form._products__main"
    }
  }, 'autocomplete-product', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-switch', _vm._b({
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.form.shows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shows", $$v);
      },
      expression: "form.shows"
    }
  }, 'v-switch', _vm.attrs_switch, false), [_c('span', {
    staticClass: "subtitle-2",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" " + _vm._s(_vm.form.shows ? "표기" : "미표기") + " ")])])], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }