var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "mt-2"
  }, [_c('v-row', {
    staticClass: "ma-n1"
  }, _vm._l(_vm.color, function (detail) {
    return _c('v-col', {
      key: detail,
      staticClass: "pa-1",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "small": "",
        "dark": "",
        "label": "",
        "color": detail
      }
    }, [_vm._v(_vm._s(detail))])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }