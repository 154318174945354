var render = function render(){
  var _vm$user$businessRegi, _vm$user$businessRegi2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "회원 상세"
    }
  }), _c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "기본정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "아이디"
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "비밀번호"
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처"
    },
    model: {
      value: _vm.user.phone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "phone", $$v);
      },
      expression: "user.phone"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사업자등록번호"
    },
    model: {
      value: _vm.user.bizNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user, "bizNumber", $$v);
      },
      expression: "user.bizNumber"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-field-card', {
    attrs: {
      "href": (_vm$user$businessRegi = _vm.user.businessRegistration) === null || _vm$user$businessRegi === void 0 ? void 0 : _vm$user$businessRegi.href,
      "target": "_blank",
      "label": "사업자등록증"
    }
  }, [_vm.user.businessRegistration ? _c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-file-outline ")]) : _vm._e(), _vm._v(" " + _vm._s(((_vm$user$businessRegi2 = _vm.user.businessRegistration) === null || _vm$user$businessRegi2 === void 0 ? void 0 : _vm$user$businessRegi2.fileName) || "등록된 파일이 없습니다") + " ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "관리정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연동코드",
      "messages": "※ 변경 시 저장 필수"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', _vm._b({
          staticClass: "mt-n1 mr-n2",
          attrs: {
            "small": "",
            "plain": "",
            "text": "",
            "height": "32",
            "color": "primary",
            "disabled": !_vm.user.meta.CustNo
          },
          on: {
            "click": _vm.sync
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), [_vm._v("조회")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.user.meta.CustNo,
      callback: function ($$v) {
        _vm.$set(_vm.user.meta, "CustNo", $$v);
      },
      expression: "user.meta.CustNo"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "선수금",
      "readonly": ""
    },
    model: {
      value: _vm.user.money,
      callback: function ($$v) {
        _vm.$set(_vm.user, "money", $$v);
      },
      expression: "user.money"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-field-card', {
    attrs: {
      "label": "가입승인"
    }
  }, [_c('v-switch', _vm._b({
    staticClass: "mt-1 mb-n1",
    attrs: {
      "dense": "",
      "color": "primary"
    },
    model: {
      value: _vm.user.isApproved,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isApproved", $$v);
      },
      expression: "user.isApproved"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('view-field-card', {
    attrs: {
      "label": "주의회원"
    }
  }, [_c('v-switch', _vm._b({
    staticClass: "mt-1 mb-n1",
    attrs: {
      "dense": "",
      "color": "red lighten-1"
    },
    model: {
      value: _vm.user.isWarning,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isWarning", $$v);
      },
      expression: "user.isWarning"
    }
  }, 'v-switch', _vm.attrs_switch, false))], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('autocomplete-user', _vm._b({
    attrs: {
      "label": "영업담당자",
      "params": {
        scope: 'manager'
      },
      "itemText": function (_ref) {
        var username = _ref.username,
          name = _ref.name;
        return name ? `${username} (${name})` : username;
      },
      "multiple": ""
    },
    model: {
      value: _vm.user._managers,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_managers", $$v);
      },
      expression: "user._managers"
    }
  }, 'autocomplete-user', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "업체정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상호명",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.name,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "name", $$v);
      },
      expression: "user.business.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "사업자등록번호",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.no,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "no", $$v);
      },
      expression: "user.business.no"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "대표자",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.ceoName,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "ceoName", $$v);
      },
      expression: "user.business.ceoName"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "업종",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.service,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "service", $$v);
      },
      expression: "user.business.service"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "업태",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.type,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "type", $$v);
      },
      expression: "user.business.type"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "연락정보"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "연락처",
      "readonly": ""
    },
    model: {
      value: _vm.user.tel,
      callback: function ($$v) {
        _vm.$set(_vm.user, "tel", $$v);
      },
      expression: "user.tel"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "이메일",
      "readonly": !!_vm.user.meta.CustNo
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "우편번호",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "postcode", $$v);
      },
      expression: "user.business.postcode"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "기본주소",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.address1,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "address1", $$v);
      },
      expression: "user.business.address1"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상세주소",
      "readonly": ""
    },
    model: {
      value: _vm.user.business.address2,
      callback: function ($$v) {
        _vm.$set(_vm.user.business, "address2", $$v);
      },
      expression: "user.business.address2"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('view-section-card', _vm._b({
    attrs: {
      "title": "주문내역"
    }
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-data-table', _vm._b({
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value,
            item = _ref2.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.numbers`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('order-view', {
          attrs: {
            "_order": item._order
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var _item$order;
              var attrs = _ref4.attrs,
                on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "pa-0 caption",
                attrs: {
                  "x-small": "",
                  "text": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_vm._v(_vm._s((_item$order = item.order) === null || _item$order === void 0 ? void 0 : _item$order.orderNo))])];
            }
          }], null, true)
        })], 1), _c('div', [_vm._v(_vm._s(item.purchaseNo))])];
      }
    }, {
      key: `item.order.sender`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', [_vm._v(_vm._s(item.order.sender.name))]), _c('div', [_vm._v(_vm._s(item.order.sender.phone))]), _c('div', [_vm._v(_vm._s(item.order.sender.email))])];
      }
    }, {
      key: `item.order.receiver`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_vm._v(_vm._s(item.order.receiver.name))]), _c('div', [_vm._v(_vm._s(item.order.receiver.phone))]), _c('div', [_vm._v(_vm._s(item.order.receiver.email))])];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false))], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }