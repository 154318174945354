var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('v-tabs', {
    staticStyle: {
      "flex": "0 0 auto"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return [_c('v-tab', {
      key: value,
      attrs: {
        "tab-value": value
      }
    }, [_vm._v(_vm._s(text))])];
  })], 2), _c('v-divider'), _c('v-tabs-items', {
    staticStyle: {
      "flex": "1 0 auto"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.items, function (_ref2, tabindex) {
    var value = _ref2.value;
    return [_c('v-tab-item', _vm._b({
      key: value,
      staticClass: "fill-height"
    }, 'v-tab-item', {
      value,
      tabindex
    }, false), [value == 'brands' ? [_c('verify-brands', _vm._b({
      model: {
        value: _vm.parsed,
        callback: function ($$v) {
          _vm.parsed = $$v;
        },
        expression: "parsed"
      }
    }, 'verify-brands', {
      file: _vm.file
    }, false))] : _vm._e(), value == 'categories' ? [_c('verify-categories', _vm._b({
      model: {
        value: _vm.parsed,
        callback: function ($$v) {
          _vm.parsed = $$v;
        },
        expression: "parsed"
      }
    }, 'verify-categories', {
      file: _vm.file
    }, false))] : _vm._e(), value == 'options' ? [_c('verify-options', _vm._b({
      model: {
        value: _vm.parsed,
        callback: function ($$v) {
          _vm.parsed = $$v;
        },
        expression: "parsed"
      }
    }, 'verify-options', {
      file: _vm.file
    }, false))] : _vm._e(), value == 'products' ? [_c('verify-products', _vm._b({
      model: {
        value: _vm.parsed,
        callback: function ($$v) {
          _vm.parsed = $$v;
        },
        expression: "parsed"
      }
    }, 'verify-products', {
      file: _vm.file
    }, false))] : _vm._e()], 2)];
  })], 2), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.file,
      expression: "!file"
    }],
    staticClass: "black--text",
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-card-title', [_vm._v(" 파일을 먼저 등록해주세요 ")])], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.file && _vm.items.length == 0,
      expression: "file && items.length == 0"
    }],
    staticClass: "black--text",
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-card-title', [_vm._v(" 파일 양식이 잘못되었습니다 ")])], 1)], 1), _c('process-categories', _vm._b({
    on: {
      "input": _vm.emit,
      "loaded": function ($event) {
        _vm.loaded += 1;
      }
    },
    model: {
      value: _vm.parsed,
      callback: function ($$v) {
        _vm.parsed = $$v;
      },
      expression: "parsed"
    }
  }, 'process-categories', {
    file: _vm.file
  }, false)), _c('process-brands', _vm._b({
    on: {
      "input": _vm.emit,
      "loaded": function ($event) {
        _vm.loaded += 1;
      }
    },
    model: {
      value: _vm.parsed,
      callback: function ($$v) {
        _vm.parsed = $$v;
      },
      expression: "parsed"
    }
  }, 'process-brands', {
    file: _vm.file
  }, false)), _c('process-products', _vm._b({
    on: {
      "input": _vm.emit,
      "loaded": function ($event) {
        _vm.loaded += 1;
      }
    },
    model: {
      value: _vm.parsed,
      callback: function ($$v) {
        _vm.parsed = $$v;
      },
      expression: "parsed"
    }
  }, 'process-products', {
    file: _vm.file
  }, false)), _c('process-options', _vm._b({
    on: {
      "input": _vm.emit,
      "loaded": function ($event) {
        _vm.loaded += 1;
      }
    },
    model: {
      value: _vm.parsed,
      callback: function ($$v) {
        _vm.parsed = $$v;
      },
      expression: "parsed"
    }
  }, 'process-options', {
    file: _vm.file
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }