var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "회원등급"
    },
    scopedSlots: _vm._u([{
      key: "add-button",
      fn: function () {
        return [_c('user-level-form', {
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "my-n1",
                attrs: {
                  "small": "",
                  "fab": "",
                  "tile": "",
                  "color": "transparent primary--text"
                }
              }, 'v-btn', Object.assign({}, attrs, {
                loading: _vm.loading
              }), false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "items-per-page": -1,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('user-level-list-codes'), _c('v-divider')];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "disabled": _vm.disabled__drag
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: `item.criteria`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('item-criterion', {
          attrs: {
            "value": item.criteria[0]
          }
        })];
      }
    }, {
      key: `item.isActive`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (isActive) {
              return _vm.update({
                _id: item._id,
                isActive
              });
            }
          },
          model: {
            value: item.isActive,
            callback: function ($$v) {
              _vm.$set(item, "isActive", $$v);
            },
            expression: "item.isActive"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.isWelcome`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-switch', _vm._b({
          on: {
            "change": function (isWelcome) {
              return _vm.update({
                _id: item._id,
                isWelcome
              });
            }
          },
          model: {
            value: item.isWelcome,
            callback: function ($$v) {
              _vm.$set(item, "isWelcome", $$v);
            },
            expression: "item.isWelcome"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('user-level-form', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.updateItem
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }), item.removable ? _c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "tile": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-delete ")])], 1) : _vm._e()];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "my-2",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }