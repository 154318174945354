<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%">
            <list-heading title="회원 영업담당자 설정" />
            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <user-manager-upload v-model="loading" color="white green--text" class="caption" v-on="{ search }">
                            <v-layout align-center> <v-icon small class="mr-1">mdi-cloud-upload</v-icon> 파일 업로드 </v-layout>
                        </user-manager-upload>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <v-layout align-center> <v-icon small class="mr-1">mdi-cloud-download</v-icon> 파일 다운로드 </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.managers`]="{ item, value, header }">
                    <item-manager-edit :value="item" v-on="{ update }">
                        <v-card flat tile width="100%" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)"> {{ header.formatter(value).replaceAll("\r\n", " / ") }} </v-card>
                    </item-manager-edit>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, initDataTableHeaders, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ItemManagerEdit from "@/components/console/dumb/edit/item-manager-edit.vue";
import UserManagerUpload from "@/components/console/users/user-manager-upload.vue";

const headers = initDataTableHeaders([
    { text: "NO", value: "index", width: +90, formatter: (value, item, isExcel) => (isExcel ? value : value.format()) },
    { text: "유형", value: "type", formatter: (value) => USER_TYPES[value]?.text || "관리자", width: +90, hideOnExcel: true },
    { text: "쇼핑몰코드", value: "meta.CustNo", width: 100 },
    { text: "아이디", value: "username", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 160 },
    { text: "이름", value: "name", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 160 },
    { text: "담당자아이디", value: "managers", cellClass: "max-width-0 text-truncate pa-0 text-start", withTitle: true, align: "center", formatter: (value, item, isExcel) => (isExcel ? value.map(({ username }) => username) : value.map(({ username }) => username).join(", ")), splitOnExcel: true },
    { text: "가입일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 160, hideOnExcel: true },
]);

export default {
    components: {
        ListHeading,
        ItemManagerEdit,
        UserManagerUpload,
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        headers,
        attrs_switch,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            query.isApproved = true;
            query.scope = { $nin: ["manager", "console"] };
            query["meta.CustNo"] = { $ne: null };

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.gets({ headers: { skip, limit }, params });

                this.users = users;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            if (this.loading) return;
            else this.loading;

            try {
                user = (await api.console.users.put(user))?.user;
                this.loading = false;
                await this.updateItem(user);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
            await this.search();
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { users } = await api.console.users.gets({
                    params,
                    // headers: { skip: 0, limit: 100 },
                });

                this.$excel(users, headers, "회원_영업담당_목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
