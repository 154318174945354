var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-textarea', _vm._g(_vm._b({
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.text,
      callback: function ($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }, 'v-textarea', Object.assign({}, _vm.$attrs, {
    rows: _vm.rows,
    autoGrow: _vm.autoGrow
  }), false), {
    input: _vm.input
  }));

}
var staticRenderFns = []

export { render, staticRenderFns }