<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="주문 목록" v-model="showsSearch" showsFilterButton />

            <order-list-search v-bind="{ loading, showsSearch }" />

            <v-row class="mx-n2 mt-n3">
                <v-col cols="auto" class="pa-2">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-col cols="auto" class="pa-2">
                    <order-list-states />
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="pa-2">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <v-layout align-center> <v-icon small class="mr-1">mdi-microsoft-excel</v-icon> 엑셀다운로드 </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.orderNo`]="{ item, value, header }">
                    <order-view :_order="item._id" v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile width="100%" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value)?.replaceAll?.("\r\n", " / ") }} </v-card>
                        </template>
                    </order-view>
                </template>
                <template #[`item.purchases`]="{ item, value, header }">
                    <order-view :_order="item._id" v-on="{ search }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile width="100%" color="transparent" class="px-4 py-3 caption text-truncate" :title="header.formatter(value)" v-bind="attrs" v-on="on"> {{ header.formatter(value)?.replaceAll?.("\r\n", " / ") }} </v-card>
                        </template>
                    </order-view>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn-toggle borderless tile class="justify-space-between" style="width: 100%">
                        <list-order-cancel-btn plain text class="caption" style="flex-grow: 1" v-bind="{ loading, selected: item.purchases, disabled: !item.cancelable, confirms: item.confirms__cancel }" v-on="{ search, loading: (value) => (loading = value) }" />
                        <v-divider vertical />
                        <list-order-confirm-btn plain text class="caption" style="flex-grow: 1" v-bind="{ loading, selected: item.confirmables, disabled: !item.confirmable }" v-on="{ search, loading: (value) => (loading = value) }" title="주문완료" />
                        <v-divider vertical />
                        <order-list-update plain text class="caption" style="flex-grow: 1" v-bind="{ loading, selected: [item], disabled: !item.updatable }" v-on="{ search, loading: (value) => (loading = value) }" />
                        <template v-if="payload?._user == '64faeae38b7a9722e49904cc'">
                            <v-divider vertical />
                            <v-btn plain text class="caption" style="flex-grow: 1" v-bind="{ loading }" @click="test(item, 'onOrderPurchased')"> 접수메일 </v-btn>
                            <v-divider vertical />
                            <v-btn plain text class="caption" style="flex-grow: 1" v-bind="{ loading }" @click="test(item, 'onOrderCancelled')"> 취소메일 </v-btn>
                        </template>
                    </v-btn-toggle>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { initDataTableHeaders, ORDER_STATES, ORDER_STATUSES, PAYMENT_METHODS, PURCHASE_ORDER_STATUSES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";
import OrderListSearch from "@/components/console/shop/orders/list/order-list-search.vue";
import OrderListStates from "@/components/console/shop/orders/list/order-list-states.vue";
import OrderListUpdate from "@/components/console/shop/orders/list/order-list-update.vue";

import ListOrderCancelBtn from "@/components/console/shop/purchases/list-actions/list-order-cancel-btn.vue";
import ListOrderConfirmBtn from "@/components/console/shop/purchases/list-actions/list-order-confirm-btn.vue";

const formatPurchase = (purchases) => {
    let text = purchases[0]?.product?.name;
    if (1 < purchases.length) text += ` 외 ${purchases.length - 1}개`;
    return text;
};

const formatReceiver = (receiver) => {
    const { postcode, address1, address2, phone, email } = receiver || {};

    let address = "";
    if (postcode) address += ` [${postcode?.trim?.() || postcode}]`;
    if (address1) address += ` ${address1?.trim?.() || address1}`;
    if (address2) address += ` ${address2?.trim?.() || address2}`;
    address = address.trim();

    let contact = [`${phone?.phoneNumberFormat?.() || ""}`, email].filter((item) => item).join(" / ");

    return [address, contact].filter((item) => item).join("\r\n");
};

export default {
    components: {
        ListHeading,

        OrderView,
        OrderListSearch,
        OrderListStates,
        OrderListUpdate,

        ListOrderCancelBtn,
        ListOrderConfirmBtn,
    },
    data: () => ({
        orders: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,
    }),
    computed: {
        ...mapState(["scope", "payload"]),
        isConsole() {
            return this.scope?.includes?.("console");
        },
        headers() {
            const headers = initDataTableHeaders([
                { text: "주문번호", value: "orderNo", width: 180, cellClass: "pa-0" },
                { text: "주문제품", value: "purchases", cellClass: "pa-0 max-width-0 text-truncate", withTitle: true, formatter: formatPurchase },
                { text: "구매회원", value: "sender", width: 160, cellClass: "max-width-0 text-truncate", withTitle: true, formatter: ({ username, name }) => `${name} (${username})` },
                { text: "배송유형", value: "receiver.title", width: 100, cellClass: "max-width-0 text-truncate", withTitle: true },
                { text: "배송상세", value: "receiver", cellClass: "max-width-0 text-truncate", withTitle: true, formatter: formatReceiver },
                { text: "구매금액", value: "totalPrice", width: 120, align: "end", formatter: (value, { moneyPrice, pointPrice }) => (value + moneyPrice + pointPrice)?.format?.() || "-" },
                // { text: "취소금액", value: "cancelTotalPrice", width: 120, align: "end", formatter: (value) => value?.format?.() || "-" },
                { text: "결제방법", value: "paymentMethod", width: 100, align: "center", formatter: (value) => PAYMENT_METHODS[value]?.text || "-" },
                { text: "주문상태", value: "state", width: +90, align: "center", formatter: (value) => ORDER_STATES[value || ORDER_STATES.PURCHASED.value]?.text || "-" },
                { text: "주문일시", value: "createdAt", width: 100, cellClass: "py-0", formatter: (value) => value?.toDateTime?.() || value || "-" },
                { text: "주문처리", value: "actions", cellClass: "pa-0", align: "center", width: 230, hideOnExcel: true },
            ]);

            if (!this.isConsole) headers.pop();

            return headers;
        },
        items() {
            return this.orders.map((item) => ({
                ...item,
                updatable: [ORDER_STATES.CONFIRMED.value, ORDER_STATES.DISPATCHD.value].includes(item.state) || item?._purchases?.length == 0,
                cancelable: item.state != ORDER_STATES.DELIVERED.value,
                confirmable: item.state == ORDER_STATES.PURCHASED.value,
                confirmables: item.purchases.filter(({ orderStatus }) => orderStatus != PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value),
                confirms__cancel: () => confirm(`선택하신 주문을 취소하시겠습니까?\r\n\r\n주문번호: ${item.orderNo}\r\n주문제품: ${formatPurchase(item.purchases)}\r\n구매회원: ${item.sender?.name} {${item.sender?.username}}\r\n\r\n취소된 주문은 복구할 수 없으며, ERP에서의 취소 처리는 벌도로 진행하셔야 합니다.`),
            }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;
            query.status = { $ne: ORDER_STATUSES.PURCHASE_INITIATED.value };
            if (query.searchDateValue) query.searchDateKey = "createdAt";

            if (!this.isConsole) {
                if (!query._managers) query._managers = this.payload?._user;
            }

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            this.users = [];
            this.summary = { totalCount: 0 };
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, orders } = await api.console.shop.orders.gets({ headers: { skip, limit }, params });

                this.orders = orders;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(item) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { order } = await api.console.shop.orders.put(item);
                this.loading = false;
                this.updateItem(order);
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.orders.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.orders.splice(index, 1, item);
            else {
                this.orders = [item, ...this.orders];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { orders } = await api.console.shop.orders.gets({
                    params,
                });

                this.$excel(orders, this.headers, "주문목록");
            } finally {
                this.loading = false;
            }
        },

        async test({ _id }, mode) {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.console.shop.orders.postTest({ _id, mode });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
