var render = function render(){
  var _vm$purchaseDashboard, _vm$purchaseDashboard2, _vm$purchaseDashboard3, _vm$purchaseDashboard4, _vm$purchaseDashboard5, _vm$purchaseDashboard6, _vm$purchaseDashboard7, _vm$purchaseDashboard8, _vm$purchaseDashboard9, _vm$purchaseDashboard10, _vm$purchaseDashboard11, _vm$purchaseDashboard12, _vm$editItem$product$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "ma-n1"
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('v-row', {
    staticClass: "ma-n1"
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "caption font-weight-bold d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "teal lighten-3"
    }
  }, [_vm._v("mdi-gift")]), _vm._v("신규주문 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("주문접수")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/orders?state=PURCHASED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard = _vm.purchaseDashboard) === null || _vm$purchaseDashboard === void 0 ? void 0 : (_vm$purchaseDashboard2 = _vm$purchaseDashboard.orderPurchased) === null || _vm$purchaseDashboard2 === void 0 ? void 0 : _vm$purchaseDashboard2.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("주문완료")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/orders?state=CONFIRMED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard3 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard3 === void 0 ? void 0 : (_vm$purchaseDashboard4 = _vm$purchaseDashboard3.orderConfirmed) === null || _vm$purchaseDashboard4 === void 0 ? void 0 : _vm$purchaseDashboard4.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발송완료")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "teal--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/orders?state=DISPATCHD');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard5 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard5 === void 0 ? void 0 : (_vm$purchaseDashboard6 = _vm$purchaseDashboard5.orderDispatchd) === null || _vm$purchaseDashboard6 === void 0 ? void 0 : _vm$purchaseDashboard6.format()))]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "caption font-weight-bold d-flex align-center"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "purple lighten-3"
    }
  }, [_vm._v("mdi-truck")]), _vm._v("배송관리 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "height": "64"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("배송중")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "purple--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/orders?state=DISPATCHD');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard7 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard7 === void 0 ? void 0 : (_vm$purchaseDashboard8 = _vm$purchaseDashboard7.orderDispatchd) === null || _vm$purchaseDashboard8 === void 0 ? void 0 : _vm$purchaseDashboard8.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("배송완료")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "purple--text text--lighten-3 font-weight-bold mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/orders?state=DELIVERED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard9 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard9 === void 0 ? void 0 : (_vm$purchaseDashboard10 = _vm$purchaseDashboard9.orderDelivered) === null || _vm$purchaseDashboard10 === void 0 ? void 0 : _vm$purchaseDashboard10.format()))]), _vm._v(" 건 ")], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("취소요청")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "blue-grey--text font-weight-bold text--lighten-1 mr-1 pa-0",
    attrs: {
      "min-width": "auto",
      "height": "auto",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/console/shop/purchases/cancel?claimStatus=CANCEL_REQUESTED');
      }
    }
  }, [_vm._v(_vm._s((_vm$purchaseDashboard11 = _vm.purchaseDashboard) === null || _vm$purchaseDashboard11 === void 0 ? void 0 : (_vm$purchaseDashboard12 = _vm$purchaseDashboard11.cancelRequest) === null || _vm$purchaseDashboard12 === void 0 ? void 0 : _vm$purchaseDashboard12.format()))]), _vm._v(" 건 ")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined fill-height",
    attrs: {
      "headers": _vm.notificationsHeaders,
      "items": _vm.notifications,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `header.notification`,
      fn: function () {
        return [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "amber lighten-2"
          }
        }, [_vm._v(" mdi-bell-ring ")]), _vm._v("공지사항 ")], 1), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-dialog', {
          attrs: {
            "max-width": "500px"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "x-small": "",
                  "dark": "",
                  "color": "accent"
                },
                on: {
                  "click": _vm.createNotification
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                staticClass: "mr-1",
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-plus")]), _vm._v(" 글쓰기 ")], 1)];
            }
          }]),
          model: {
            value: _vm.notificationDialog,
            callback: function ($$v) {
              _vm.notificationDialog = $$v;
            },
            expression: "notificationDialog"
          }
        }, [_vm.notificationDialog ? _c('v-card', [_c('v-card-title', [!_vm.editItem._id ? _c('span', {
          staticClass: "caption font-weight-bold"
        }, [_vm._v("공지사항 등록")]) : _c('span', {
          staticClass: "caption font-weight-bold"
        }, [_vm._v("공지사항 수정")])]), _c('v-card-text', [_c('v-text-field', {
          attrs: {
            "label": "제목",
            "persistent-placeholder": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.subject,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "subject", $$v);
            },
            expression: "editItem.subject"
          }
        }), _c('v-textarea', {
          staticClass: "mt-4",
          attrs: {
            "label": "내용",
            "persistent-placeholder": "",
            "hide-details": ""
          },
          model: {
            value: _vm.editItem.content,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "content", $$v);
            },
            expression: "editItem.content"
          }
        })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.closeNotification
          }
        }, [_vm._v("취소")]), _c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.saveNotification
          }
        }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: `item.notification`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-row', {
          on: {
            "click": function ($event) {
              return _vm.$set(item, 'show', !item.show);
            }
          }
        }, [_c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(_vm._s(item.subject))]), _c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [!item.show ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1), _c('v-expand-transition', [_c('v-row', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: item.show,
            expression: "item.show"
          }]
        }, [_c('v-col', [_c('v-textarea', {
          staticClass: "text-body-1",
          attrs: {
            "readonly": "",
            "text": "",
            "hide-details": ""
          },
          model: {
            value: item.content,
            callback: function ($$v) {
              _vm.$set(item, "content", $$v);
            },
            expression: "item.content"
          }
        })], 1)], 1)], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editNotification(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.removeNotification(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  })], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined",
    attrs: {
      "headers": _vm.questionsHeaders,
      "items": _vm.questions,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `header.subject`,
      fn: function () {
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "pink lighten-3"
          }
        }, [_vm._v(" mdi-comment-question ")]), _vm._v("1:1문의 ")];
      },
      proxy: true
    }, {
      key: `item.product`,
      fn: function (_ref5) {
        var _item$product$thumb;
        var item = _ref5.item;
        return [item.product ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [item.product.thumb ? _c('v-img', {
          attrs: {
            "src": (_item$product$thumb = item.product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v(_vm._s(item.product.name))])])], 1) : _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "md": "auto"
          }
        }, [_c('v-responsive', {
          attrs: {
            "width": "56",
            "height": "56"
          }
        }, [_c('v-row', {
          staticClass: "fill-height ma-0 grey lighten-3",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('div', {
          staticClass: "text-start"
        }, [_vm._v("삭제된 제품")])])], 1)];
      }
    }, {
      key: `item.user`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.user ? _c('v-row', [_c('v-col', [_vm._v(_vm._s(item.user.name) + "(" + _vm._s(item.user.username) + ")")])], 1) : _c('v-row', [_c('v-col', [_vm._v("탈퇴회원")])], 1)];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.createdAt.toDate()) + " ")];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editQuestion(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.questionDialog,
      callback: function ($$v) {
        _vm.questionDialog = $$v;
      },
      expression: "questionDialog"
    }
  }, [_vm.questionDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("제품문의")])]), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "작성자",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.user ? `${_vm.editItem.user.name}(${_vm.editItem.user.username})` : '탈퇴회원'
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "답변",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.reply,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "reply", $$v);
      },
      expression: "editItem.reply"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeQuestion
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.saveQuestion
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined fill-height",
    attrs: {
      "headers": _vm.inquiresHeaders,
      "items": _vm.inquires,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `header.subject`,
      fn: function () {
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "red lighten-2"
          }
        }, [_vm._v("mdi-help-circle")]), _vm._v("제품문의 ")];
      },
      proxy: true
    }, {
      key: `item.createdAt`,
      fn: function (_ref10) {
        var _item$createdAt, _item$createdAt$toDat;
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$createdAt = item.createdAt) === null || _item$createdAt === void 0 ? void 0 : (_item$createdAt$toDat = _item$createdAt.toDate) === null || _item$createdAt$toDat === void 0 ? void 0 : _item$createdAt$toDat.call(_item$createdAt)) + " ")];
      }
    }, {
      key: `item.reply`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', [_vm._v("미답변")])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.editInquire(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, true)
  }), _c('v-dialog', {
    attrs: {
      "max-width": "720px"
    },
    model: {
      value: _vm.inquireDialog,
      callback: function ($$v) {
        _vm.inquireDialog = $$v;
      },
      expression: "inquireDialog"
    }
  }, [_vm.inquireDialog ? _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("제품문의")])]), _c('v-card-text', [_vm.editItem.product ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "md": "auto"
    }
  }, [_vm.editItem.product.thumb ? _c('v-img', {
    attrs: {
      "src": (_vm$editItem$product$ = _vm.editItem.product.thumb) === null || _vm$editItem$product$ === void 0 ? void 0 : _vm$editItem$product$.url,
      "max-width": "56",
      "max-height": "56"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "56",
      "height": "56"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1"
  }, [_c('div', {
    staticClass: "text-start"
  }, [_vm._v(_vm._s(_vm.editItem.product.name))])])], 1) : _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "56",
      "height": "56"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1"
  }, [_c('div', {
    staticClass: "text-start"
  }, [_vm._v("삭제된 제품")])])], 1), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 제목",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.subject,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "subject", $$v);
      },
      expression: "editItem.subject"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의날짜",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.createdAt.toDate()
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "작성자",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.editItem.user ? `${_vm.editItem.user.name}(${_vm.editItem.user.username})` : '탈퇴회원'
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "문의 내용",
      "persistent-placeholder": "",
      "readonly": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "content", $$v);
      },
      expression: "editItem.content"
    }
  }), _c('div', {
    staticClass: "mt-2",
    staticStyle: {
      "font-size": "0.85em"
    }
  }, [_vm._v("답변")]), _c('naver-smarteditor', {
    model: {
      value: _vm.editItem.reply,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "reply", $$v);
      },
      expression: "editItem.reply"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeInquire
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.saveInquire
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('v-data-table', {
    staticClass: "v-sheet--outlined fill-height",
    attrs: {
      "items": _vm.productsSales,
      "headers": _vm.productsSalesHeaders,
      "disable-sort": "",
      "disable-filtering": "",
      "hide-default-footer": "",
      "items-per-page": 6
    },
    scopedSlots: _vm._u([{
      key: `header.product`,
      fn: function () {
        return [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": "",
            "color": "green lighten-2"
          }
        }, [_vm._v(" mdi-chart-box-outline ")]), _vm._v("제품별매출 ")];
      },
      proxy: true
    }, {
      key: `item.product`,
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('item-product', {
          attrs: {
            "product": item.product,
            "isLink": ""
          }
        })];
      }
    }, {
      key: `item.purchasePrice`,
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.purchasePrice.format()) + " ")];
      }
    }, {
      key: `item.purchaseAmount`,
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(item.purchaseAmount.format()) + " ")];
      }
    }], null, true)
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('v-row', {
    staticClass: "ma-n1"
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "indigo lighten-1"
    }
  }, [_vm._v("mdi-chart-line")]), _vm._v("월별매출통계 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "202"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.monthlySalesOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v("일별결제금액 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "202"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesPriceOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v("일별결제건수 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "202"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesAmountOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "blue lighten-2"
    }
  }, [_vm._v(" mdi-chart-bar ")]), _vm._v("일별결제자수 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "202"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dailySalesUserOption
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "caption font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": "",
      "color": "cyan"
    }
  }, [_vm._v("mdi-chart-timeline-variant")]), _vm._v("요일별매출통계 ")], 1), _c('v-card-text', [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "height": "202"
    }
  }, [_c('v-chart', {
    attrs: {
      "options": _vm.dayOfWeekSalesOption
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }