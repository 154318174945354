<template>
    <v-btn-toggle v-model="value" color="primary">
        <v-btn :value="null" color="white" height="38" class="caption">전체</v-btn>
        <v-btn v-for="{ text, value } in states" v-bind="{ value }" :key="value" color="white" class="caption" height="38"> {{ text }} </v-btn>
    </v-btn-toggle>
</template>

<script>
import { ORDER_STATES } from "@/assets/variables";

const states = Object.values(ORDER_STATES);
export default {
    data: () => ({
        value: null,
        states,
    }),
    computed: {
        state() {
            return this.$route.query.state || null;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        state() {
            this.sync();
        },
        value() {
            this.emit();
        },
    },
    methods: {
        sync() {
            this.value = this.state;
        },
        emit() {
            const { ...query } = this.$route.query;

            delete query.page;

            if (this.value) query.state = this.value;
            else delete query.state;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
