var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref2) {
        var _item$product, _item$product$thumb;
        var item = _ref2.item;
        return [_c('v-row', {
          staticClass: "pa-3 pa-sm-0",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0 pa-sm-3 purchase-item-image",
          attrs: {
            "cols": "12",
            "sm": "auto"
          }
        }, [_c('image-popup', {
          attrs: {
            "src": item === null || item === void 0 ? void 0 : (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
            "size": "40",
            "rounded": "lg",
            "attrs_avatar": {
              rounded: true
            }
          }
        })], 1), _c('v-col', {
          staticClass: "pa-0 pa-sm-3"
        }, [_c('div', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(item.purchaseNo))]), _c('div', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")])])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    itemClass: _vm.itemClass
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }