<template>
    <v-layout justify-center>
        <v-responsive max-width="640" width="100%">
            <list-heading title="브랜드 목록">
                <template #add-button>
                    <brand-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </brand-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.label`]="{ value }">
                    <image-popup :src="value?.url" :attrs_avatar="{ size: 48, rounded: true }" />
                </template>
                <template #[`item.thumb`]="{ value }">
                    <image-popup :src="value?.url" :attrs_avatar="{ size: 48, rounded: true }" />
                </template>
                <template #[`item.image`]="{ value }">
                    <image-popup :src="value?.url" :attrs_avatar="{ size: 48, rounded: true }" />
                </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <brand-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </brand-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, initDataTableHeaders } from "@/assets/variables";

import BrandForm from "@/components/console/shop/brands/brand-form.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

const headers = initDataTableHeaders([
    { text: "이름", value: "name" },
    { text: "코드", value: "code" },
    { width: +56, text: "라벨", value: "label", align: "center", cellClass: "pa-1" },
    // { width: +56, text: "목록", value: "thumb", align: "center", cellClass: "pa-1" },
    // { width: +56, text: "상세", value: "image", align: "center", cellClass: "pa-1" },
    // { width: +80, text: "표시", value: "shows", align: "center" },
    { width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +88, text: "", value: "actions", align: "center" },
]);

export default {
    components: {
        BrandForm,
        ImagePopup,
        ListHeading,
    },
    data: () => ({
        brands: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch,
        loading: false,
    }),
    computed: {
        items() {
            return [...this.brands];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, brands } = await api.console.shop.brands.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.brands = brands.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(brand) {
            try {
                brand = (await api.console.shop.brands.put(brand))?.brand;
                this.updateItem(brand);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.search();
            }
        },

        async remove(brand) {
            const go = confirm(`"${brand?.name}" 브랜드를 삭제하시겠습니까?`);
            if (go) {
                try {
                    brand = (await api.console.shop.brands.delete(brand))?.brand;
                    this.brands = this.brands.filter(({ _id }) => _id !== brand._id);
                    alert(`"${brand?.name}" 브랜드가 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                } finally {
                    this.search();
                }
            }
        },

        updateItem(brand) {
            const index = this.brands.findIndex(({ _id }) => _id == brand._id);
            if (0 <= index) this.brands.splice(index, 1, brand);
            else {
                this.brands = [brand, ...this.brands];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>
