<template>
    <v-dialog v-model="shows" max-width="720">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card v-bind="{ loading }">
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="shows = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-6">
                <v-row class="ma-n2">
                    <v-col cols="3" class="pa-2">
                        <view-field-card label="썸네일" :attrs_wrapper="{ class: 'pa-0' }">
                            <image-input v-model="form.thumb" accept="image/*" :aspect-ratio="1 / 1" contain flat />
                        </view-field-card>
                    </v-col>
                    <v-col cols="9" class="pa-2">
                        <v-row class="ma-n2">
                            <template v-if="code == 'catalogue'">
                                <v-col cols="12" class="pa-2">
                                    <shop-category-select label="카테고리" v-model="form._category" v-bind="{ ...attrs_input, categories }" />
                                </v-col>
                            </template>
                            <v-col cols="12" class="pa-2">
                                <v-text-field label="제목" v-model="form.name" v-bind="attrs_input" />
                            </v-col>
                            <v-col cols="12" class="pa-2">
                                <v-file-input label="파일" v-model="form.file" v-bind="attrs_input" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" v-bind="{ loading }" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" v-bind="{ loading }" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input, initCatalogue } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";
import ViewFieldCard from "@/components/console/dumb/view-field-card.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";

export default {
    components: {
        ImageInput,
        ViewFieldCard,
        ShopCategorySelect,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        code: { type: String, default: "catalogue" },
    },
    data: () => ({
        form: initCatalogue(),

        attrs_input,

        shows: false,
        loading: false,
    }),
    computed: {
        ...mapState(["categories"]),
        isCreate() {
            return !this.value?._id;
        },
        title() {
            let title = "";
            switch (this.code) {
                case "software": {
                    title = "소프트웨어";
                    break;
                }
                case "catalogue":
                default: {
                    title = "카탈로그";
                    break;
                }
            }
            if (this.isCreate) title += " 생성";
            else title += " 상세";
            return title;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        async init() {
            const { code } = this;
            this.form = initCatalogue({ code });

            if (!this.isCreate) {
                const { catalogue } = await api.console.shop.catalogues.get({ _id: this.value?._id });
                this.form = initCatalogue(catalogue);
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { post, put } = api.console.shop.catalogues;

                let { _file, file, _thumb, thumb, ...form } = this.form;

                let { catalogue } = this.isCreate ? await post(form) : await put(form);

                if (file instanceof File) {
                    file = (await api.console.files.post({ isMembersOnly: catalogue.code == "software", _catalogue: catalogue?._id, index: "file" }, file))?.file;
                }
                _file = file?._id || null;
                if (!!this.form._file && _file != this.form._file) await api.console.files.delete({ _id: this.form._file });

                if (thumb instanceof File) {
                    thumb = (await api.console.files.post({ _catalogue: catalogue?._id, index: "thumb" }, thumb))?.file;
                }
                _thumb = thumb?._id || null;
                if (!!this.form._thumb && _thumb != this.form._thumb) await api.console.files.delete({ _id: this.form._thumb });

                catalogue = (await put({ _id: catalogue?._id, _file, _thumb }))?.catalogue;

                this.$emit("input", catalogue);
                alert("저장되었습니다");
                this.shows = false;
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style></style>
