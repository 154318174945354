<template>
    <v-edit-dialog :return-value.sync="form.query" cancel-text="취소" save-text="변경" v-on="{ save }" large eager persistent>
        <slot />
        <template #input>
            <v-autogrow-field v-model="form.query" v-bind="{ ...attrs_input }" class="mt-3" />
            <v-fade-transition>
                <v-overlay v-show="loading" absolute light color="white">
                    <v-progress-circular indeterminate size="100" width="5" color="primary" />
                </v-overlay>
            </v-fade-transition>
        </template>
    </v-edit-dialog>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import VAutogrowField from "@/components/plugins/vuetify/v-autogrow-field.vue";

export default {
    components: {
        VAutogrowField,
    },
    props: {
        value: { state: Object, default: () => ({}) },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: {},
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, query } = this.form;
            let form = { _id, query };

            this.$emit("update", form);
        },
    },
};
</script>

<style></style>
