<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <list-heading v-bind="{ title }" />

            <v-row class="ma-n2">
                <v-col cols="12" class="pa-2">
                    <view-section-card title="기본정보">
                        <v-card-text>
                            <v-row class="ma-n2">
                                <v-col cols="6" class="pa-2">
                                    <v-text-field :value="(board.writer || {}).name" label="작성자" v-bind="attrs_input" readonly disabled></v-text-field>
                                </v-col>
                                <v-col cols="6" class="pa-2">
                                    <v-datetime-field :value="board.createdAt ? board.createdAt.toDateTime() : undefined" label="작성일" v-bind="attrs_input" @input="(value) => (board.createdAt = value)" />
                                </v-col>
                                <v-col cols="6" class="pa-2" v-if="categories.length">
                                    <v-select v-model="board.category" :items="categories" label="카테고리" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="6" class="pa-2">
                                    <v-text-field v-model="board.subject" label="제목" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="6" class="pa-2" v-if="[].includes(board.code)">
                                    <v-text-field v-model="board.meta.href" label="링크주소" placeholder="https://youtube.com" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="6" class="pa-2" v-if="[].includes($route.query.code || board.code)">
                                    <v-text-field v-model="board.period" label="기간" placeholder="ex) 2020-01-01 ~ 2020.12.31" v-bind="attrs_input" />
                                </v-col>
                                <v-col cols="12" class="pa-2" v-if="[].includes(board.code)">
                                    <v-text-field v-model="board.summary" label="한줄설명" v-bind="attrs_input" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <view-section-card title="썸네일" v-if="['bright-academy', 'jfl-guides'].includes(board.code)">
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0">
                                    <v-img v-if="board.thumb" max-width="96" max-height="96" :src="createObjectURL(board.thumb)" contain></v-img>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="board.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="['youtube'].includes(board.code)">
                    <v-row class="ma-n2">
                        <v-col class="pa-2">
                            <view-section-card title="유튜브">
                                <v-card>
                                    <v-card-text>
                                        <v-text-field v-model="board.meta.youtube" label="링크주소" placeholder="https://youtube.com/" v-bind="attrs_input" />
                                    </v-card-text>
                                    <v-divider />
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn color="secondary" @click="setVideoId">미리보기</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </view-section-card>
                        </v-col>
                        <v-col cols="4" class="pa-2">
                            <v-card height="182">
                                <youtube :video-id="videoId" fitParent style="height: 182px" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="['bright-academy'].includes(board.code)">
                    <v-row class="ma-n2">
                        <v-col class="pa-2" cols="12" lg="6">
                            <view-section-card title="이미지" class="fill-height" :disabled="!!board.files[0] || !!board.meta.youtube">
                                <v-card-text>
                                    <v-file-input v-model="board.image" show-size v-bind="attrs_input" />
                                </v-card-text>
                            </view-section-card>
                        </v-col>
                        <v-col class="pa-2" cols="12" lg="6">
                            <view-section-card title="동영상" class="fill-height" :disabled="!!board.image || !!board.meta.youtube">
                                <v-card-text>
                                    <v-file-input v-model="board.files[0]" show-size v-bind="attrs_input" />
                                </v-card-text>
                            </view-section-card>
                        </v-col>
                        <v-col class="pa-2" cols="12" lg="6">
                            <view-section-card title="유튜브" :disabled="!!board.image || !!board.files[0]">
                                <v-card-text>
                                    <v-text-field v-model="board.meta.youtube" label="링크주소" placeholder="https://youtube.com/" clearable v-bind="attrs_input" />
                                </v-card-text>
                                <v-divider />
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="secondary" @click="setVideoId">미리보기</v-btn>
                                </v-card-actions>
                            </view-section-card>
                        </v-col>
                        <v-col class="pa-2" cols="12" lg="6">
                            <v-card height="182">
                                <youtube :video-id="videoId" fitParent style="height: 182px" />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="[].includes(board.code)">
                    <view-section-card title="파일">
                        <v-card-text>
                            <v-chip-group>
                                <v-chip v-for="file in board?.files" :key="file?.name" label outlined close :download="file.name" :href="createObjectURL(file)" @click:close="board.files = board.files.filter((item) => item != file)">
                                    {{ file?.name }}
                                </v-chip>
                                <v-chip v-if="(board?.files?.length || 0) < 2" label tile @click="$refs['file-input']?.$el.getElementsByTagName('input')?.[0]?.click?.()">
                                    <v-icon>mdi-plus</v-icon>
                                </v-chip>
                            </v-chip-group>
                            <v-file-input v-model="file" v-show="false" ref="file-input" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="[].includes(board.code)">
                    <view-section-card title="파일">
                        <v-card-text>
                            <v-file-input v-model="board.files[0]" show-size />
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="!['bright-academy'].includes(board.code)">
                    <view-section-card title="내용">
                        <v-card-text v-if="['jfl-guides'].includes(board.code)">
                            <naver-smarteditor v-model="board.content" id="content" rows="10" />
                        </v-card-text>
                        <v-card-text v-else>
                            <v-textarea v-model="board.content" id="content" rows="10" outlined hide-details />
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" class="pa-2" v-if="[].includes(board.code)">
                    <view-section-card title="답변">
                        <v-card-text>
                            <naver-smarteditor v-model="board.reply" id="reply" rows="10" />
                        </v-card-text>
                    </view-section-card>
                </v-col>
            </v-row>

            <v-btn fixed right bottom large fab color="primary" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import urlParser from "js-video-url-parser";
import { attrs_input, BOARD_CATEGORIES__JFL_GUIDES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        ListHeading,
        VDatetimeField,
        ViewSectionCard,
        NaverSmarteditor,
    },
    data() {
        return {
            attrs_input,

            ready: false,
            loading: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,
                category: this.$route.query.category,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                meta: {
                    buyEnabled: false,
                    buyURL: null,
                    youtube: null,
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: undefined,
                image: undefined,
                files: [],
            },

            file: null,

            videoId: null,
        };
    },
    computed: {
        ...mapGetters(["academyCategories"]),
        title() {
            return !this.$route.params._board ? "게시글 등록" : "게시글 상세";
        },
        categories() {
            switch (this.board.code) {
                case "bright-academy": {
                    return this.academyCategories || [];
                }
                case "jfl-guides": {
                    return Object.values(BOARD_CATEGORIES__JFL_GUIDES);
                }
                default: {
                    return [];
                }
            }
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        file(file) {
            if (file) {
                this.board.files.push(file);
                this.file = null;
            }
        },
    },
    methods: {
        async init() {
            try {
                // if(this.$route.query._clone){
                //     this.board._id = undefined;

                //     if(this.board.thumb){
                //         this.thumb = await this.getResource(this.board.thumb);
                //         this.board.thumb = undefined;
                //     }
                //     for(var i in this.board.images){
                //         if(this.board.images[i]){
                //             this.images[i] = await this.getResource(this.board.images[i]);
                //             this.board.images[i] = undefined;
                //         }
                //     }
                // }

                if (this.$route.params._board) {
                    var { board } = await api.console.boards.get({ _id: this.$route.params._board });
                    board.files = await Promise.all((board.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                    if (this.board.code == "youtube") this.setVideoId();
                }

                if (!this.$route.params._board === undefined) {
                    var { summary } = await api.console.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, image, ...board } = this.board;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.console.boards.files.delete({ _board: board?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.console.boards.put(board) : await api.console.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.console.boards.postThumb(board, thumb);
                else await api.console.boards.deleteThumb(board);

                if (image) await api.console.boards.postThumb(board, thumb);
                else await api.console.boards.deleteImage(board);

                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.console.boards.files.post({ _board: board?._id, index }, file)));

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        setVideoId() {
            this.videoId = urlParser.parse(this.board?.meta?.youtube)?.id || "";
        },
    },
};
</script>
