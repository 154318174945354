<template>
    <view-section-card title="할인사용" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-switch v-model="form.discountEnabled" :value="true" v-bind="attrs_switch" @change="emit" />
        </template>
        <v-expand-transition>
            <v-sheet v-show="form.discountEnabled">
                <v-divider />
                <v-card-text>
                    <v-row class="ma-n2">
                        <v-col cols="6" class="pa-2">
                            <v-select
                                v-model="form.discount.unit"
                                label="입력유형"
                                v-bind="{
                                    ...attrs_input,

                                    items: units,
                                    hint: unitHint,
                                }"
                                persistent-hint
                                class="mb-n1"
                                @input="emit"
                            >
                                <template #message="{ message }">
                                    <span class="text-caption"> ※ {{ message }} </span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="6" class="pa-2">
                            <v-currency-field
                                v-model="form.discount.amount"
                                label="할인입력"
                                v-bind="{
                                    ...attrs_input,

                                    suffix: amountSuffix,
                                    hint: ' ',
                                }"
                                counter
                                persistent-hint
                                @input="emit"
                            >
                                <template #message>
                                    <span class="text-caption"> 할인판매가: </span>
                                </template>
                                <template #counter>
                                    <span class="text-caption"> {{ discountPrice.format() }} 원 </span>
                                </template>
                            </v-currency-field>
                        </v-col>
                        <!-- <v-col cols="12" class="pa-2">
                            <v-checkbox v-model="form.discount.expiration.enabled" hide-details dense class="ma-0 pa-0" @change="emit">
                                <template #label>
                                    <span class="text-caption"> 특정기간만할인 </span>
                                </template>
                            </v-checkbox>
                        </v-col>
                        <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="form.discount.expiration.enabled">
                                <v-row class="ma-0">
                                    <v-col cols="12" sm="6" class="pa-2">
                                        <v-datetime-field v-model="form.discount.expiration.startDate" v-bind="attrs_input" label="할인시작일자" @input="emit" />
                                    </v-col>
                                    <v-col cols="12" sm="6" class="pa-2">
                                        <v-datetime-field v-model="form.discount.expiration.endDate" v-bind="attrs_input" label="할인종료일자" @input="emit" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-expand-transition> -->
                    </v-row>
                </v-card-text>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_switch } from "@/assets/variables/attrs";
import { initProduct } from "@/assets/variables/inits";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

const units = [
    { text: "할인가", value: "price" },
    { text: "할인금액", value: "won" },
    { text: "할인비율", value: "percent" },
];

export default {
    components: {
        ViewSectionCard,
        VDatetimeField,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        units,

        attrs_switch,
        attrs_input,
    }),
    computed: {
        discountPrice() {
            let { price } = this.value;
            price = +price;

            let { amount } = this.form.discount;
            amount = +amount;

            switch (this.form.discount.unit) {
                case "percent": {
                    price -= (price * amount) / 100;
                    break;
                }
                case "price": {
                    price = amount;
                    break;
                }
                case "won": {
                    price -= amount;
                    break;
                }
            }

            return price;
        },
        amountSuffix() {
            switch (this.form.discount.unit) {
                case "percent": {
                    return "%";
                }
                case "price":
                case "won": {
                    return "원";
                }
            }
        },
        unitHint() {
            switch (this.form.discount.unit) {
                case "percent": {
                    return "판매가에서 차감할 비율을 입력";
                }
                case "price": {
                    return "할인되어 판매되는 금액을 입력";
                }
                case "won": {
                    return "판매가에서 차감할 금액을 입력";
                }
            }
        },
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
    watch: {
        value() {
            this.sync();
        },
    },
    mounted() {
        this.sync();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-text-field__details {
    margin: 0 !important;
    padding: 0 4px !important;
}
</style>