<template>
    <v-expand-transition>
        <div v-show="showsSearch">
            <v-card flat outlined class="mb-3" v-bind="{ loading }">
                <v-card-title class="subtitle-2 font-weight-bold">{{ label }}</v-card-title>
                <v-card-text class="py-0">
                    <slot />
                </v-card-text>
                <v-card-actions v-if="!hideActions" class="py-0">
                    <v-spacer />
                    <v-btn color="primary" rounded text v-bind="{ loading }" @click="$emit('search')"> <v-icon small>mdi-magnify</v-icon> 검색 </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "검색필터" },
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
        hideActions: { type: Boolean, default: false },
    },
};
</script>

<style></style>
