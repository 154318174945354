var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_c('view-section-card', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.optionsEnabled,
      expression: "!form.optionsEnabled"
    }],
    attrs: {
      "title": "재고사용",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "value": true
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.stockEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.form, "stockEnabled", $$v);
            },
            expression: "form.stockEnabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.stockEnabled,
      expression: "form.stockEnabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "재고"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.stock,
      callback: function ($$v) {
        _vm.$set(_vm.form, "stock", $$v);
      },
      expression: "form.stock"
    }
  }, 'v-currency-field', _vm.attrs_input, false))], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }