<template>
    <v-data-table v-bind="{ items, headers, itemClass }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
        <template #[`item.product`]="{ item }">
            <v-row class="pa-3 pa-sm-0" align="center">
                <v-col cols="12" sm="auto" class="pa-0 pa-sm-3 purchase-item-image">
                    <image-popup :src="item?.product?.thumb?.url" size="40" rounded="lg" :attrs_avatar="{ rounded: true }" />
                </v-col>
                <v-col class="pa-0 pa-sm-3">
                    <div class="caption">{{ item.purchaseNo }}</div>
                    <div>
                        {{ item?.name }}
                    </div>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, PURCHASE_ORDER_STATUSES } from "@/assets/variables";

import ImagePopup from "@/components/console/dumb/image-popup.vue";
import OrderViewDelivery from "./order-view-delivery.vue";
import OrderViewClaim from "./order-view-claim.vue";

const headers = initDataTableHeaders([
    { width: 440, text: "제품", value: "product" },
    // { width: 160, text: "제품코드", value: "code" },
    { width: 100, text: "판매가", value: "price", formatter: (value) => value?.format?.() || value || "-", align: "center" },
    { width: +70, text: "수량", value: "amount", formatter: (value) => value?.format?.() || value || "-", align: "center" },
    { width: 100, text: "합계", value: "totalPrice", formatter: (value) => value?.format?.() || value || "-", align: "center" },
]);
export default {
    components: {
        ImagePopup,
        OrderViewDelivery,
        OrderViewClaim,
    },
    props: {
        purchases: { type: Array, default: () => [] },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
    },
    methods: {
        itemClass(item) {
            let className = "";
            if (item.disabled) {
                className += " item--canceled";
            }
            return className.trim();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & > * {
            height: auto !important;
            max-height: auto !important;
            min-height: auto !important;
            font-size: 12px;
            font-weight: normal;
        }

        &:last-child {
            padding-bottom: 16px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
::v-deep {
    .item--canceled {
        color: rgba(0, 0, 0, 0.5);

        .purchase-item-image {
            opacity: 0.5;
        }
    }
}
</style>
