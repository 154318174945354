var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('list-heading', _vm._b({
    attrs: {
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }, 'list-heading', {
    title: _vm.title
  }, false)), _c('list-search', _vm._b({
    on: {
      "search": function ($event) {
        (_vm.page = 1) && _vm.search();
      }
    }
  }, 'list-search', {
    loading: _vm.loading,
    showsSearch: _vm.showsSearch
  }, false), [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "작성일자",
      "clearable": "",
      "type": "date"
    },
    model: {
      value: _vm.filter.createdAt[0],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAt, 0, $$v);
      },
      expression: "filter.createdAt[0]"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "작성일자",
      "clearable": "",
      "type": "date"
    },
    model: {
      value: _vm.filter.createdAt[1],
      callback: function ($$v) {
        _vm.$set(_vm.filter.createdAt, 1, $$v);
      },
      expression: "filter.createdAt[1]"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "items": _vm.searchKeys,
      "label": "검색조건"
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "검색어",
      "disabled": !_vm.filter.searchKey
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        (_vm.page = 1) && _vm.search();
      }
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _vm.categories.length ? [_c('v-row', {
    staticClass: "mt-n3"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('board-categories-list', _vm._b({}, 'board-categories-list', {
    categories: _vm.categories
  }, false))], 1)], 1)] : _vm._e(), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "headers": _vm.headers,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.thumb`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-row', [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "width": "54",
            "height": "54",
            "contain": ""
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: `item.files.0`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "href": value === null || value === void 0 ? void 0 : value.url,
            "target": "_blank",
            "disabled": !value
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)];
      }
    }, {
      key: `item.category`,
      fn: function (_ref4) {
        var _vm$categories$find;
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(((_vm$categories$find = _vm.categories.find(function (item) {
          return item.value == value;
        })) === null || _vm$categories$find === void 0 ? void 0 : _vm$categories$find.text) || "-") + " ")];
      }
    }, {
      key: `item.meta.href`,
      fn: function (_ref5) {
        var value = _ref5.value;
        return [!!value ? _c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "href": value,
            "target": "_blank"
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1) : [_vm._v("-")]];
      }
    }, {
      key: `item.meta.youtube`,
      fn: function (_ref6) {
        var value = _ref6.value;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "href": value,
            "target": "_blank",
            "disabled": !value
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          attrs: {
            "small": "",
            "title": "수정"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), false ? _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "복사"
          },
          on: {
            "click": function ($event) {
              return _vm.copy(item);
            }
          }
        }, [_vm._v("mdi-content-copy")]) : _vm._e(), _c('v-icon', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "title": "삭제"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-btn', {
    attrs: {
      "bottom": "",
      "color": "accent",
      "fab": "",
      "fixed": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }