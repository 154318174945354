<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading title="문의하기 목록" showsFilterButton v-model="showsSearch" />

            <list-search v-bind="{ showsSearch }" v-on="{ search }">
                <v-row class="ma-n2">
                    <v-col cols="6" md="3" class="pa-2">
                        <v-text-field v-model="filter.createdAts[0]" label="등록일자" type="date" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="pa-2">
                        <v-text-field v-model="filter.createdAts[1]" label="등록일자" type="date" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="pa-2">
                        <v-select v-model="filter.searchKey" label="검색대상" :items="filterItems" v-bind="attrs_input" />
                    </v-col>
                    <v-col cols="6" md="3" class="pa-2">
                        <v-text-field v-model="filter.searchValue" label="검색어" :disabled="filter.searchKey == null" v-bind="attrs_input" />
                    </v-col>
                </v-row>
            </list-search>

            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>

                <template #[`item.actions`]="{ item }">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-2" />

            <v-dialog v-model="questionDialog" max-width="720px">
                <v-card v-if="questionDialog">
                    <v-card-title>
                        <span class="subtitle-1">제품문의</span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="editItem.subject" label="문의 제목" persistent-placeholder readonly dense hide-details class="mt-3"></v-text-field>
                        <v-text-field label="문의날짜" persistent-placeholder readonly dense hide-details :value="editItem.createdAt.toDate()" class="mt-6"></v-text-field>
                        <v-textarea v-model="editItem.content" label="문의 내용" persistent-placeholder readonly dense hide-details class="mt-6"></v-textarea>
                        <v-textarea v-model="editItem.reply" label="답변" persistent-placeholder dense hide-details class="mt-6"></v-textarea>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text @click="save">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, initDataTableHeaders, USER_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ListSearch from "@/components/console/dumb/list-search.vue";

const headers = initDataTableHeaders([
    // { width: 80, text: "위치", value: "code", formatter: (key) => USER_TYPES[key]?.text },
    { width: 160, text: "유형", value: "type" },
    { width: 200, text: "작성자", value: "user", formatter: (user = {}) => (user?.name && user?.username ? `${user?.name}(${user?.username})` : user?.username || user?.name || "-") },
    { text: "문의내용", value: "subject" },
    { width: 110, text: "작성일자", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: 110, text: "답변상태", value: "reply", formatter: (value) => (value ? "답변완료" : "미답변") },
    { width: 100, text: "Actions", value: "actions" },
]);

export default {
    components: {
        ListHeading,
        ListSearch,
    },
    data: () => ({
        attrs_input,

        ///////////////////////////////////////////////
        // 검색필터
        ///////////////////////////////////////////////
        showsSearch: true,

        filter: {
            createdAts: ["", ""],
            searchKey: "",
            searchValue: "",
        },

        filterItems: [
            { text: "전체", value: "" },
            { text: "회원아이디", value: "user.username" },
        ],

        ///////////////////////////////////////////////
        // 제품문의 테이블 정보
        ///////////////////////////////////////////////
        page: 1,
        pageCount: 0,
        limit: 20,

        questions: [],
        headers,

        ///////////////////////////////////////////////////
        //
        ///////////////////////////////////////////////////
        questionDialog: false,
        editItem: {},
    }),
    computed: {
        items() {
            return this.questions;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async search() {
            try {
                var { summary, questions } = await api.console.center.questions.getQuestions({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                });

                this.questions = questions;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        edit(question) {
            this.editItem = Object.assign({}, question);
            this.questionDialog = true;
        },

        close() {
            this.questionDialog = false;
        },

        async save() {
            try {
                await api.console.center.questions.putQuestion(this.editItem);
                await this.search();
                alert("저장되었습니다");
                this.questionDialog = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
