<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="반품관리" showsFilterButton v-model="showsSearch" />
            <purchase-list-search v-bind="{ showsSearch }" />
            <return-list-filter v-bind="{ dashboard }" class="mt-3" />

            <v-data-table v-model="selected" v-bind="{ items, headers, loading }" :items-per-page="-1" show-select disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item?.order?.receiver?.title }}</div>
                    <div>{{ item?.order?.receiver?.name }}</div>
                    <div>{{ item?.order?.receiver?.phone }}</div>
                    <div>{{ item?.order?.receiver?.email }}</div>
                </template>
                <template #[`item.order.receiver.address`]="{ item }">
                    <div v-if="item.order.receiver.postcode">[{{ item.order.receiver.postcode }}]</div>
                    <div>{{ item.order.receiver.address1 }} {{ item.order.receiver.address2 }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />

            <v-card outlined>
                <v-card-text class="pa-0">
                    <v-row align="center" class="ma-0">
                        <v-col cols="12" sm="3" md="2">수거처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="pt-0 pt-sm-3">
                            <!-- <list-collection-pending-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" /> -->
                            <list-collection-progress-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                            <list-collection-complete-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-row align="center" class="ma-0">
                        <v-col cols="12" sm="3" md="2">반품처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="pt-0 pt-sm-3">
                            <list-return-complete-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                            <list-return-reject-form v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                            <list-to-exchange-btn v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-row align="center" class="ma-0">
                        <v-col cols="12" sm="3" md="2">정보수정</v-col>
                        <v-col cols="12" sm="9" md="10" class="pt-0 pt-sm-3">
                            <list-claim-form v-bind="{ loading, selected }" v-on="{ search, loading: (value) => (loading = value) }" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES } from "@/assets/variables";
import { decode__productOptionName } from "@/plugins/vue-methods-shop";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ReturnListFilter from "@/components/console/shop/purchases/return-list-filter.vue";
import PurchaseListSearch from "@/components/console/shop/purchases/purchase-list-search.vue";

import ListCollectionPendingBtn from "@/components/console/shop/purchases/list-actions/list-collection-pending-btn.vue";
import ListCollectionProgressBtn from "@/components/console/shop/purchases/list-actions/list-collection-progress-btn.vue";
import ListCollectionCompleteBtn from "@/components/console/shop/purchases/list-actions/list-collection-complete-btn.vue";

import ListReturnCompleteBtn from "@/components/console/shop/purchases/list-actions/list-return-complete-btn.vue";
import ListReturnRejectForm from "@/components/console/shop/purchases/list-actions/list-return-reject-form.vue";
import ListToExchangeBtn from "@/components/console/shop/purchases/list-actions/list-to-exchange-btn.vue";

import ListClaimForm from "@/components/console/shop/purchases/list-actions/list-claim-form.vue";

const headers = [
    { width: 190, text: "주문번호\n제품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 100, text: "주문상태", value: "orderStatusMessage" },
    { width: 100, text: "클레임상태", value: "claimStatusMessage" },
    { width: 100, text: "클레임 사유", value: "claimReason" },
    { width: 200, text: "클레임 상세사유", value: "claimReasonDetails" },
    { width: 120, text: "연락처", value: "order.sender.phone" },
    { width: 120, text: "제품코드", value: "code" },
    { width: 200, text: "구매제품", value: "product.name" },
    { width: 200, text: "옵션정보", value: "name", formatter: decode__productOptionName },
    { width: +90, text: "구매수량", value: "amount" },
    { width: 120, text: "구매자", value: "order.sender" },
    { width: 120, text: "수취인", value: "order.receiver" },
    { width: 300, text: "배송지", value: "order.receiver.address" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));

export default {
    components: {
        OrderView,

        ListHeading,
        ReturnListFilter,
        PurchaseListSearch,

        ListCollectionPendingBtn,
        ListCollectionProgressBtn,
        ListCollectionCompleteBtn,

        ListReturnCompleteBtn,
        ListReturnRejectForm,
        ListToExchangeBtn,

        ListClaimForm,
    },
    data: () => ({
        purchases: [],

        limit: 20,
        summary: { totalCount: 0 },

        dashboard: {
            returnRequest: 0,
            collectProgress: 0,
            returnComplete: 0,
        },

        headers,
        loading: false,
        showsSearch: true,

        selected: [],
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.claimStatus = query?.claimStatus || [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value, PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value, PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value];

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;

                var { dashboard } = await api.console.shop.purchases.getDashboard({
                    manage: "return",
                });
                this.dashboard = dashboard;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
