<template>
    <v-data-table v-bind="{ items, headers }" dense disable-sort>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

const headers = initDataTableHeaders(
    [
        { width: 160, value: "product", text: "제품 제품코드" },
        { width: 160, value: "values.0.value", text: "옵션1" },
        { width: 160, value: "values.1.value", text: "옵션2" },
        { width: 160, value: "values.2.value", text: "옵션3" },
        { width: 160, value: "values.3.value", text: "옵션4" },
        { width: 160, value: "values.4.value", text: "옵션5" },
        { width: 160, value: "values.5.value", text: "옵션6" },
        { width: 160, value: "values.6.value", text: "옵션7" },
        { width: 160, value: "code", text: "필수옵션 제품코드" },
        { width: +60, value: "stock", text: "재고", formatter: (value) => value?.format?.() || value || "-" },
        { width: 100, value: "price", text: "총 제품가격", formatter: (value) => value?.format?.() || value || "-" },
    ].map((item) => ({
        ...item,
        class: (item.class || "") + " word-break-keep-all",
        cellClass: (item.cellClass || "") + " text-truncate max-width-0",
        withTitle: true,
    }))
);

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return this.value?.options || [];
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
}
</style>
