<template>
    <v-layout justify-center>
        <v-responsive width="100%" height="100%">
            <list-heading title="회원 목록" v-model="showsSearch" showsFilterButton />
            <user-list-search v-bind="{ showsSearch, loading }" />
            <v-row class="mt-n3">
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white" class="caption" v-bind="{ loading }">
                            <v-layout align-baseline>
                                <span> 검색결과: </span>
                                <b class="mx-1"> {{ summary.totalCount?.format?.() }} </b> 건
                            </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <user-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-card outlined>
                                <v-btn color="white primary--text" class="caption" v-bind="{ ...attrs, loading }" v-on="on">
                                    <v-layout align-center> <v-icon small class="mr-1">mdi-account-plus</v-icon> 회원생성 </v-layout>
                                </v-btn>
                            </v-card>
                        </template>
                    </user-form>
                </v-col>
                <v-col cols="auto">
                    <v-card outlined>
                        <v-btn color="white green--text" class="caption" v-bind="{ loading }" @click="excel">
                            <v-layout align-center> <v-icon small class="mr-1">mdi-microsoft-excel</v-icon> 엑셀다운로드 </v-layout>
                        </v-btn>
                    </v-card>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" v-bind="{ items, loading }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
                <template #[`item.title`]="{ item, value }">
                    <edit-dialog-user-type :value="item" v-on="{ updateItem }"> {{ value }} </edit-dialog-user-type>
                </template>
                <template #[`item.console`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="console" v-bind="{ ...attrs_switch, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.manager`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="manager" v-bind="{ ...attrs_switch, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.counsel`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="counsel" v-bind="{ ...attrs_switch, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.credits`]="{ item }">
                    <user-scope-switch v-model="item.scope" code="credits" v-bind="{ ...attrs_switch, item }" @input="(scope) => update({ _id: item._id, scope })" />
                </template>
                <template #[`item.isWarning`]="{ item }">
                    <v-switch v-model="item.isWarning" v-bind="attrs_switch" @change="(isWarning) => update({ _id: item._id, isWarning })" />
                </template>
                <template #[`item.isApproved`]="{ item }">
                    <v-switch v-model="item.isApproved" v-bind="attrs_switch" @change="(isApproved) => update({ _id: item._id, isApproved })" />
                </template>
                <template #[`item.orderPrice`]="{ item }">
                    <span v-if="item.orderPrice">{{ item.orderPrice.format() }}</span>
                    <span v-else>-</span>
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon tile :to="`${$route.path}/${item._id}`">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, initDataTableHeaders, USER_TYPES } from "@/assets/variables";

import UserForm from "@/components/console/users/user-form.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import UserListSearch from "@/components/console/users/user-list-search.vue";
import UserScopeSwitch from "@/components/console/users/user-scope-switch.vue";
import EditDialogUserType from "@/components/console/users/edit-dialog-user-type.vue";

const headers = initDataTableHeaders([
    { text: "유형", value: "type", formatter: (value) => USER_TYPES[value]?.text || "관리자", width: +90 },
    { text: "아이디", value: "username", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 100 },
    { text: "연동코드", value: "meta.CustNo", width: 100 },
    { text: "이름\r\n(상호명)", value: "name", cellClass: "max-width-0 text-truncate py-3", withTitle: true },
    // { text: "상호명", value: "business.name", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 100 },
    { text: "대표자", value: "business.ceoName", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 100 },
    { text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || value || "-", width: 120 },
    { text: "이메일", value: "email", cellClass: "max-width-0 text-truncate py-3", withTitle: true, width: 120 },
    { text: "제품구매금액", value: "orderPrice", width: 120 },
    { text: "영업담당자", value: "managers", cellClass: "max-width-0 text-truncate py-3 text-start", withTitle: true, align: "center", formatter: (value) => value.map(({ username, name }) => (name ? `${username} (${name})` : username)).join(", ") },
    { text: "최고관리자\r\n(회원권한)", value: "console", class: "px-1", align: "center", formatter: (value, item) => ((item.scope || []).includes("manager") ? "Y" : "N"), width: +80 },
    { text: "영업담당자\r\n(회원권한)", value: "manager", class: "px-1", align: "center", formatter: (value, item) => ((item.scope || []).includes("manager") ? "Y" : "N"), width: +80 },
    { text: "상담사\r\n(회원권한)", value: "counsel", class: "px-1", align: "center", formatter: (value, item) => ((item.scope || []).includes("counsel") ? "Y" : "N"), width: +80 },
    { text: "외상거래\r\n(회원권한)", value: "credits", class: "px-1", align: "center", formatter: (value, item) => ((item.scope || []).includes("credits") ? "Y" : "N"), width: +80 },
    { text: "주의회원", value: "isWarning", class: "px-1", align: "center", excel: (value) => (value ? "활성" : "비활성"), width: +80 },
    { text: "가입승인", value: "isApproved", class: "px-1", align: "center", excel: (value) => (value ? "승인" : "미승인"), width: +80 },
    { text: "가입일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-", width: 100 },
    { text: "", value: "actions", cellClass: "py-2", align: "center", width: +60 },
]);

export default {
    components: {
        UserForm,
        ListHeading,
        UserListSearch,
        UserScopeSwitch,
        EditDialogUserType,
    },
    data: () => ({
        users: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
        showsSearch: true,

        headers,
        attrs_switch,
    }),
    computed: {
        items() {
            return this.users.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            delete query.page;

            return { ...query };
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        params() {
            this.init().then(this.search);
        },
    },
    methods: {
        async init() {
            try {
                this.users = [];
                this.summary = { totalCount: 0 };
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, users } = await api.console.users.gets({
                    headers: { skip, limit, mode: "without-default" },
                    params,
                });

                this.users = users;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(user) {
            try {
                await api.console.users.put(user);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        updateItem(user) {
            const index = this.users.findIndex(({ _id }) => _id == user._id);
            if (0 <= index) this.users.splice(index, 1, user);
            else {
                this.users = [user, ...this.users];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { params } = this;
                var { users } = await api.console.users.gets({
                    headers: { mode: "without-default" },
                    params,
                });

                this.$excel(users, headers, "회원목록");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
