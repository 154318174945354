var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1080",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "소프트웨어 다운로드 로그",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('log-list-search', _vm._b({}, 'log-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined mt-3",
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.user`,
      fn: function (_ref2) {
        var _value$business;
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('v-row', [value ? _c('v-col', {
          staticClass: "py-0"
        }, [_vm._v(" " + _vm._s(value === null || value === void 0 ? void 0 : (_value$business = value.business) === null || _value$business === void 0 ? void 0 : _value$business.name) + " (" + _vm._s(value === null || value === void 0 ? void 0 : value.username) + ") ")]) : _c('v-col', {
          staticClass: "py-0 text--secondary"
        }, [_vm._v(" 삭제된 회원입니다 ")]), _c('v-col', {
          staticClass: "px-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": `/console/users/${value === null || value === void 0 ? void 0 : value._id}`
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)], 1), _c('v-col', {
          staticClass: "pl-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              query: Object.assign({}, _vm.$route.query, {
                _user: value === null || value === void 0 ? void 0 : value._id,
                page: null
              })
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-account-check-outline")])], 1)], 1)], 1)];
      }
    }, {
      key: `item.software`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [_c('v-row', [_c('v-col', {
          staticClass: "py-0",
          class: {
            'text--secondary': !(value !== null && value !== void 0 && value.name)
          }
        }, [_vm._v(" " + _vm._s((value === null || value === void 0 ? void 0 : value.name) || "삭제된 소프트웨어입니다") + " ")]), _c('v-col', {
          staticClass: "px-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              path: '/console/shop/catalogues'
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-link")])], 1)], 1), _c('v-col', {
          staticClass: "pl-1 py-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-small": "",
            "icon": "",
            "text": "",
            "to": {
              query: Object.assign({}, _vm.$route.query, {
                _software: value === null || value === void 0 ? void 0 : value._id,
                page: null
              })
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-file-check-outline")])], 1)], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }