<template>
    <list-search v-bind="{ loading, showsSearch }" @search="emit">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field v-model="query.searchDateValue[0]" type="date" max="2999-12-31" label="주문일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <v-text-field v-model="query.searchDateValue[1]" type="date" max="2999-12-31" label="주문일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-user label="사용자" v-model="query._user" :itemText="({ name, username }) => `${name} (${username})`" :sort="{ name: 1, username: 1, _id: -1 }" placeholder="전체" clearable v-bind="{ ...attrs_input, params }" v-on="{ emit }" />
            </v-col>
            <template v-if="isConsole">
                <v-col cols="12" sm="6" lg="3" class="pa-2">
                    <autocomplete-user label="영업담당자" v-model="query._managers" :params="{ scope: 'manager' }" :itemText="({ username }) => username" :sort="{ username: 1, _id: -1 }" placeholder="전체" clearable v-bind="attrs_input" v-on="{ emit }" />
                </v-col>
            </template>
            <!-- <v-col cols="12" sm="6" lg="3" class="pa-2">
                <autocomplete-product label="제품" v-model="query._product" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col> -->
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import { mapState } from "vuex";

let initQuery = (query = {}) => ({
    ...query,
    ["_user"]: query?.["_user"] || null,
    ["_product"]: query?.["_product"] || null,
    ["searchDateValue"]: [...(query?.["searchDateValue"] || [null, null])],
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteProduct,
    },
    props: {
        loading: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
    }),
    computed: {
        ...mapState(["scope", "payload"]),
        isConsole() {
            return this.scope?.includes?.("console");
        },
        params() {
            if (this.isConsole) return {};
            else return { _managers: this.payload?._user };
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            const { ...query } = this.query || {};

            delete query.page;

            if (!query?.["searchDateValue"]) delete query["searchDateValue"];
            if (!query?.["searchDateValue"]?.[0] && !query?.["searchDateValue"]?.[1]) delete query["searchDateValue"];

            if (!query?.["_user"]) delete query["_user"];
            if (!query?.["_product"]) delete query["_product"];

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
