<template></template>

<script>
import XLSX from "xlsx";

import { initShopBulkUploadData } from "@/assets/variables";

const sheetName = "4.옵션제품 필수옵션 등록";

const nameData = (row) => ({
    rawData: row,

    ["제품 제품코드"]: row?.A || null,

    ["옵션1"]: row?.B || null,
    ["옵션2"]: row?.C || null,
    ["옵션3"]: row?.D || null,
    ["옵션4"]: row?.E || null,
    ["옵션5"]: row?.F || null,
    ["옵션6"]: row?.G || null,
    ["옵션7"]: row?.H || null,

    ["필수옵션 제품코드"]: row?.I || null,
    ["재고"]: row?.J || null,
    ["총 제품가격"]: row?.K || null,
});

const structureData = ({ rawData, ...nameData }, index, [header]) => {
    const values = [nameData["옵션1"], nameData["옵션2"], nameData["옵션3"], nameData["옵션4"], nameData["옵션5"], nameData["옵션6"], nameData["옵션7"]].map((value, index) => ({ index, value }));

    let color;

    let name;
    if (index != 0) {
        name = values.reduce((name, item) => {
            if (item.value) {
                if (name) name += ` / `;

                let term = header[`옵션${item.index + 1}`];
                let desc = item.value;
                if (term == "색상") {
                    let [desc1, desc2] = desc.split(":");
                    desc = desc1;

                    color = {};
                    color.name = desc1;
                    if (/^(#?[a-fA-F0-9]){6}$|^(#?[a-fA-F0-9]){3}$/.test(desc2)) color.hexa = desc2;
                    else color.img = desc2;
                }

                name += `${encodeURIComponent(term)}: ${encodeURIComponent(desc)}`;
            }
            return name;
        }, "");
    }

    let value;
    if (index != 0) {
        value = values.reduce((value, item) => {
            if (item.value) {
                if (value) value += ` / `;

                let term = header[`옵션${item.index + 1}`];
                let desc = item.value;
                if (term == "색상") desc = desc.split(":")[0];
                value += encodeURIComponent(desc);
            }
            return value;
        }, "");
    }

    return {
        product: nameData["제품 제품코드"],

        values,

        name,
        code: nameData["필수옵션 제품코드"],
        value,
        color,
        price: nameData["총 제품가격"] ? +(+(nameData["총 제품가격"] || 0)).toFixed() : "-",
        stock: nameData["재고"],

        rawData,
        nameData,
    };
};

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 3 });

                const options = rawData.map(nameData).map(structureData);

                this.$emit("input", initShopBulkUploadData({ ...this.value, options }));
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>
