<template>
    <v-dialog v-model="dialog" width="560" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" class="pa-2" v-if="!code">
                        <v-select v-model="form.code" label="위치" :items="types" v-bind="{ ...attrs_input, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="2" class="pa-2">
                        <v-text-field v-model="form.index" label="#" v-bind="{ ...attrs_input, loading }" :disabled="isCreate" />
                    </v-col>
                    <v-col cols="12" md="10" class="pa-2">
                        <autocomplete-product v-model="form._product" label="제품" :params="params__product" v-bind="{ ...attrs_input, loading }" @input="$nextTick(setProduct)" v-on="{ setProduct }" ref="autocomplete-product" />
                    </v-col>
                    <v-col cols="12" class="pa-2 pt-1">
                        <v-card outlined>
                            <v-fade-transition leave-absolute>
                                <v-card-text v-show="!product?.review__best" class="text-align-center"> 베스트 리뷰가 없습니다. </v-card-text>
                            </v-fade-transition>
                            <v-expand-transition>
                                <div v-show="product?.review__best">
                                    <item-review :review="product?.review__best" showsUser flat />
                                </div>
                            </v-expand-transition>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.startsAt" label="전시시작" class="pa-0" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-datetime-field v-model="form.endsAt" label="전시종료" class="pa-0" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "미노출" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, DISPLAY_CODES } from "@/assets/variables";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import ItemProduct from "../../dumb/item-product.vue";
import ItemReview from "../../dumb/item-review.vue";

const types = Object.values(DISPLAY_CODES);
const params__product = { populate: JSON.stringify(["category", "review__best"]) };

const initDisplay = (display = {}) => ({
    ...display,

    _product: display._product || null,

    index: display.index || null,
    shows: display.shows || false,

    startsAt: display.startsAt || null,
    endsAt: display.endsAt || null,
});

export default {
    components: {
        VDatetimeField,
        AutocompleteProduct,
        ItemProduct,
        ItemReview,
    },
    props: {
        _id: { type: String, default: null },
        code: { type: String, default: null },
    },
    data: () => ({
        form: initDisplay(),
        product: undefined,

        types,
        params__product,

        attrs_input,
        attrs_switch,

        dialog: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "리뷰전시";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
        "form._product"() {
            this.$nextTick(this.setProduct);
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initDisplay({ code: this.code });
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.displays.get({ _id }))?.display;
                    this.$emit("input", { ...this.form });
                }
            } finally {
                this.loading = false;
                this.$nextTick(this.setProduct);
            }
        },
        validates() {
            try {
                let { code, _product } = this.form;

                if (!code) throw new Error("위치를 선택하세요");
                if (!_product) throw new Error("제품을 선택하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (!this.validates()) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                let { post, put } = api.console.shop.displays;
                let { ...form } = this.form;
                let display = (await (this.isCreate ? post : put)(form))?.display;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", display);
                this.dialog = false;
            } finally {
                this.loading = false;
            }
        },

        setProduct() {
            this.product = this.$refs["autocomplete-product"]?.item;
        },
    },
};
</script>
