<template></template>

<script>
import XLSX from "xlsx";

import { initShopBulkUploadData } from "@/assets/variables";

const sheetName = "2.브랜드등록";

const nameData = (row) => ({
    rawData: row,

    ["이름"]: row?.A || null,
    ["코드"]: row?.B || null,
    ["라벨 이미지"]: row?.C || null,
    ["목록 이미지"]: row?.D || null,
    ["상세 이미지"]: row?.E || null,
    ["설명"]: row?.F || null,
});

const structureData = ({ rawData, ...nameData }) => ({
    name: nameData["이름"],
    code: nameData["코드"],
    desc: nameData["설명"],

    label: nameData["라벨 이미지"],
    thumb: nameData["목록 이미지"],
    image: nameData["상세 이미지"],

    rawData,
    nameData,
});

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    mounted() {
        this.process();
    },
    watch: {
        file() {
            this.process();
        },
    },
    methods: {
        process() {
            if (!this.file) return;

            const reader = new FileReader();
            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: sheetName });
                const sheet = workBook.Sheets[sheetName];
                const rawData = XLSX.utils.sheet_to_json(sheet, { header: "A", range: 3 });

                const brands = rawData.map(nameData).map(structureData);

                this.$emit("input", initShopBulkUploadData({ ...this.value, brands }));
                this.$emit("loaded");
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>