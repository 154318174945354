var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "추가제품(카드)",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.addOnsConf.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.form.addOnsConf, "enabled", $$v);
            },
            expression: "form.addOnsConf.enabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.addOnsConf.enabled,
      expression: "form.addOnsConf.enabled"
    }]
  }, [_c('v-divider'), _c('autocomplete-product', _vm._b({
    attrs: {
      "multiple": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('item-product', {
          attrs: {
            "product": item
          }
        })];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('item-product', {
          attrs: {
            "product": item
          }
        })];
      }
    }]),
    model: {
      value: _vm.form._addOns,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_addOns", $$v);
      },
      expression: "form._addOns"
    }
  }, 'autocomplete-product', _vm.attrs_autocomplete, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }