<template>
    <v-data-table v-bind="{ items, headers }" dense disable-sort>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" :title="header.withTitle ? header.formatter.bind(item)(value, item) : undefined" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { DISPLAY_CODES, initDataTableHeaders, initShopBulkUploadData, PRODUCT_TYPES } from "@/assets/variables";

const headers = initDataTableHeaders(
    [
        { width: 160, value: "category", text: "카테고리 코드" },
        { width: +90, value: "type", text: "제품 유형", formatter: (value) => (PRODUCT_TYPES[value] || PRODUCT_TYPES.NORMAL_PRODUCT).text },
        { width: 320, value: "name", text: "제품명" },
        { width: 140, value: "relatedGroupCode", text: "연관제품 코드" },
        { width: 140, value: "code", text: "제품 코드" },
        { width: 100, value: "price", text: "판매가", formatter: (value) => value?.format?.() || value || "-" },
        { width: +60, value: "stock", text: "재고", formatter: (value) => value?.format?.() || value || "-" },
        { width: +60, value: "sequence", text: "진열 순서", formatter: (value) => value?.format?.() || value || "-" },
        { width: 160, value: "brand", text: "브랜드 코드" },
        { width: 120, value: "manufacturer", text: "제조사" },
        { width: +80, value: "origin", text: "원산지" },
        { width: 120, value: "flags", text: "플래그", formatter: (value) => value.join(", ") },
        { width: 120, value: "hashtags", text: "해시태그", formatter: (value) => value.join(", ") },
        { width: +80, value: "shows", text: "전시 여부", formatter: (value) => (value ? "Y" : "N"), align: "center" },
        { width: +80, value: "sells", text: "판매 여부", formatter: (value) => (value ? "Y" : "N"), align: "center" },
        { width: +80, value: "new_arrival", text: "신제품", formatter: (value, item) => (item.groups.includes(DISPLAY_CODES.NEW_ARRIVAL.value) ? "Y" : "N"), align: "center" },
        { width: +80, value: "best_seller", text: "베스트 셀러", formatter: (value, item) => (item.groups.includes(DISPLAY_CODES.BEST_SELLER.value) ? "Y" : "N"), align: "center" },
        { width: +80, value: "popular", text: "이달의 인기제품", formatter: (value, item) => (item.groups.includes(DISPLAY_CODES.POPULAR.value) ? "Y" : "N"), align: "center" },
        { width: +80, value: "best_review", text: "베스트 리뷰", formatter: (value, item) => (item.groups.includes(DISPLAY_CODES.BEST_REVIEW.value) ? "Y" : "N"), align: "center" },
        { width: 120, value: "thumb", text: "썸네일" },
        { width: +80, value: "optionsConf.enabled", text: "옵션제품", formatter: (value) => (value ? "Y" : "N"), align: "center" },
        { width: +80, value: "relatedsConf.enabled", text: "추가제품", formatter: (value) => (value ? "Y" : "N"), align: "center" },
        { width: +80, value: "seoEnabled", text: "SEO", formatter: (value) => (value ? "Y" : "N"), align: "center" },
        { width: 160, value: "seo.title", text: "title (SEO)" },
        { width: 160, value: "seo.author", text: "author (SEO)", formatter: (value, item) => item.seo.meta.find(({ name }) => name == "author")?.content },
        { width: 160, value: "seo.description", text: "description (SEO)", formatter: (value, item) => item.seo.meta.find(({ name }) => name == "description")?.content },
        { width: 160, value: "seo.keywords", text: "keywords (SEO)", formatter: (value, item) => item.seo.meta.find(({ name }) => name == "keywords")?.content },
    ].map((item) => ({
        ...item,
        class: (item.class || "") + " word-break-keep-all",
        cellClass: (item.cellClass || "") + " text-truncate max-width-0",
        withTitle: true,
    }))
);

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return this.value?.products || [];
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .word-break-keep-all {
        word-break: keep-all;
    }
    .max-width-0 {
        max-width: 0px;
    }
}
</style>
