<template>
    <v-file-btn v-model="file" v-bind="{ ...$attrs, loading }">
        <slot />
    </v-file-btn>
</template>

<script>
import XLSX from "xlsx";

import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";
import api from "@/api";

const structureData = (rawData) => ({
    ["username"]: rawData["아이디"],
    ["managers"]: Object.keys(rawData)
        .filter((key) => /^담당자아이디/.test(key))
        .map((key) => rawData[key]),
    ["meta.CustNo"]: rawData["쇼핑몰코드"],

    rawData,
});

export default {
    components: {
        VFileBtn,
    },
    props: {
        value: { type: Boolean, default: false }, // loading
    },
    data: () => ({
        file: null,
        items: [],
        loading: false,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        file() {
            this.process();
        },
    },
    methods: {
        sync() {
            this.loading = this.value;
        },
        setLoading(bool) {
            this.$emit("input", bool);
        },
        process() {
            if (!this.file) return;

            if (this.loading) return;
            else this.setLoading(true);

            const reader = new FileReader();

            reader.onload = async () => {
                const workBook = XLSX.read(reader.result, { type: "binary", sheets: 0 });

                const sheet = Object.values(workBook.Sheets)[0];
                const rawData = XLSX.utils.sheet_to_json(sheet);

                const items = rawData.map(structureData);

                try {
                    if (items.length < 1) throw new Error("데이터가 없습니다");

                    const go = confirm(`${items.length} 건의 데이터가 확인되었습니다\r\n계속 진행하시겠습니까?`);

                    if (!go) throw new Error("취소하셨습니다");
                } catch (error) {
                    alert(error.message);
                    this.file = null;
                    this.setLoading(false);
                    return;
                }

                try {
                    const { summary, itemsFailed } = await api.console.users.managers.post({ items });
                    alert(`총 ${summary.totalCount} 건 중 ${summary.successCount} 건 업데이트에 성공했습니다`);
                    console.log({ itemsFailed });
                    this.setLoading(false);
                    this.$emit("search");
                } finally {
                    if (this.loading) this.setLoading(false);
                    this.file = null;
                }
            };
            reader.readAsBinaryString(this.file);
        },
    },
};
</script>

<style></style>
