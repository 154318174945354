<template>
    <v-layout justify-center>
        <v-responsive max-width="1280" width="100%" height="100%">
            <list-heading title="회원등급">
                <template #add-button>
                    <user-level-form @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="{ ...attrs, loading }" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </user-level-form>
                </template>
            </list-heading>

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" style="overflow: hidden" ref="data-table">
                <template #top>
                    <user-level-list-codes />
                    <v-divider />
                </template>

                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" :disabled="disabled__drag" @change="setNewIndex">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>

                <template #[`item.criteria`]="{ item }">
                    <item-criterion :value="item.criteria[0]" />
                </template>

                <template #[`item.isActive`]="{ item }">
                    <v-switch v-model="item.isActive" v-bind="attrs_switch" @change="(isActive) => update({ _id: item._id, isActive })" />
                </template>

                <template #[`item.isWelcome`]="{ item }">
                    <v-switch v-model="item.isWelcome" v-bind="attrs_switch" @change="(isWelcome) => update({ _id: item._id, isWelcome })" />
                </template>

                <template #[`item.actions`]="{ item }">
                    <user-level-form :value="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn text icon tile v-bind="attrs" v-on="on">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </user-level-form>

                    <v-btn text icon tile @click="remove(item)" v-if="item.removable">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_switch, USER_LEVEL_CODES } from "@/assets/variables";

import Draggable from "vuedraggable";

import ListHeading from "@/components/console/dumb/list-heading.vue";

import ItemCriterion from "@/components/console/users/levels/list/item-criterion.vue";
import UserLevelForm from "@/components/console/users/levels/form/user-level-form.vue";
import UserLevelListCodes from "@/components/console/users/levels/list/user-level-list-codes.vue";

const headers = [
    { width: +80, text: "#", value: "index" },
    { width: 160, text: "회원유형", value: "codeText" },
    { width: 160, text: "등급명칭", value: "name" },
    { width: 240, text: "등급조건", value: "criteria", cellClass: "pa-0" },
    { width: +80, text: "사용여부", value: "isActive" },
    { width: +80, text: "초기등급", value: "isWelcome" },
    { width: 100, text: "", value: "actions", cellClass: "px-0", align: "center" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, cellClass: (item.cellClass || "") + ` caption`, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,

        ListHeading,

        ItemCriterion,
        UserLevelForm,
        UserLevelListCodes,
    },
    props: {
        code: { type: [String, Array], default: () => [...Object.values(USER_LEVEL_CODES).filter(({ shows }) => shows)].map(({ value }) => value) },
    },
    data: () => ({
        levels: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch,

        dialog: false,
        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),

        items() {
            return [...this.levels];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.code = this.code;

            return { ...query };
        },

        disabled__drag() {
            return JSON.stringify(this.code) == JSON.stringify([...Object.values(USER_LEVEL_CODES).filter(({ shows }) => shows)].map(({ value }) => value)) || this.loading;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.levels = [];
            this.summary = { totalCount: 0 };

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, levels } = await api.console.levels.gets({
                    headers: { skip, limit },
                    params,
                });

                this.levels = levels;
                this.summary = summary;
            } finally {
                this.loading = false;
            }
        },

        async update(form) {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { level } = await api.console.levels.put(form);
                this.updateItem(level);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async remove(level) {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (confirm("해당 회원등급을 삭제하시겠습니까?")) {
                    await api.console.levels.delete(level);
                    await this.search();
                }
            } finally {
                this.loading = false;
            }
        },

        updateItem(item) {
            const index = this.levels.findIndex(({ _id }) => _id == item._id);
            if (0 <= index) this.levels.splice(index, 1, item);
            else {
                this.levels = [item, ...this.levels];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async setNewIndex({ moved = {} }) {
            let {
                element: { _id, index },
                oldIndex,
                newIndex,
            } = moved;
            index += oldIndex - newIndex;
            let form = { _id, index };
            this.update(form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .line-height-1-5 {
        line-height: 1.5;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__mobile-row__cell {
        max-width: calc(100vw - 12px - 16px - 80px - 16px - 12px);
    }
}
</style>
