<template>
    <v-data-table v-bind="{ items, headers }" dense>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value, item)" /> </template>
    </v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

const headers = initDataTableHeaders([
    { value: "parents.0.name", text: "카테고리1", formatter: (value, item) => (item.parents.length == 0 ? item.name : value) || "-" },
    { value: "parents.1.name", text: "카테고리2", formatter: (value, item) => (item.parents.length == 1 ? item.name : value) || "-" },
    { value: "parents.2.name", text: "카테고리3", formatter: (value, item) => (item.parents.length == 2 ? item.name : value) || "-" },
    { value: "code", text: "카테고리코드" },
]);

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return this.value?.categories || [];
        },
    },
};
</script>

<style>
</style>