<template>
    <v-expand-transition>
        <view-section-card title="재고사용" hideDivider v-bind="{ loading }" v-show="!form.optionsEnabled">
            <template #actions>
                <v-switch v-model="form.stockEnabled" :value="true" v-bind="attrs_switch" @change="emit" />
            </template>
            <v-expand-transition>
                <v-sheet v-show="form.stockEnabled">
                    <v-divider />
                    <v-card-text>
                        <v-currency-field v-model="form.stock" label="재고" v-bind="attrs_input" @input="emit" />
                    </v-card-text>
                </v-sheet>
            </v-expand-transition>
        </view-section-card>
    </v-expand-transition>
</template>

<script>
import { attrs_input, attrs_switch, initProduct } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_input,
        attrs_switch,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initProduct(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
