<template>
    <v-btn v-bind="{ ...attrs, loading }" @click="setOrderStatusPayment">{{ title }}</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_ORDER_STATUSES } from "@/assets/variables";

export default {
    props: {
        title: { type: String, default: "발주확인" },
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    computed: {
        attrs() {
            if (0 < Object.keys(this.$attrs).length) return this.$attrs;
            else return { small: true, outlined: true, color: "primary", class: "mr-2" };
        },
    },
    methods: {
        async setOrderStatusPayment() {
            try {
                if (!this.selected.length) throw new Error(`${this.title} 처리할 구매건을 선택해주세요`);
            } catch (error) {
                this.$handleError(error);
                return;
            }

            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, orderStatus: PURCHASE_ORDER_STATUSES.ORDER_CONFIRM.value });
                }

                const _orders = [...new Set(this.selected.map(({ _order }) => _order))];
                for (const _id of _orders) {
                    await api.console.shop.orders.patchState({ _id });
                }

                alert(`${this.title} 처리 되었습니다`);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
