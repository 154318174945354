<template>
    <v-layout justify-center>
        <v-responsive max-width="480" width="100%">
            <list-heading title="bright Academy 카테고리" />

            <view-section-card hideTitle v-bind="{ loading }">
                <v-card-text>
                    <v-chip-group column>
                        <draggable v-model="form.academy.categories">
                            <template v-for="(item, index) in form.academy.categories">
                                <v-chip :key="item" outlined label close @click:close="form.academy.categories.splice(index, 1)"> {{ item }} </v-chip>
                            </template>
                        </draggable>
                    </v-chip-group>
                    <v-text-field v-model="text" v-bind="{ ...attrs_input, loading }" append-icon="mdi-plus" @click:append="addItem" @keydown.enter="addItem" />
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-end">
                    <v-btn text color="red" v-bind="{ loading }" @click="init">초기화하기</v-btn>
                    <v-spacer />
                    <v-btn text color="primary" v-bind="{ loading }" @click="save">저장하기</v-btn>
                </v-card-actions>
            </view-section-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, initSetting } from "@/assets/variables";

import Draggable from "vuedraggable";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import { mapActions, mapState } from "vuex";

export default {
    components: {
        Draggable,
        ListHeading,
        ViewSectionCard,
    },
    data: () => ({
        text: null,
        form: initSetting(),

        attrs_input,

        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getSetting__console"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                this.form = initSetting(await this.getSetting__console());
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { academy } = this.form;
                await api.console.setting.put({ academy });
                this.form = initSetting(await this.getSetting__console());
            } finally {
                this.loading = false;
            }
        },

        addItem() {
            try {
                if (this.form.academy.categories.includes(this.text)) {
                    throw new Error("이미 추가된 항목입니다");
                }

                this.form.academy.categories.push(this.text);
                this.text = null;
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>
