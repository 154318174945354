var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3 mx-n3",
    attrs: {
      "max-width": "1080",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "SMTP 발송"
    }
  }), _c('v-card', _vm._b({
    attrs: {
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("SEO(검색어 최적화 설정)")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "수신자 이메일"
    },
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('naver-smarteditor', _vm._b({
    attrs: {
      "label": "내용"
    },
    model: {
      value: _vm.form.html,
      callback: function ($$v) {
        _vm.$set(_vm.form, "html", $$v);
      },
      expression: "form.html"
    }
  }, 'naver-smarteditor', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-btn', _vm._b({
    attrs: {
      "plain": "",
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.post
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 발송하기 ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }