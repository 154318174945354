<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <list-heading title="제품리뷰" v-model="showsSearch" showsFilterButton />

            <review-list-search v-bind="{ showsSearch, loading }" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.product`]="{ value }">
                    <item-product :product="value" isLink />
                </template>

                <template #[`item.content`]="{ item }">
                    <item-review :review="item" flat color="transparent" class="fill-height d-flex flex-column" />
                </template>

                <template #[`item.reply`]="{ item, value }">
                    <review-reply-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-3 fill-height white-space-pre-line"> {{ value || "-" }} </v-card>
                    </review-reply-edit>
                </template>

                <template #[`item.repliedAt`]="{ item, value }">
                    <review-replied-at-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-3 fill-height"> {{ value?.toDateTime?.() || value || "-" }} </v-card>
                    </review-replied-at-edit>
                </template>

                <template #[`item.isFixed`]="{ item }">
                    <v-switch v-model="item.isFixed" v-bind="attrs_switch" @change="(isFixed) => update({ _id: item._id, isFixed })" />
                </template>

                <template #[`item.fixedIndex`]="{ item, value }">
                    <review-fixed-index-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-3 fill-height"> {{ value?.format?.() || value || "-" }} </v-card>
                    </review-fixed-index-edit>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch } from "@/assets/variables";

import ItemReview from "@/components/console/dumb/item-review.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ReviewReplyEdit from "@/components/console/shop/reviews/review-reply-edit.vue";
import ReviewListSearch from "@/components/console/shop/reviews/review-list-search.vue";
import ReviewRepliedAtEdit from "@/components/console/shop/reviews/review-replied-at-edit.vue";
import ReviewFixedIndexEdit from "@/components/console/shop/reviews/review-fixed-index-edit.vue";

const headers = [
    { width: 100, text: "작성일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 140, text: "작성자 / 아이디", value: "user", formatter: ({ name, username, phone } = {}) => [name, username, phone].filter((item) => item).join("\n") },
    { width: 180, text: "제품", value: "product", cellClass: "pa-0" },
    { width: 320, text: "내용", value: "content", cellClass: "pa-0" },
    { width: 320, text: "답변", value: "reply", cellClass: "pa-0" },
    { width: 100, text: "답변일시", value: "repliedAt", cellClass: "pa-0" },
    { width: +80, text: "상단고정", value: "isFixed" },
    { width: +80, text: "고정순서", value: "fixedIndex", cellClass: "pa-0" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ItemReview,
        ItemProduct,
        ListHeading,
        ReviewReplyEdit,
        ReviewListSearch,
        ReviewRepliedAtEdit,
        ReviewFixedIndexEdit,
    },
    data: () => ({
        reviews: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        attrs_switch,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return this.reviews.map((item, index) => ({ ...item, index: index + 1 }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.page;

            if (query.searchDateValue) query.searchDateKey = "createdAt";

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.reviews = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, reviews } = await api.console.shop.reviews.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.reviews = reviews;
            } finally {
                this.loading = false;
            }
        },

        async update(review) {
            if (this.loading) return;
            else this.loading = true;
            try {
                review = (await api.console.shop.reviews.put(review))?.review;
                this.updateItem(review);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(review) {
            const index = this.reviews.findIndex(({ _id }) => _id == review._id);
            if (0 <= index) this.reviews.splice(index, 1, review);
            else {
                this.reviews = [review, ...this.reviews];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
