<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center" class="ma-n2">
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[0]" type="date" label="지급/사용일(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <v-text-field v-model="query.createdAt[1]" type="date" label="지급/사용일(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <autocomplete-user v-model="query['_user']" label="회원" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="" class="pa-2">
                <autocomplete-shop-coupon v-model="query['_shopCoupon']" placeholder="전체" label="쿠폰정책" clearable v-bind="attrs_input" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";
import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";
import AutocompleteShopCoupon from "@/components/dumb/autocomplete-shop-coupon.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["_user"]: query?.["_user"] ?? null,
    ["_shopCoupon"]: query?.["_shopCoupon"] ?? null,
});

export default {
    components: {
        ListSearch,
        AutocompleteUser,
        AutocompleteShopCoupon,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query["createdAt"]) delete query["createdAt"];
            if (!query["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query["createdAt"];

            if (!query["_user"]) delete query["_user"];
            if (!query["_shopCoupon"]) delete query["_shopCoupon"];

            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
