<template>
    <v-layout justify-center fill-height>
        <v-responsive max-width="1160" width="100%" height="100%">
            <list-heading :title="isCreate ? '제품 등록' : '제품 상세'" />

            <view-section-card title="카테고리" v-bind="{ loading }">
                <v-card-text>
                    <shop-category-select v-model="product._category" v-bind="{ ...attrs_input, categories }" />
                </v-card-text>
            </view-section-card>

            <v-row class="mx-n2 my-2">
                <v-col cols="12" md="3" class="pa-2">
                    <view-section-card title="썸네일" v-bind="{ loading }">
                        <v-card-text>
                            <image-input v-model="product.thumb" :aspect-ratio="1 / 1" flat />
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="9" class="pa-2">
                    <view-section-card title="제품 이미지" v-bind="{ loading }" class="fill-height">
                        <v-card-text>
                            <images-input v-model="product.images" :md="2" />
                        </v-card-text>
                    </view-section-card>
                </v-col>

                <v-col cols="12" md="7" class="pa-2">
                    <view-section-card title="제품정보" v-bind="{ loading }">
                        <v-card-text>
                            <v-row class="mx-n2">
                                <v-col cols="12" md="3" class="pa-2">
                                    <v-select v-model="product.type" label="제품유형" :items="productTypes" v-bind="attrs_input" :disabled="!isCreate" @input="emit" />
                                </v-col>
                                <v-col cols="12" md="6" class="pa-2">
                                    <v-text-field v-model="product.name" label="제품명" v-bind="attrs_input" @input="emit" />
                                </v-col>
                                <v-col cols="12" md="3" class="pa-2">
                                    <v-text-field v-model="product.sequence" label="진열순서" v-bind="attrs_input" @input="emit" />
                                </v-col>

                                <v-col cols="12"> <v-divider /> </v-col>

                                <v-col cols="12" md="6" class="pa-2">
                                    <v-text-field v-model="product.code" label="제품코드" v-bind="attrs_input" @input="emit" />
                                </v-col>

                                <v-col cols="12" md="6" class="pa-2">
                                    <v-text-field v-model="product.relatedGroupCode" label="연관제품코드" v-bind="attrs_input" clearable @input="emit" />
                                </v-col>

                                <v-col cols="12" md="6" class="pa-0">
                                    <v-currency-field v-model="product.price" label="판매가 (0원 일때 '무료'로 문구변경)" suffix="원" class="pa-2" v-bind="attrs_input" @input="emit" />
                                </v-col>

                                <v-col cols="12" md="6" class="pa-2">
                                    <v-select v-model="product._brand" label="브랜드" :items="brands" item-text="name" item-value="_id" clearable v-bind="attrs_input" @input="emit" />
                                </v-col>
                                <v-col cols="12"> <v-divider /> </v-col>
                                <v-col cols="12" md="12" class="pa-2">
                                    <v-combobox v-model="product.flags" label="플래그" multiple :dense="false" append-icon="" v-bind="attrs_input" @input="emit">
                                        <template #selection="{ attrs, item, parent }">
                                            <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                                <v-col cols="12" md="12" class="pa-2">
                                    <v-combobox v-model="product.hashtags" label="해시태그(검색어)" multiple :dense="false" append-icon="" v-bind="attrs_input" @input="emit">
                                        <template #selection="{ attrs, item, parent }">
                                            <v-chip v-bind="attrs" outlined text label small close @click:close="parent.selectItem(item)"> {{ item }} </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </view-section-card>
                </v-col>
                <v-col cols="12" md="5" class="pa-2">
                    <v-row class="ma-n2">
                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <v-row no-gutters>
                                    <v-col cols="12" md="6">
                                        <view-section-title title="전시여부">
                                            <template #actions> <v-switch v-model="product.shows" v-bind="attrs_switch" @input="emit" /> </template>
                                        </view-section-title>
                                        <v-divider style="margin-bottom: -1px" />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <view-section-title title="판매여부">
                                            <template #actions> <v-switch v-model="product.sells" v-bind="attrs_switch" @input="emit" /> </template>
                                        </view-section-title>
                                        <v-divider style="margin-bottom: -1px" />
                                    </v-col>
                                </v-row>
                            </view-section-card>
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <v-row no-gutters>
                                    <template v-for="item in labels">
                                        <v-col :key="`${item.value}-item`" cols="12" md="6">
                                            <view-section-title :title="item.text">
                                                <template #actions> <shop-product-labels v-model="product.labels" :code="item.value" v-bind="attrs_switch" @input="emit" /> </template>
                                            </view-section-title>
                                            <v-divider style="margin-bottom: -1px" />
                                        </v-col>
                                    </template>
                                </v-row>
                            </view-section-card>
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <view-section-card hideTitle hideDivider v-bind="{ loading }">
                                <v-row no-gutters>
                                    <template v-for="item in [displayCodes[0]]">
                                        <v-col :key="`${item.value}-item`" cols="12" md="6">
                                            <view-section-title :title="item.text">
                                                <template #actions> <shop-product-groups v-model="product.groups" :code="item.value" v-bind="attrs_switch" @input="emit" /> </template>
                                            </view-section-title>
                                            <v-divider style="margin-bottom: -1px" />
                                        </v-col>
                                    </template>
                                </v-row>
                            </view-section-card>
                        </v-col>

                        <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="product.type != PRODUCT_TYPES.VARIETY_BUNDLE.value">
                                <!-- 재고사용 -->
                                <product-form-stock v-model="product" class="ma-2" v-bind="{ loading }" @input="emit" />
                            </v-col>
                        </v-expand-transition>

                        <v-col cols="12" class="pa-2">
                            <!-- 할인사용 -->
                            <shop-product-discount v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" class="pa-2">
                    <v-row class="ma-n2">
                        <!-- 제품옵션 -->
                        <!-- <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="product.type == PRODUCT_TYPES.NORMAL_PRODUCT.value">
                                <div class="pa-2">
                                    <shop-product-options v-model="product" v-bind="{ loading }" @input="emit" />
                                </div>
                            </v-col>
                        </v-expand-transition> -->

                        <!-- 묶음제품 -->
                        <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="product.type != PRODUCT_TYPES.PRODUCT_BUNDLE.value">
                                <div class="pa-2">
                                    <shop-product-variation v-model="product" v-bind="{ loading }" @input="emit" />
                                </div>
                            </v-col>
                        </v-expand-transition>

                        <!-- 세트제품 -->
                        <v-expand-transition>
                            <v-col cols="12" class="pa-0" v-show="product.type == PRODUCT_TYPES.PRODUCT_BUNDLE.value">
                                <div class="pa-2">
                                    <shop-product-bundleds v-model="product" v-bind="{ loading }" @input="emit" />
                                </div>
                            </v-col>
                        </v-expand-transition>

                        <!-- 추가제품 -->
                        <!-- <v-col cols="12" class="pa-2">
                            <shop-product-supplies v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col> -->

                        <!-- 추가제품(옵션) -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-relateds v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col>

                        <!-- 추가제품(카드) -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-addons v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col>

                        <!-- 검색엔진최적화(SEO) -->
                        <v-col cols="12" class="pa-2">
                            <shop-product-seo v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col>

                        <!-- 적용된 배송정책 -->
                        <v-col cols="12" class="pa-2" v-if="!isCreate">
                            <shop-product-shipping v-model="product" v-bind="{ loading }" @input="emit" />
                        </v-col>

                        <v-col cols="12" class="pa-2">
                            <view-section-card title="제품 상세설명" v-bind="{ loading }">
                                <naver-smarteditor v-if="!loading" v-model="product.content" rows="10" class="mb-n2" @input="emit" />
                            </view-section-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-btn fixed right bottom fab color="white" elevation="1" class="mb-16" v-bind="{ loading }" :href="`/shop/products/${product?._id}`">
                <v-icon>mdi-link-variant</v-icon>
            </v-btn>
            <v-btn fixed right bottom fab color="primary" elevation="1" v-bind="{ loading }" @click="save">
                <v-icon>mdi-content-save</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mapGetters } from "vuex";
import { attrs_input, attrs_switch, DISPLAY_CODES, initProduct, PRODUCT_TYPES } from "@/assets/variables";

import Draggable from "vuedraggable";

import ImageInput from "@/components/console/dumb/image-input.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

import ShopProductSeo from "@/components/console/shop/products/shop-product-seo.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ShopProductBonus from "@/components/console/shop/products/shop-product-bonus.vue";
import ProductFormStock from "@/components/console/shop/products/form/product-form-stock.vue";
import ShopProductGroups from "@/components/console/shop/products/shop-product-groups.vue";
import ShopProductLabels from "@/components/console/shop/products/shop-product-labels.vue";
import ShopProductAddons from "@/components/console/shop/products/add-ons/shop-product-addons.vue";
import ShopProductOptions from "@/components/console/shop/products/options/shop-product-options.vue";
import ShopCategorySelect from "@/components/console/shop/categories/shop-category-select.vue";
import ShopProductDiscount from "@/components/console/shop/products/shop-product-discount.vue";
import ShopProductBundleds from "@/components/console/shop/products/bundleds/shop-product-bundleds.vue";
import ShopProductSupplies from "@/components/console/shop/products/shop-product-supplies.vue";
import ShopProductRelateds from "@/components/console/shop/products/relateds/shop-product-relateds.vue";
import ShopProductShipping from "@/components/console/shop/products/shop-product-shipping.vue";
import ShopProductVariation from "@/components/console/shop/products/variation/shop-product-variation.vue";

const productTypes = Object.values(PRODUCT_TYPES);
const labels = [
    { value: "new", text: "New" },
    { value: "digital", text: "Digital" },
];

export default {
    components: {
        Draggable,

        ImageInput,
        ImagesInput,
        ListHeading,
        ViewSectionCard,
        ViewSectionTitle,

        ShopProductSeo,
        NaverSmarteditor,
        ShopProductBonus,
        ProductFormStock,
        ShopProductGroups,
        ShopProductLabels,
        ShopProductAddons,
        ShopProductOptions,
        ShopCategorySelect,
        ShopProductDiscount,
        ShopProductBundleds,
        ShopProductSupplies,
        ShopProductRelateds,
        ShopProductShipping,
        ShopProductVariation,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        product: initProduct(),

        brands: [],
        categories: [],

        labels,
        productTypes,
        PRODUCT_TYPES,
        DISPLAY_CODES,

        attrs_input,
        attrs_switch,

        loading: false,
    }),
    computed: {
        ...mapGetters(["displayCodes"]),
        isCreate() {
            return !this._product;
        },
        isBundle() {
            return [PRODUCT_TYPES.PRODUCT_BUNDLE.value].includes(this.product?.type);
        },
        isCopy() {
            return this.$route.query.mode == "copy";
        },
        optionsStock() {
            var stock = 0;
            for (var i in this.product.options) {
                stock += this.product.options[i].stock;
            }
            return stock;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
        optionsStock() {
            if (this.product.optionsEnabled) {
                this.product.stock = this.optionsStock;
            }
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
                this.categories = categories;

                var { brands } = await api.console.shop.brands.gets();
                this.brands = brands;

                if (!this.isCreate) {
                    var { product } = await api.console.shop.products.get({ _id: this._product });

                    if (this.isCopy) {
                        product._id = undefined;

                        for (var i in product.options) {
                            delete product.options[i]._id;
                        }
                        for (var i in this.product.supplies) {
                            delete product.supplies[i]._id;
                        }
                    }

                    this.product = product;
                }

                if (this.isCreate || this.isCopy) {
                    var { summary } = await api.console.shop.products.gets({ headers: { limit: 1 } });
                    this.product.sequence = summary.totalCount;
                }
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.product.optionsEnabled = 0 < (this.product.options || []).length;
                this.product.suppliesEnabled = 0 < (this.product.supplies || []).length;

                let { _thumb, thumb, _images = [], images = [], _options = [], options = [], _relateds = [], relateds = [], _bundleds = [], bundleds = [], variation, optionsConf, ...form } = this.product;

                ////////////////////////////////////////////////////////////////
                // 옵션정보 이미지 저장
                ////////////////////////////////////////////////////////////////
                optionsConf.criteria = await Promise.all(
                    optionsConf.criteria.map(async (item) => ({
                        ...item,
                        values: await Promise.all(
                            item.values.map(async (value) => {
                                if (value.img instanceof File) {
                                    value.img = (await api.console.files.post({ path: "shop-colors" }, value.img))?.file;
                                }

                                value._img = value?.img?._id;
                                if (!value._img) delete value._img;

                                delete value.img;
                                return value;
                            })
                        ),
                    }))
                );
                const map__optionColors = new Map(optionsConf.criteria.flatMap(({ values }) => values.map((color) => [color.tempId, color])));

                ////////////////////////////////////////////////////////////////
                // 제품 저장
                ////////////////////////////////////////////////////////////////
                const { post, put } = api.console.shop.products;
                let { product } = await (this.isCreate || this.isCopy ? post : put)({ ...form, optionsConf });

                ////////////////////////////////////////////////////////////////
                // 옵션제품 저장
                ////////////////////////////////////////////////////////////////
                const { post: postOption, put: putOption } = api.console.shop.products.options;
                for (var i in options) {
                    let form = options[i];
                    form._product = product?._id;
                    form.color = map__optionColors.get(form.tempId__color);

                    options[i] = (await (form._id ? putOption : postOption)(form))?.option;
                }
                _options = options.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 추가제품 저장
                ////////////////////////////////////////////////////////////////
                for (var i in this.product.supplies) {
                    var supply = this.product.supplies[i];

                    if (supply.deleted) {
                        await api.console.shop.products.supplies.deleteSupply(product._id, supply);
                    } else if (supply._id) {
                        await api.console.shop.products.supplies.putSupply(product._id, supply);
                    } else if (!supply._id) {
                        await api.console.shop.products.supplies.postSupply(product._id, supply);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 추가제품(옵션) 저장
                ////////////////////////////////////////////////////////////////
                const { post: postRelated, put: putRelated } = api.console.shop.products.relateds;
                for (var i in relateds) {
                    let form = relateds[i];

                    delete form.product;
                    delete form.option;
                    delete form.supply;

                    relateds[i] = (await (form._id ? putRelated : postRelated)(form))?.related;
                }
                _relateds = relateds.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 묶음제품 저장
                ////////////////////////////////////////////////////////////////
                if (form.type == PRODUCT_TYPES.VARIETY_BUNDLE.value) {
                    variation.code = form.code;

                    for (const product of variation.list) {
                        const { _id, variation } = product;
                        variation.code = form.code;
                        await put({ _id, variation });
                    }
                }
                if (form.type == PRODUCT_TYPES.NORMAL_PRODUCT.value) {
                    variation.code = variation.head?.code || null;
                }

                ////////////////////////////////////////////////////////////////
                // 세트제품 저장
                ////////////////////////////////////////////////////////////////
                const { post: postBundled, put: putBundled } = api.console.shop.products.bundleds;
                for (var i in bundleds) {
                    let form = bundleds[i];

                    delete form.product;
                    delete form.option;
                    delete form.supply;

                    bundleds[i] = (await (form._id ? putBundled : postBundled)(form))?.bundled;
                }
                _bundleds = bundleds.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 썸네일 저장 / 삭제(X)
                ////////////////////////////////////////////////////////////////
                // 기존 구매내역 썸네일 유지를 위해 삭제 미진행
                ////////////////////////////////////////////////////////////////
                if (thumb instanceof File) thumb = (await api.console.files.post({ _product: product._id, index: "thumb" }, thumb))?.file;
                // if (_thumb && _thumb != thumb?._id) await api.console.files.delete({ _id: _thumb });
                _thumb = thumb?._id;

                ////////////////////////////////////////////////////////////////
                // 이미지 저장 / 삭제
                ////////////////////////////////////////////////////////////////
                for (const i in images) {
                    if (images[i] instanceof File) images[i] = (await api.console.files.post({ _product: product._id, index: `image-${i}` }, images[i]))?.file;
                }
                for (const i in _images) {
                    if (_images[i] && !images.some(({ _id }) => _id == _images[i])) await api.console.files.delete({ _id: _images[i] });
                }
                _images = images.map(({ _id }) => _id);

                ////////////////////////////////////////////////////////////////
                // 제품 업데이트
                ////////////////////////////////////////////////////////////////
                product = await put({ _id: product._id, _thumb, _images, _options, _relateds, _bundleds, variation });

                alert(this.isCreate || this.isCopy ? "등록 되었습니다" : "수정 되었습니다");
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.product = initProduct(this.product);
        },
    },
};
</script>
