<template>
    <view-section-card title="발급">
        <template #actions> <v-switch v-model="form.issue.isActive" v-bind="attrs_switch" @change="emit" /> </template>
        <v-card :disabled="!form.issue.isActive" flat>
            <v-card-text>
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2">
                        <form-issue-dates v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-2">
                        <v-select v-model="form.issue.type" label="발급형태" :items="types" v-bind="attrs_input" @input="emit" />
                    </v-col>
                    <!-- <v-col cols="12" md="6" class="pa-2">
                        <v-text-field v-model="form.issue.issuesAt" label="발급일자(특정)" clearable :disabled="disabled_issuesAt" v-bind="attrs_input__date" @input="emit" />
                    </v-col> -->
                    <v-col cols="12" md="6" class="pa-2">
                        <form-issue-event v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <form-issue-round v-model="form" @input="emit" />
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <form-issue-target v-model="form" @input="emit" />
                    </v-col>
                    <!-- <v-col cols="12" md="12" class="pa-2">
                        <v-row class="mx-n4"> <v-divider /> </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <form-issue-limit v-model="form" @input="emit" />
                    </v-col> -->
                </v-row>
            </v-card-text>
        </v-card>
    </view-section-card>
</template>

<script>
import { attrs_input, attrs_input__date, attrs_switch, initShopPoint } from "@/assets/variables";
import { ISSUE_EVENT_TYPES, ISSUE_TYPES } from "@/assets/variables/constants";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

import FormIssueEvent from "./form-issue-event.vue";
import FormIssueDates from "./form-issue-dates.vue";
import FormIssueRound from "./form-issue-round.vue";
import FormIssueLimit from "./form-issue-limit.vue";
import FormIssueTarget from "./form-issue-target.vue";

const types = [ISSUE_TYPES.INSTANT];

export default {
    components: {
        ViewSectionCard,

        FormIssueEvent,
        FormIssueDates,
        FormIssueRound,
        FormIssueLimit,
        FormIssueTarget,
    },
    props: {
        value: { type: Object, default: initShopPoint },
    },
    data: () => ({
        form: initShopPoint(),

        types,

        attrs_input,
        attrs_input__date,

        attrs_switch,
    }),
    computed: {
        disabled_issuesAt() {
            // 발급형태 발급시점이 상점기념일이 아닐 경우 비활성화
            const eventTypeMatches = this.form.issue.event.type == ISSUE_EVENT_TYPES.SHOP_SPECIFIC_DAY.value;
            if (!eventTypeMatches) return true;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        disabled_issuesAt() {
            if (this.disabled_issuesAt) {
                this.form.issue.issuesAt = null;
                this.emit();
            }
        },
    },
    methods: {
        sync() {
            this.form = initShopPoint(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
