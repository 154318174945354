<template>
    <view-section-card title="추가제품(카드)" hideDivider v-bind="{ loading }">
        <template #actions> <v-switch v-model="form.addOnsConf.enabled" v-bind="attrs_switch" @change="emit"></v-switch> </template>
        <v-expand-transition>
            <v-sheet v-show="form.addOnsConf.enabled">
                <v-divider />
                <autocomplete-product v-model="form._addOns" multiple v-bind="attrs_autocomplete" @input="emit">
                    <template #item="{ item }">
                        <item-product :product="item" />
                    </template>
                    <template #selection="{ item }">
                        <item-product :product="item" />
                    </template>
                </autocomplete-product>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import { attrs_input__verticalTable, attrs_switch, initProduct } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import ItemProduct from "@/components/console/dumb/item-product.vue";

export default {
    components: {
        ViewSectionCard,
        AutocompleteProduct,
        ItemProduct,
    },
    props: {
        value: { type: Object, default: initProduct },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initProduct(),

        attrs_switch,
    }),
    computed: {
        attrs_autocomplete() {
            const attrs = {
                ...attrs_input__verticalTable,

                multiple: true,
            };

            if (this.value?._id) {
                attrs.params = {
                    _id: { $ne: this.value?._id },
                };
            }

            attrs.params = {
                ...(attrs.params || {}),
                shows: true,
            };

            return attrs;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async sync() {
            this.form = initProduct(this.value);
        },

        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-relateds-table ::v-deep {
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        display: block;
        height: 100%;
    }
}
</style>
