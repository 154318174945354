var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "회원 영업담당자 설정"
    }
  }), _c('v-row', {
    staticClass: "mt-n3"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('user-manager-upload', _vm._g({
    staticClass: "caption",
    attrs: {
      "color": "white green--text"
    },
    model: {
      value: _vm.loading,
      callback: function ($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, {
    search: _vm.search
  }), [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-cloud-upload")]), _vm._v(" 파일 업로드 ")], 1)], 1)], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "caption",
    attrs: {
      "color": "white green--text"
    },
    on: {
      "click": _vm.excel
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-layout', {
    attrs: {
      "align-center": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-cloud-download")]), _vm._v(" 파일 다운로드 ")], 1)], 1)], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "headers": _vm.headers,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            attrs: {
              "title": header.withTitle ? header.formatter.bind(item)(value, item) : undefined
            },
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value, item))
            }
          })];
        }
      };
    }), {
      key: `item.managers`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value,
          header = _ref2.header;
        return [_c('item-manager-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_c('v-card', {
          staticClass: "px-4 py-3 caption text-truncate",
          attrs: {
            "flat": "",
            "tile": "",
            "width": "100%",
            "color": "transparent",
            "title": header.formatter(value)
          }
        }, [_vm._v(" " + _vm._s(header.formatter(value).replaceAll("\r\n", " / ")) + " ")])], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "tile": "",
            "to": `${_vm.$route.path}/${item._id}`
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-pencil ")])], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }