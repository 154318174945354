var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "outlined": "",
            "color": "primary"
          }
        }, 'v-btn', Object.assign({}, attrs, {
          loading: _vm.loading
        }), false), on), [_vm._v("교환요청")])];
      }
    }]),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("교환요청")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "사유",
      "items": ['단순 변심', '제품 불량', '기타']
    },
    model: {
      value: _vm.form.claimReason,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReason", $$v);
      },
      expression: "form.claimReason"
    }
  }, 'v-select', _vm.attrs_input, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    attrs: {
      "label": "상세"
    },
    model: {
      value: _vm.form.claimReasonDetails,
      callback: function ($$v) {
        _vm.$set(_vm.form, "claimReasonDetails", $$v);
      },
      expression: "form.claimReasonDetails"
    }
  }, 'v-textarea', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.put
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }