<template>
    <v-dialog v-model="dialog" width="480" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5">
                <v-row class="ma-n2">
                    <v-col cols="12" sm="6" class="pa-2"> <v-select v-model="form.group" label="분류" :items="groups" v-bind="{ ...attrs_input, loading }" :disabled="!!group" /> </v-col>
                    <v-col cols="12" sm="6" class="pa-2"> <v-text-field v-model="form.index" type="number" label="#" v-bind="{ ...attrs_input, loading }" :disabled="isCreate" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-autogrow-field v-model="form.query" label="질문" v-bind="{ ...attrs_input, loading }" /> </v-col>
                    <v-col cols="12" class="pa-2"> <v-autogrow-field v-model="form.reply" label="답변" v-bind="{ ...attrs_input, loading }" /> </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.isEnabled" dense v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.isEnabled ? "활성화" : "비활성화" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input, attrs_switch, initCenterPrompt } from "@/assets/variables";

import VAutogrowField from "@/components/plugins/vuetify/v-autogrow-field.vue";

export default {
    components: {
        VAutogrowField,
    },
    props: {
        _id: { type: String, default: null },
        group: { type: String, default: null },
    },
    data: () => ({
        form: initCenterPrompt(),

        dialog: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        ...mapState({ groups: (state) => state?.setting?.promptGroups || [] }),
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "프롬프트";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    const { group } = this;
                    this.form = initCenterPrompt({ group });
                } else {
                    let { _id } = this;
                    this.form = (await api.console.center.prompts.get({ _id }))?.prompt;
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        validates() {
            let { group, query, reply } = this.form;
            if (!group) throw new Error("분류를 선택해주세요");
            if (!query) throw new Error("질문을 작성해주세요");
            if (!reply) throw new Error("답변을 등록해주세요");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.center.prompts;
                let { ...form } = this.form;
                let prompt = (await (this.isCreate ? post : put)(form))?.prompt;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", prompt);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
