var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-edit-dialog', _vm._g({
    attrs: {
      "return-value": _vm.item._managers,
      "large": "",
      "cancel-text": "취소",
      "save-text": "저장"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.item, "_managers", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.item, "_managers", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "input",
      fn: function () {
        return [_c('v-sheet', [_c('autocomplete-user', _vm._b({
          staticClass: "mt-3",
          attrs: {
            "params": {
              scope: 'manager'
            },
            "itemText": function (_ref) {
              var username = _ref.username;
              return username;
            },
            "multiple": ""
          },
          model: {
            value: _vm.item._managers,
            callback: function ($$v) {
              _vm.$set(_vm.item, "_managers", $$v);
            },
            expression: "item._managers"
          }
        }, 'autocomplete-user', _vm.attrs_input, false))], 1)];
      },
      proxy: true
    }])
  }, {
    save: _vm.save
  }), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }