var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs', _vm._b({}, 'v-tabs', _vm.$attrs, false), [_vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
      value = _ref.value,
      to = _ref.to;
    return [_c('v-tab', _vm._b({
      key: value,
      attrs: {
        "exact": ""
      }
    }, 'v-tab', {
      value,
      to
    }, false), [_vm._v(_vm._s(text))])];
  }), _c('v-spacer'), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('prompt-list-groups-form', {
    on: {
      "input": _vm.init
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "fab": "",
            "tile": "",
            "width": "48",
            "height": "48"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "dense": ""
          }
        }, [_vm._v("mdi-cog")])], 1)];
      }
    }]),
    model: {
      value: _vm.setting,
      callback: function ($$v) {
        _vm.setting = $$v;
      },
      expression: "setting"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }