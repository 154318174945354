<template>
    <v-layout justify-center>
        <v-responsive width="100%" class="px-3 mx-n3">
            <list-heading title="구매확정 관리" showsFilterButton v-model="showsSearch" />
            <purchase-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined mt-3">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.numbers`]="{ item }">
                    <div>
                        <order-view :_order="item._order">
                            <template #activator="{ attrs, on }">
                                <v-btn x-small text tile class="pa-0 caption" v-bind="attrs" v-on="on">{{ item.order?.orderNo }}</v-btn>
                            </template>
                        </order-view>
                    </div>
                    <div>{{ item.purchaseNo }}</div>
                </template>
                <template #[`item.order.sender`]="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.isWarning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template #[`item.order.receiver`]="{ item }">
                    <div>{{ item?.order?.receiver?.title }}</div>
                    <div>{{ item?.order?.receiver?.name }}</div>
                    <div>{{ item?.order?.receiver?.phone }}</div>
                    <div>{{ item?.order?.receiver?.email }}</div>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";
import { decode__productOptionName } from "@/plugins/vue-methods-shop";

import OrderView from "@/components/console/shop/purchases/order-view/order-view.vue";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CancelListFilter from "@/components/console/shop/purchases/cancel-list-filter.vue";
import PurchaseListSearch from "@/components/console/shop/purchases/purchase-list-search.vue";

const headers = [
    { width: 190, divider: true, text: "주문번호\n제품주문번호", value: "numbers", class: "white-space-pre-line" },
    { width: 100, divider: true, text: "주문일자", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 110, divider: true, text: "구매확정상태", value: "purchaseStatusMessage" },
    { width: 100, divider: true, text: "주문상태", value: "orderStatusMessage" },
    { width: 100, divider: true, text: "클레임상태", value: "claimStatusMessage" },
    { width: 100, divider: true, text: "클레임 사유", value: "claimReason" },
    { width: 200, divider: true, text: "클레임 상세사유", value: "claimReasonDetails" },
    { width: 120, divider: true, text: "연락처", value: "order.sender.phone" },
    { width: 120, divider: true, text: "제품코드", value: "code" },
    { width: 200, divider: true, text: "구매제품", value: "product.name" },
    { width: 200, divider: true, text: "옵션정보", value: "name", formatter: decode__productOptionName },
    { width: +90, divider: true, text: "구매수량", value: "amount" },
    { width: 120, divider: true, text: "구매자", value: "order.sender" },
    { width: 120, divider: true, text: "수취인", value: "order.receiver" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption" }));

export default {
    components: {
        OrderView,

        ListHeading,
        CancelListFilter,
        PurchaseListSearch,
    },
    data: () => ({
        purchases: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,
        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.purchases];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.purchaseStatus = query?.purchaseStatus || [PURCHASE_PURCHASE_STATUSES.PURCHASE_COMPLETE.value];

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.console.shop.purchases.getPurchases({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
