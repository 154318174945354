<template>
    <v-btn v-bind="{ ...attrs, loading }" @click="setOrderStatusCancel">{{ title }}</v-btn>
</template>

<script>
import api from "@/api";
import { PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

export default {
    props: {
        title: { type: String, default: "주문취소" },
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
        confirms: { type: Function, default: () => confirm("해당 주문취소를 처리하시겠습니까?\r\n주문취소 이후 복구는 불가능합니다") },
    },
    computed: {
        attrs() {
            if (0 < Object.keys(this.$attrs).length) return this.$attrs;
            else return { small: true, outlined: true, color: "primary", class: "mr-2" };
        },
    },
    methods: {
        async setOrderStatusCancel() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.confirms()) throw new Error(`${this.title} 처리를 취소하셨습니다`);
                if (!this.selected.length) throw new Error(`${this.title} 처리할 구매건을 선택해주세요`);
            } catch (error) {
                alert(error?.message);
                this.$emit("loading", false);
                return;
            }

            try {
                for (const { _id } of this.selected) {
                    await api.console.shop.purchases.putPurchase({ _id, orderStatus: PURCHASE_ORDER_STATUSES.ORDER_CANCELED.value, claimStatus: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, purchaseStatus: PURCHASE_PURCHASE_STATUSES.PURCHASE_CANCELED.value, rejectedAt: null, canceledAt: Date.now(), returnedAt: null, exchangedAt: null, exchangeShippedAt: null });
                }
                const _orders = [...new Set(this.selected.map(({ _order }) => _order))];
                for (const _id of _orders) {
                    await api.console.shop.orders.patchState({ _id });
                }

                alert(`${this.title} 처리 되었습니다`);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
