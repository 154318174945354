<template>
    <v-card outlined class="view-field-card" v-bind="$attrs">
        <v-card-text style="position: relative; padding: 7px 11px" v-bind="attrs_wrapper">
            <span class="d-inline-block white caption px-1" style="position: absolute; top: 0; left: 8px; transform: translateY(-50%); z-index: 1">{{ label }}</span>
            <slot />
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "라벨" },
        attrs_wrapper: { type: Object, default: () => ({}) },
    },
};
</script>

<style lang="scss" scoped>
.view-field-card {
    border-color: rgba(0, 0, 0, 0.38) !important;
}
</style>
