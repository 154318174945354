var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "720"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', _vm._b({}, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-6"
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('view-field-card', {
    attrs: {
      "label": "썸네일",
      "attrs_wrapper": {
        class: 'pa-0'
      }
    }
  }, [_c('image-input', {
    attrs: {
      "accept": "image/*",
      "aspect-ratio": 1 / 1,
      "contain": "",
      "flat": ""
    },
    model: {
      value: _vm.form.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.form, "thumb", $$v);
      },
      expression: "form.thumb"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    staticClass: "ma-n2"
  }, [_vm.code == 'catalogue' ? [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('shop-category-select', _vm._b({
    attrs: {
      "label": "카테고리"
    },
    model: {
      value: _vm.form._category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_category", $$v);
      },
      expression: "form._category"
    }
  }, 'shop-category-select', Object.assign({}, _vm.attrs_input, {
    categories: _vm.categories
  }), false))], 1)] : _vm._e(), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', _vm._b({
    attrs: {
      "label": "파일"
    },
    model: {
      value: _vm.form.file,
      callback: function ($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  }, 'v-file-input', _vm.attrs_input, false))], 1)], 2)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', _vm._b({
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }