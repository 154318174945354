<template>
    <v-layout justify-center>
        <v-responsive max-width="960" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="기획전 목록">
                <template #add-button>
                    <exhibition-form v-bind="{ group }" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small fab tile color="transparent primary--text" class="my-n1" v-bind="attrs" v-on="on"> <v-icon>mdi-plus</v-icon> </v-btn>
                        </template>
                    </exhibition-form>
                </template>
                <template #buttons> <exhibition-group-toggle /> </template>
            </list-heading>

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table">
                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.title`]="{ item }">
                    <v-list-item>
                        <v-list-item-content>
                            <v-chip-group>
                                <v-chip v-if="item.label" small>
                                    {{ item.label }}
                                </v-chip>
                            </v-chip-group>
                            <v-list-item-subtitle v-if="item.subtitle">
                                {{ item.subtitle }}
                            </v-list-item-subtitle>
                            <v-list-item-title v-if="item.title">
                                {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-content v-if="item.summary">
                                {{ item.summary }}
                            </v-list-item-content>
                            <v-list-item-action-text v-if="item.duration?.[0] || item.duration?.[1]">
                                <span> {{ item.duration?.[0]?.toDate?.() || "" }} </span>
                                <span> ~ </span>
                                <span> {{ item.duration?.[1]?.toDate?.() || "" }} </span>
                            </v-list-item-action-text>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template #[`item.thumb`]="{ value }"> <image-popup size="40" :src="value?.url" /> </template>
                <template #[`item.photo`]="{ value }"> <image-popup size="40" :src="value?.url" /> </template>
                <template #[`item.shows`]="{ item }">
                    <v-switch v-model="item.shows" v-bind="attrs_switch" @change="(shows) => update({ _id: item._id, shows })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <exhibition-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </exhibition-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch, EXHIBITION_GROUPS } from "@/assets/variables";

import Draggable from "vuedraggable";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import ExhibitionForm from "@/components/console/shop/exhibitions/exhibition-form.vue";
import ExhibitionGroupToggle from "@/components/console/shop/exhibitions/exhibition-group-toggle.vue";

const headers = [
    { divider: true, width: +80, text: "#", value: "index" },
    { divider: true, width: 350, text: "제목", value: "title", cellClass: "pa-0" },
    { divider: true, width: +80, text: "썸네일", value: "thumb", align: "center" },
    { divider: true, width: +80, text: "이미지", value: "photo", align: "center" },
    { divider: true, width: +80, text: "표시", value: "shows", align: "center" },
    { divider: true, width: 170, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDateTime?.(), divider: true },
    { width: +88, text: "", value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

const groups = Object.values(EXHIBITION_GROUPS);

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,
        ImagePopup,
        ListHeading,
        ExhibitionForm,
        ExhibitionGroupToggle,
    },
    data() {
        return {
            exhibitions: [],

            limit: 20,
            summary: { totalCount: 0 },

            headers,
            attrs_switch,
            loading: false,
        };
    },
    computed: {
        items() {
            return [...this.exhibitions];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        group() {
            return this.$route.query.group || groups[0].value;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, exhibitions } = await api.console.shop.exhibitions.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.exhibitions = exhibitions.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(exhibition) {
            try {
                exhibition = (await api.console.shop.exhibitions.put(exhibition))?.exhibition;
                this.updateItem(exhibition);
            } catch (error) {
                this.$handleError(error);
            }
        },

        async remove(exhibition) {
            const go = confirm(`"${exhibition?.name}" 제품을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ exhibition }] = [await api.console.shop.exhibitions.delete(exhibition)];
                    this.exhibitions = this.exhibitions.filter(({ _id }) => _id !== exhibition._id);
                    alert(`"${exhibition?.name}" 제품이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(exhibition) {
            const index = this.exhibitions.findIndex(({ _id }) => _id == exhibition._id);
            if (0 <= index) this.exhibitions.splice(index, 1, exhibition);
            else {
                this.exhibitions = [exhibition, ...this.exhibitions];
                this.summary.totalCount += 1;
            }
            this.search();
        },
        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let {
                    element: { _id, index },
                    oldIndex,
                    newIndex,
                } = moved;
                console.log({ index, oldIndex, newIndex });
                index += oldIndex - newIndex;
                console.log({ index });
                let form = { _id, index };
                let { exhibition } = await api.console.shop.exhibitions.put(form);
                this.updateItem(exhibition);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
