<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" class="px-3 mx-n3">
            <list-heading title="상담예약 목록" showsFilterButton v-model="showsSearch" />
            <form-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined" style="overflow: hidden">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.state`]="{ item, value }">
                    <form-state-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-2 my-n2">
                            {{ FORM_STATES[value]?.text || "-" }}
                        </v-card>
                    </form-state-edit>
                </template>

                <template #[`item.memo`]="{ item, value }">
                    <form-memo-edit :value="item" v-on="{ update }">
                        <v-card flat tile color="transparent" class="px-4 py-2 my-n2 fill-height"> {{ value || "-" }} </v-card>
                    </form-memo-edit>
                </template>

                <template #expanded-item="{ item, headers }">
                    <td />
                    <td :colspan="headers.length - 1" class="px-0">
                        <v-sheet class="fill-height">
                            <v-card-subtitle class="caption">문의내용</v-card-subtitle>
                            <v-card-text class="white-space-pre-line">
                                {{ item.content }}
                            </v-card-text>
                        </v-sheet>
                    </td>
                </template>

                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
                </template>
            </v-data-table>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { FORM_STATES, initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import FormMemoEdit from "@/components/console/forms/form-memo-edit.vue";
import FormStateEdit from "@/components/console/forms/form-state-edit.vue";
import FormListSearch from "@/components/console/forms/form-list-search.vue";

const code = "inquiry-call";

const headers = initDataTableHeaders([
    { width: 160, text: "상담유형", value: "category" },
    { width: 240, text: "작성자 / 연락처", value: "meta", formatter: ({ name, phone } = {}) => [name, phone?.phoneNumberFormat?.() || "-"].filter((item) => item).join(" / ") },
    { width: 440, text: "문의사항", value: "content" },
    { width: 320, text: "메모", value: "memo", cellClass: "px-0" },
    { width: +90, text: "처리상태", value: "state", cellClass: "px-0" },
    { width: 160, text: "요청일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
]);

export default {
    components: {
        ListHeading,
        FormMemoEdit,
        FormStateEdit,
        FormListSearch,
    },
    data: () => ({
        forms: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
        FORM_STATES,
    }),
    computed: {
        items() {
            return [...this.forms];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.code = code;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.forms = [];
            this.summary = { totalCount: 0 };

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, forms } = await api.console.forms.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.forms = forms;
            } finally {
                this.loading = false;
            }
        },

        async update(form) {
            if (this.loading) return;
            else this.loading = true;

            try {
                form = (await api.console.forms.put(form))?.form;
                alert("저장되었습니다");
                this.updateItem(form);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(form) {
            const index = this.forms.findIndex(({ _id }) => _id == form._id);
            if (0 <= index) this.forms.splice(index, 1, form);
            else {
                this.forms = [form, ...this.forms];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
