<template>
    <v-data-table v-bind="{ items, headers }" dense></v-data-table>
</template>

<script>
import { initDataTableHeaders, initShopBulkUploadData } from "@/assets/variables";

const headers = initDataTableHeaders([
    { value: "name", text: "이름" },
    { value: "code", text: "코드" },
    { value: "desc", text: "설명" },

    { value: "label", text: "라벨 이미지" },
    { value: "thumb", text: "목록 이미지" },
    { value: "image", text: "상세 이미지" },
]);

export default {
    props: {
        file: { type: File, default: null },
        value: { type: Object, default: initShopBulkUploadData },
    },
    data: () => ({
        headers,
    }),
    computed: {
        items() {
            return this.value?.brands || [];
        },
    },
};
</script>

<style>
</style>