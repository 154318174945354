<template>
    <v-dialog v-model="shows" width="320" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col class="py-2" cols="12">
                        <v-text-field v-model="form.name" label="이름" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                    <v-col class="py-2" cols="12">
                        <v-text-field v-model="form.code" label="코드" v-bind="{ ...attrs_input, loading }" />
                    </v-col>
                    <!-- <v-col class="py-2" cols="12">
                        <v-text-field v-model="form.desc" label="설명" v-bind="{ ...attrs_input, loading }" />
                    </v-col> -->
                    <v-col class="py-2" cols="12">
                        <span class="caption">라벨</span>
                        <image-input v-model="form.label" accept="image/*" outlined :aspect-ratio="1 / 1" contain />
                    </v-col>
                    <!-- <v-col class="py-2" cols="12" sm="4">
                        <span class="caption">목록</span>
                        <image-input v-model="form.thumb" accept="image/*" outlined :aspect-ratio="1 / 1" contain />
                    </v-col>
                    <v-col class="py-2" cols="12" sm="4">
                        <span class="caption">상세</span>
                        <image-input v-model="form.image" accept="image/*" outlined :aspect-ratio="1 / 1" contain />
                    </v-col> -->
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <!-- <v-switch v-model="form.shows" dense v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "표기" : "미표기" }} </span>
                    </v-switch> -->
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch } from "@/assets/variables";

import ImageInput from "@/components/console/dumb/image-input.vue";

const initForm = (form = {}) => ({
    _label: form?._label || null,
    _thumb: form?._thumb || null,
    _image: form?._image || null,

    name: form?.name || null,
    code: form?.code || null,
    desc: form?.desc || null,
    shows: form?.show || false,
});

export default {
    components: {
        ImageInput,
    },
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        loading: false,

        attrs_input,
        attrs_switch,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "브랜드";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (this.isCreate) {
                    this.form = initForm();
                } else {
                    let { _id } = this;
                    this.form = (await api.console.shop.brands.get({ _id }))?.brand;
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { name, code } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
            if (!code) throw new Error("코드를 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.shop.brands;
                let { _label, _thumb, _image, label, thumb, image, ...form } = this.form;
                let brand = (await (this.isCreate ? post : put)(form))?.brand;

                if (label instanceof File) label = (await api.console.files.post({ _brand: brand._id, index: "label" }, label))?.file;
                if (_label && _label != label?._id) await api.console.files.delete({ _brand: brand._id, _id: _label });
                _label = label?._id;

                if (thumb instanceof File) thumb = (await api.console.files.post({ _brand: brand._id, index: "thumb" }, thumb))?.file;
                if (_thumb && _thumb != thumb?._id) await api.console.files.delete({ _brand: brand._id, _id: _thumb });
                _thumb = thumb?._id;

                if (image instanceof File) image = (await api.console.files.post({ _brand: brand._id, index: "image" }, image))?.file;
                if (_image && _image != image?._id) await api.console.files.delete({ _brand: brand._id, _id: _image });
                _image = image?._id;

                brand = (await put({ _id: brand._id, _label, _thumb, _image }))?.brand;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", brand);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
