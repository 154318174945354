<template>
    <v-data-table v-bind="{ items, headers }" mobile-breakpoint="10000" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined fill-height" style="position: relative">
        <template #top> <div class="table-label px-1 white">결제정보</div> </template>
        <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
    </v-data-table>
</template>

<script>
import { PAYMENT_METHODS } from "@/assets/variables";

export default {
    props: {
        order: { type: Object, default: () => {} },
    },
    computed: {
        items() {
            return [this.order];
        },
        headers() {
            const headers = [];

            headers.push({ text: "결제방법", value: "paymentMethodText" });
            headers.push({ text: "결제금액", value: "totalPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            if (this.order?.moneyPrice) headers.push({ text: "사용선수금", value: "moneyPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            if (this.order?.pointPrice) headers.push({ text: "사용적립금", value: "pointPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            headers.push({ text: "제품금액", value: "productPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            if (this.order?.discountPrice) headers.push({ text: "할인금액", value: "discountPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });
            headers.push({ text: "배송비", value: "deliveryPrice", formatter: (value) => `${value?.format?.() || value || 0}원` });

            return headers.map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), cellClass: "caption", divider: index != array.length - 1 }));
        },
    },
};
</script>

<style lang="scss" scoped>
.table-label {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(50%) scale(0.75);

    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
}
::v-deep {
    .v-data-table__mobile-row {
        padding: 4px 16px !important;
        &,
        & * {
            height: auto !important;
            min-height: auto;
            font-size: 12px;
            font-weight: normal;
        }
        &:first-child {
            margin-top: 12px;
        }
        &:last-child {
            margin-bottom: 12px;
        }
    }
}
</style>
