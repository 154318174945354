<template>
    <v-layout justify-center>
        <v-responsive max-width="1024" width="100%">
            <list-heading title="카테고리 설정" />

            <v-row class="mx-n2 my-2">
                <v-col cols="12" md="6" class="pa-2">
                    <v-card outlined flat v-bind="{ loading }">
                        <v-toolbar color="info lighten-1" dense flat>
                            <v-toolbar-title class="white--text subtitle-2"> 카테고리 순서변경 </v-toolbar-title>
                            <v-spacer />
                            <v-btn color="accent" fab dark small absolute bottom right @click="form = undefined">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <template v-if="categories.length">
                                <v-draggable-treeview v-model="categories" item-key="_id" children="children" group="categories" dense @click="select" @input="update" />
                            </template>
                            <template v-else>
                                <span>
                                    등록된 카테고리가 없습니다. <br />
                                    카테고리를 등록해주세요.
                                </span>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                    <shop-category-form :value="form" v-bind="{ categories, loading }" v-on="{ search, loading: (bool) => (loading = bool) }" />
                </v-col>
            </v-row>
            <v-fade-transition>
                <v-overlay v-show="loading" light color="white">
                    <v-progress-circular size="100" width="5" color="primary" indeterminate />
                </v-overlay>
            </v-fade-transition>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { initShopCategory } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import ShopCategoryForm from "@/components/console/shop/categories/shop-category-form.vue";

export default {
    components: {
        ListHeading,
        ShopCategoryForm,
    },
    data: () => ({
        form: initShopCategory(),
        categories: [],

        loading: false,
        initiating: false,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
                this.categories = categories;
                this.category = initShopCategory(categories[this.categories.findIndex(({ _id }) => _id == this.category?._id)]);
            } finally {
                this.loading = false;
            }
        },

        async select(category) {
            if (this.form?._id == category?._id) this.form = undefined;
            else this.form = initShopCategory(category);
        },

        async update() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await this.updateCategoryOrders(this.categories);
            } finally {
                this.loading = false;
            }
        },

        async updateCategoryOrders(categories, _parent, depth = 1) {
            for (var category of categories) {
                category.sequence = categories.indexOf(category);
                category.depth = depth;
                category._parent = _parent;

                if (category.children) {
                    await this.updateCategoryOrders(category.children, category._id, depth + 1);
                }
                await api.console.shop.categories.put(category);
            }
        },
    },
};
</script>
