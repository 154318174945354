var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._g(_vm._b({
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "outlined": ""
    }
  }, 'v-card', Object.assign({}, _vm.$attrs, {
    loading: _vm.loading
  }), false), _vm.on), [!_vm.hideTitle ? _c('view-section-title', _vm._b({
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_vm._t("actions")];
      },
      proxy: true
    }], null, true)
  }, 'view-section-title', {
    title: _vm.title,
    actionCols: _vm.actionCols
  }, false)) : _vm._e(), !_vm.hideTitle && !_vm.hideDivider ? _c('v-divider') : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }