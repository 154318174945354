<template>
    <list-search v-bind="{ showsSearch }" @search="setQuery">
        <v-row align="center">
            <v-col cols="12" sm="6" md="">
                <v-text-field v-model="query.createdAt[0]" type="date" label="주문일자(시작)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="">
                <v-text-field v-model="query.createdAt[1]" type="date" label="주문일자(종료)" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="12" md="">
                <v-select label="배송방법" v-model="query['delivery.method']" :items="deliveryMethods" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="">
                <v-select label="검색대상" v-model="query.searchKey" :items="searchKeys" placeholder="전체" clearable v-bind="attrs_input" />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="">
                <v-text-field label="검색어" v-model="query.searchValue" clearable v-bind="attrs_input" @keydown.enter="setQuery" />
            </v-col>
        </v-row>
    </list-search>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

let initQuery = (query = {}) => ({
    ...query,

    ["createdAt"]: query?.["createdAt"] || [null, null],

    ["searchKey"]: query?.["searchKey"] || null,
    ["searchValue"]: query?.["searchValue"] || null,

    ["delivery.method"]: query?.["delivery.method"] || null,
});

const searchKeys = [
    { text: "수취인명", value: "receiver.name" },
    { text: "구매자명", value: "sender.name" },
    { text: "구매자연락처", value: "sender.phone" },
    { text: "구매자ID", value: "sender.username" },
    { text: "주문번호", value: "orderNo" },
    { text: "제품주문번호", value: "purchaseNo" },
];

const deliveryMethods = ["택배,등기,소포", "퀵서비스", "방문수령", "직접전달"];

export default {
    components: {
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),

        searchKeys,
        deliveryMethods,

        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        setQuery() {
            const { ...query } = this.query || {};

            if (!query?.["createdAt"]) delete query.createdAt;
            if (!query?.["createdAt"]?.[0] && !query?.createdAt?.[1]) delete query.createdAt;

            if (!query?.["searchKey"]) delete query.searchKey;
            if (!query?.["searchValue"]) delete query.searchValue;

            if (!query?.["delivery.method"]) delete query.delivery.method;

            query.page = 1;

            console.log(query);

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
