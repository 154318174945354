<template>
    <v-edit-dialog :return-value.sync="item._managers" large cancel-text="취소" save-text="저장" v-on="{ save }">
        <slot />
        <template #input>
            <v-sheet>
                <autocomplete-user v-model="item._managers" class="mt-3" :params="{ scope: 'manager' }" :itemText="({ username }) => username" multiple v-bind="attrs_input" />
            </v-sheet>
        </template>
    </v-edit-dialog>
</template>

<script>
import { initUser } from "@/assets/variables/inits";
import { attrs_input } from "@/assets/variables";

import AutocompleteUser from "@/components/dumb/autocomplete-user.vue";

export default {
    components: {
        AutocompleteUser,
    },
    props: {
        value: { type: Object, default: initUser },
        placeholder: { type: String, default: null },
    },
    data: () => ({
        item: initUser(),
        attrs_input,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = initUser(this.value);
        },
        save() {
            let { _id, _managers } = this.item;

            let form = { _id, _managers };

            this.$emit("update", form);
        },
    },
};
</script>
