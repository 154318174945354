<template>
    <v-dialog v-model="shows" width="400" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }" tile class="d-flex flex-column" max-height="90vh" style="overflow: hidden">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="shows = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-5" style="flex: 0 1 auto; overflow-y: auto">
                <v-row class="ma-n2">
                    <v-col cols="12" md="12" class="pa-2"> <v-select v-model="form.code" :items="userTypes" label="회원유형" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="12" class="pa-2"> <v-text-field v-model="form.index" label="등급레벨" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="12" class="pa-2"> <v-text-field v-model="form.name" label="등급명칭" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" md="12" class="pa-2"> <form-criterion v-model="form.criteria[0]" /> </v-col>
                    <v-col cols="12" md="12" class="pa-2">
                        <view-section-card v-model="form.isWelcome" title="초기등급" hideDivider>
                            <template #actions>
                                <v-switch v-model="form.isWelcome" @change="emit" v-bind="attrs_switch" :disabled="value.isWelcome && !isCreate" />
                            </template>
                        </view-section-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col cols="auto" class="py-0" style="min-width: 140px">
                    <v-switch v-model="form.isActive" @change="emit" v-bind="attrs_switch">
                        <span slot="label" class="subtitle-2"> {{ form.isActive ? "활성화" : "비활성화" }} </span>
                    </v-switch>
                </v-col>
                <v-spacer />
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, initUserLevel, USER_TYPES } from "@/assets/variables";

import FormCriterion from "./form-criterion.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

const userTypes = Object.values(USER_TYPES);

export default {
    components: {
        FormCriterion,
        ViewSectionCard,
    },
    props: {
        value: { type: Object, default: initUserLevel },
    },
    data: () => ({
        form: initUserLevel(),

        userTypes,

        attrs_input,
        attrs_switch,

        shows: false,
        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.value._id;
        },
        title() {
            let title = "회원등급";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (this.isCreate) {
                    this.form = initUserLevel();
                } else {
                    let { _id } = this.value;
                    this.form = initUserLevel((await api.console.levels.get({ _id }))?.level);
                }
            } finally {
                this.loading = false;
            }
        },
        emit() {
            this.form = initUserLevel(this.form);
        },
        validates() {
            if (!this.form.code) throw new Error("회원유형를 작성하셔야 합니다");
            if (!this.form.name) throw new Error("등급명칭을 작성하셔야 합니다");
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.validates();

                let { ...form } = this.form;
                let { post, put } = api.console.levels;
                let level = (await (this.isCreate ? post : put)(form))?.level;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", level);
                this.shows = false;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
