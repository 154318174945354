var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    staticStyle: {
      "flex": "1 0 auto",
      "position": "relative"
    },
    attrs: {
      "mobile-breakpoint": "10000",
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('div', {
          staticClass: "table-label px-1 white"
        }, [_vm._v("배송정보")])];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.receiver.isFactory`,
      fn: function () {
        var _vm$order, _vm$order$receiver;
        return [_c('v-checkbox', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "value": (_vm$order = _vm.order) === null || _vm$order === void 0 ? void 0 : (_vm$order$receiver = _vm$order.receiver) === null || _vm$order$receiver === void 0 ? void 0 : _vm$order$receiver.isFactory,
            "readonly": "",
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              var _vm$order2, _vm$order2$receiver;
              return [_vm._v(" " + _vm._s((_vm$order2 = _vm.order) !== null && _vm$order2 !== void 0 && (_vm$order2$receiver = _vm$order2.receiver) !== null && _vm$order2$receiver !== void 0 && _vm$order2$receiver.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다") + " ")];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }