<template>
    <v-dialog v-model="shows" width="400">
        <template #activator="{ attrs, on }">
            <v-btn small outlined color="primary" class="mr-2" v-bind="{ ...attrs, loading }" v-on="on">배송지 정보수정</v-btn>
        </template>
        <v-card>
            <v-card-title class="subtitle-1">배송지 정보수정</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12"> <v-text-field v-model="form.name" label="수취인명" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.phone" label="연락처" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.email" label="이메일" v-bind="attrs_input" /> </v-col>
                    <v-col cols="12" class="py-0"> <v-address-field v-model="form" v-bind="attrs_input" :btnAttrs="{ color: 'secondary', dark: true }" /> </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn text @click="shows = false">취소</v-btn>
                <v-btn color="primary" text @click="put">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";
import VAddressField from "@/components/console/dumb/v-address-field.vue";

const initForm = () => ({
    name: null,
    phone: null,
    email: null,
    postcode: null,
    address1: null,
    address2: null,
});

export default {
    components: {
        VAddressField,
    },
    props: {
        loading: { type: Boolean, default: false },
        selected: { type: Array, default: () => [] },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        attrs_input,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.form = initForm();
        },
        async put() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                if (!this.selected.length) throw new Error("배송지 정보수정 처리할 구매건을 선택해주세요");

                for (const { _id } of this.selected) {
                    await api.console.shop.orders.put({ _id, receiver: this.form });
                }

                alert("배송지 정보수정 처리 되었습니다");
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.$emit("loading", false);
                this.$emit("search");
            }
        },
    },
};
</script>

<style></style>
