var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "묶음제품",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([_vm.form.type == _vm.PRODUCT_TYPES.NORMAL_PRODUCT.value ? {
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.variation.enabled,
            callback: function ($$v) {
              _vm.$set(_vm.form.variation, "enabled", $$v);
            },
            expression: "form.variation.enabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    } : null], null, true)
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.type == _vm.PRODUCT_TYPES.VARIETY_BUNDLE.value,
      expression: "form.type == PRODUCT_TYPES.VARIETY_BUNDLE.value"
    }]
  }, [_c('v-divider'), _c('variation-bundle', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1), _c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.type == _vm.PRODUCT_TYPES.NORMAL_PRODUCT.value && _vm.form.variation.enabled,
      expression: "form.type == PRODUCT_TYPES.NORMAL_PRODUCT.value && form.variation.enabled"
    }]
  }, [_c('v-divider'), _c('variation-normal', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }