<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" class="px-3 mx-n3">
            <list-heading title="소프트웨어 다운로드 로그" showsFilterButton v-model="showsSearch" />
            <log-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined mt-3">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                <template #[`item.user`]="{ item, value }">
                    <v-row>
                        <v-col class="py-0" v-if="value">
                            {{ value?.business?.name }}
                            ({{ value?.username }})
                        </v-col>
                        <v-col class="py-0 text--secondary" v-else> 삭제된 회원입니다 </v-col>
                        <v-col cols="auto" class="px-1 py-0">
                            <v-btn x-small icon text :to="`/console/users/${value?._id}`">
                                <v-icon>mdi-link</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pl-1 py-0">
                            <v-btn x-small icon text :to="{ query: { ...$route.query, _user: value?._id, page: null } }">
                                <v-icon>mdi-account-check-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.software`]="{ value }">
                    <v-row>
                        <v-col class="py-0" :class="{ 'text--secondary': !value?.name }"> {{ value?.name || "삭제된 소프트웨어입니다" }} </v-col>
                        <v-col cols="auto" class="px-1 py-0">
                            <v-btn x-small icon text :to="{ path: '/console/shop/catalogues' }">
                                <v-icon>mdi-link</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto" class="pl-1 py-0">
                            <v-btn x-small icon text :to="{ query: { ...$route.query, _software: value?._id, page: null } }">
                                <v-icon>mdi-file-check-outline</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_input, initDataTableHeaders } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import LogListSearch from "@/components/console/users/logs/log-list-search.vue";

const headers = initDataTableHeaders([
    { width: 120, text: "다운로드일시", value: "createdAt", formatter: (value) => value?.toDateTime?.() || value || "-" },
    { width: 180, text: "사용자", value: "user" },
    { width: 480, text: "소프트웨어", value: "software" },
]);

export default {
    components: {
        ListHeading,
        LogListSearch,
    },
    data: () => ({
        logs: [],

        limit: 20,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,

        attrs_input,

        point: {
            _user: null,
            amount: 0,
            remark: null,
        },
    }),
    computed: {
        items() {
            return [...this.logs];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                await this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, logs } = await api.console.users.logs.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.logs = logs;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .word-break-keep-all {
        word-break: keep-all;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator {
        height: 100%;
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .v-data-table__expanded__content {
        box-shadow: none !important;
    }
    .vertical-align-top {
        vertical-align: top !important;
    }
}
</style>
