<template>
    <v-layout justify-center>
        <v-responsive max-width="960" width="100%" height="100%" class="px-3 mx-n3">
            <list-heading title="AI 챗봇 설정" />

            <v-data-table v-bind="{ loading, headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined" :items-per-page="-1" ref="data-table" style="overflow: hidden">
                <template #top> <prompt-list-groups /> <v-divider /> </template>
                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :disabled="!group || loading">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.thumb`]="{ value }"> <image-popup size="40" :src="value?.url" /> </template>
                <template #[`item.photo`]="{ value }"> <image-popup size="40" :src="value?.url" /> </template>
                <template #[`item.prompt`]="{ item }">
                    <vertical-table-row term="질문" v-bind="attrs_verticalTable">
                        <prompt-list-query-edit :value="item" v-on="{ update }">
                            {{ item.query || "-" }}
                        </prompt-list-query-edit>
                    </vertical-table-row>
                    <vertical-table-row term="답변" v-bind="attrs_verticalTable" hideDivider>
                        <prompt-list-reply-edit :value="item" v-on="{ update }">
                            {{ item.reply || "-" }}
                        </prompt-list-reply-edit>
                    </vertical-table-row>
                </template>
                <template #[`item.isEnabled`]="{ item }">
                    <v-switch v-model="item.isEnabled" v-bind="attrs_switch" @change="(isEnabled) => update({ _id: item._id, isEnabled })" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <prompt-form v-bind="item" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon tile v-bind="attrs" v-on="on">
                                <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                        </template>
                    </prompt-form>
                    <v-btn small text icon tile @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-row no-gutters>
                        <v-spacer />
                        <v-divider vertical />
                        <prompt-form v-bind="{ group }" @input="updateItem">
                            <template #activator="{ attrs, on }">
                                <v-btn text tile color="primary" height="48" v-bind="attrs" v-on="on">
                                    <v-icon small class="mr-1"> mdi-plus </v-icon>
                                    항목추가하기
                                </v-btn>
                            </template>
                        </prompt-form>
                    </v-row>
                </template>
            </v-data-table>
            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { attrs_switch } from "@/assets/variables";

import Draggable from "vuedraggable";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import PromptForm from "@/components/console/center/prompts/prompt-form.vue";
import PromptListGroups from "@/components/console/center/prompts/prompt-list-groups.vue";
import VerticalTableRow from "@/components/console/dumb/vertical-table-row.vue";
import PromptListQueryEdit from "@/components/console/center/prompts/prompt-list-query-edit.vue";
import PromptListReplyEdit from "@/components/console/center/prompts/prompt-list-reply-edit.vue";

const headers = [
    { width: +60, text: "#", value: "index", align: "end" },
    { width: 160, text: "분류", value: "group" },
    { width: 440, text: "프롬프트", value: "prompt", cellClass: "px-0" },
    { width: +80, text: "활성화", value: "isEnabled", align: "center" },
    { width: 100, text: "생성일자", value: "createdAt", formatter: (value) => value?.toDate?.() },
    { width: +88, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, cellClass: (item.cellClass || "") + " caption", formatter: item.formatter ?? ((value) => value ?? "-") }));

const attrs_verticalTable = {
    class: "transparent",
    props: {
        rowProps: { class: "mx-0" },
        termProps: { col: 12, md: 2, style: "border-right:thin solid rgba(0,0,0,0.12)" },
    },
};

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,
        ListHeading,
        PromptForm,
        PromptListGroups,
        VerticalTableRow,
        PromptListQueryEdit,
        PromptListReplyEdit,
    },
    props: {
        group: { type: String, default: null },
    },
    data: () => ({
        prompts: [],

        limit: 20,
        summary: { totalCount: 0 },

        loading: false,

        headers,
        attrs_switch,
        attrs_verticalTable,
    }),
    computed: {
        items() {
            return [...this.prompts];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;
            query.group = this.group;
            if (!query.group) delete query.group;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, prompts } = await api.console.center.prompts.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.prompts = prompts.map((item, index) => ({ index: summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },

        async update(prompt) {
            if (this.loading) return;
            else this.loading = true;

            try {
                prompt = (await api.console.center.prompts.put(prompt))?.prompt;
                this.updateItem(prompt);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async remove(prompt) {
            const go = confirm(`"${prompt?.query}" 항목을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ prompt }] = [await api.console.center.prompts.delete(prompt)];
                    this.prompts = this.prompts.filter(({ _id }) => _id !== prompt._id);
                    alert(`"${prompt?.query}" 항목이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                }
            }
        },

        updateItem(prompt) {
            const index = this.prompts.findIndex(({ _id }) => _id == prompt._id);
            if (0 <= index) this.prompts.splice(index, 1, prompt);
            else {
                this.prompts = [prompt, ...this.prompts];
                this.summary.totalCount += 1;
            }
            this.search();
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let {
                    element: { _id, index },
                    oldIndex,
                    newIndex,
                } = moved;
                index += oldIndex - newIndex;
                let form = { _id, index };
                let { prompt } = await api.console.center.prompts.put(form);
                this.updateItem(prompt);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
}
</style>
