var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "할인사용",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-switch', _vm._b({
          attrs: {
            "value": true
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: _vm.form.discountEnabled,
            callback: function ($$v) {
              _vm.$set(_vm.form, "discountEnabled", $$v);
            },
            expression: "form.discountEnabled"
          }
        }, 'v-switch', _vm.attrs_switch, false))];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.discountEnabled,
      expression: "form.discountEnabled"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "ma-n2"
  }, [_c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "mb-n1",
    attrs: {
      "label": "입력유형",
      "persistent-hint": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function (_ref) {
        var message = _ref.message;
        return [_c('span', {
          staticClass: "text-caption"
        }, [_vm._v(" ※ " + _vm._s(message) + " ")])];
      }
    }]),
    model: {
      value: _vm.form.discount.unit,
      callback: function ($$v) {
        _vm.$set(_vm.form.discount, "unit", $$v);
      },
      expression: "form.discount.unit"
    }
  }, 'v-select', Object.assign({}, _vm.attrs_input, {
    items: _vm.units,
    hint: _vm.unitHint
  }), false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-currency-field', _vm._b({
    attrs: {
      "label": "할인입력",
      "counter": "",
      "persistent-hint": ""
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c('span', {
          staticClass: "text-caption"
        }, [_vm._v(" 할인판매가: ")])];
      },
      proxy: true
    }, {
      key: "counter",
      fn: function () {
        return [_c('span', {
          staticClass: "text-caption"
        }, [_vm._v(" " + _vm._s(_vm.discountPrice.format()) + " 원 ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.discount.amount,
      callback: function ($$v) {
        _vm.$set(_vm.form.discount, "amount", $$v);
      },
      expression: "form.discount.amount"
    }
  }, 'v-currency-field', Object.assign({}, _vm.attrs_input, {
    suffix: _vm.amountSuffix,
    hint: ' '
  }), false))], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }