<template>
    <v-textarea v-model="text" v-bind="{ ...$attrs, rows, autoGrow }" @keydown.enter.prevent v-on="{ input }" />
</template>

<script>
export default {
    props: {
        value: { type: String, default: null },

        rows: { type: [String, Number], default: "1" },
        disableAutoGrow: { type: Boolean, default: false },
    },
    data: () => ({
        text: null,
    }),
    computed: {
        autoGrow() {
            return !this.disableAutoGrow;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.text = this.value;
        },
        input(value) {
            this.text = (value || "").replaceAll(/(\r\n|\n|\r|\t)/gm, "");
            this.$emit("input", this.text);
        },
    },
};
</script>

<style>
</style>